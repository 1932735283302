import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";

class Menus extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
        this.setState({ show: false, effect: 'zoomOut' });
    }

    handleShow(){
        this.setState({ show: true, effect: 'zoomIn' });
    }

    render() {
        return(
            <>
                <span onClick={this.handleShow} className={"btn btn-sm bg-cs2 cpt animated fadeInDown"}>
                    <i className={'mdi mdi-information mr-1'} />
                    Help
                </span>
                <Modal
                    size="md"
                    scrollable={true}
                    centered={true}
					backdrop="static"
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header className="Header">
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-helper">
                        <p className="text-center">
                            <img className="img-fluid" src="/assets/images/covers_new/keno.png" alt="Keno" />
                        </p>
                        <p className={'font-light text-white font-15'}>
                            Keno is a gambling game played with cards (tickets) bearing numbers in squares, from 1 to 40. <br/>
                            You will choose as many numbers as you wish and then submit your bet. Random number will be drawn, and prizes are paid out by the casino according to how many numbers you selected were chosen.
                            <br/>
                            <b>Keno Game Tips & Tricks</b>
                            The more number combination chosen, the higher probability of a win or higher payout. 
                            The maximum possible selection and maximum possible successful5 hits is set to 10.
                            Betting on both even and odd numbers betters your chances of winning.
                            <ul className="m-0 p-1 text-white font-15">
                                <li>• How many numbers to play with</li>
                                <li>• How many coins to wager</li>
                                <li>• Where to play and check your winning numbers -Keno is played by choosing up to 10 numbers from a total set of numbers from 1 to 40.</li>
                            </ul>
                        </p>
                        <h4 className="text-yellow">
                            How to Play Keno ?
                        </h4>
                        <p className={'font-light text-white font-15'}>
                            1. More the number combinations selected, higher the potential payout.
                            <br/>
                            2.The more selections hit successfully, the higher will be the payout multiplier.
                            <br/>
                            3.Maximum possible selection and maximum possible successful hits is 10.
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Menus;
