import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { forceSatoshiFormat } from '../../../Helper';

const Container = styled.div`
  background-color: #1E2024;
  color: white;
  padding: 12px;
  border-radius: 20px;
  font-family: Arial, sans-serif;
  
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 2px;
  table-layout: fixed;
  
  @media (min-width: 768px) {
    border-spacing: 0 4px;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 6px;
  color: #99A4B0;
  font-weight: bold;
  font-size: 0.875rem;
  
  @media (min-width: 768px) {
    padding: 10px;
    font-size: 1rem;
  }

  &:first-child {
    width: 25%;
  }

  &:nth-child(2) {
    width: 25%;
  }

  &:nth-child(3) {
    width: 25%;
    text-align: center;
  }

  &:last-child {
    width: 25%;
    text-align: center;
  }
`;

const TableCell = styled.td`
  padding: 6px;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  @media (min-width: 768px) {
    padding: 10px;
    font-size: 1rem;
  }
`;

const GreenText = styled.span`
  color: #4caf50;
  font-size: 0.875rem;
  
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const RedText = styled.span`
  color: #f44336;
  font-size: 0.875rem;
  
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const AnimatedTableRow = styled.tr`
  background-color: transparent;
  transition: background-color 0.3s ease;
`;

const CryptoIcon = styled.img`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  flex-shrink: 0;
  
  @media (min-width: 768px) {
    height: 16px;
    width: 16px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  overflow: hidden;
  
  @media (min-width: 768px) {
    gap: 4px;
  }
`;

const NumbersCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #99A4B0;
  font-weight: bold;

  @media (max-width: 767px) {
    max-width: 80px;
    margin: 0 auto;
  }
`;

const AmountText = styled.div`
  color: #99A4B0;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// Use the same crypto configuration as HashTableDice
const cryptoConfig = {
  'ETH': {
    icon: '/assets/images/ETH.png',
    minBet: 0.001000,
    maxBet: 0.100000,
    decimals: 6
  },
  'BTC': {
    icon: '/assets/images/BTC.png',
    minBet: 0.0000100,
    maxBet: 0.0010000,
    decimals: 7
  },
  'USDT': {
    icon: '/assets/images/USDT.png',
    minBet: 1,
    maxBet: 100,
    decimals: 2
  },
  'DOGE': {
    icon: '/assets/images/DOGE.png',
    minBet: 50.000,
    maxBet: 500.00,
    decimals: 7
  },
  'LTC': {
    icon: '/assets/images/LTC.png',
    minBet: 0.001,
    maxBet: 0.1000,
    decimals: 7
  },
  'BCH': {
    icon: '/assets/images/BCH.png',
    minBet: 0.001,
    maxBet: 0.1000,
    decimals: 7
  },
  'TRON': {
    icon: '/assets/images/TRON.png',
    minBet: 20.000,
    maxBet: 100.00,
    decimals: 7
  },
  'INR': {
    icon: '/assets/images/INR.png',
    minBet: 100,
    maxBet: 5000,
    decimals: 7
  }
};

const getRandomBetAmount = (crypto) => {
  const config = cryptoConfig[crypto];
  const range = config.maxBet - config.minBet;
  const randomAmount = Math.random() * range + config.minBet;
  return randomAmount.toFixed(config.decimals);
};

const calculateProfit = (betAmount, crypto) => {
  const isWin = Math.random() < 0.5;
  if (!isWin) return "0.00";
  
  const profit = parseFloat(betAmount) * 2;
  return profit.toFixed(cryptoConfig[crypto].decimals);
};

const generateRandomKenoNumbers = () => {
  const count = Math.floor(Math.random() * 3) + 3;
  const numbers = [];
  while (numbers.length < count) {
    const num = Math.floor(Math.random() * 40) + 1;
    if (!numbers.includes(num)) {
      numbers.push(num);
    }
  }
  return numbers.sort((a, b) => a - b).join(',');
};

const generateRandomKenoData = (count) => {
  const data = [];
  const cryptoTypes = Object.keys(cryptoConfig);

  for (let i = 0; i < count; i++) {
    const selectedCrypto = cryptoTypes[Math.floor(Math.random() * cryptoTypes.length)];
    const betAmount = getRandomBetAmount(selectedCrypto);
    const profit = calculateProfit(betAmount, selectedCrypto);
    const numbers = generateRandomKenoNumbers();
    
    data.push({
      player: "Hidden",
      bet: betAmount,
      profit: profit,
      numbers: numbers,
      crypto: selectedCrypto,
      icon: cryptoConfig[selectedCrypto].icon
    });
  }
  return data;
};

const AnimatedTable = ({ visibleRowsCount = 10 }) => {
  const [visibleData, setVisibleData] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    setAllData(generateRandomKenoData(20));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setAllData(prevData => {
        const newData = [...prevData];
        newData.pop();
        newData.unshift(generateRandomKenoData(1)[0]);
        return newData;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setVisibleData(allData.slice(0, visibleRowsCount));
  }, [allData, visibleRowsCount]);

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>PLAYER</TableHeader>
          <TableHeader>BET</TableHeader>
          <TableHeader>PROFIT</TableHeader>
          <TableHeader>NUMBERS</TableHeader>
        </tr>
      </thead>
      <tbody>
        {visibleData.map((row, index) => (
          <AnimatedTableRow key={index}>
            <TableCell style={{fontWeight:'bold'}}>{row.player}</TableCell>
            <TableCell>
              <FlexContainer>
                <CryptoIcon src={row.icon} alt="Crypto icon" />
                <AmountText>{forceSatoshiFormat(row.bet)}</AmountText>
              </FlexContainer>
            </TableCell>
            <TableCell style={{textAlign: 'center'}}>
              {parseFloat(row.profit) > 0 ? (
                <FlexContainer style={{justifyContent: 'center'}}>
                  <CryptoIcon src={row.icon} alt="Crypto icon" />
                  <GreenText>{forceSatoshiFormat(row.profit)}</GreenText>
                </FlexContainer>
              ) : (
                <FlexContainer style={{justifyContent: 'center'}}>
                  <CryptoIcon src={row.icon} alt="Crypto icon" />
                  <RedText>{forceSatoshiFormat(row.profit)}</RedText>
                </FlexContainer>
              )}
            </TableCell>
            <TableCell>
              <NumbersCell>{row.numbers}</NumbersCell>
            </TableCell>
          </AnimatedTableRow>
        ))}
      </tbody>
    </Table>
  );
};

function KenoMultiplayerTable() {
  return (
    <div style={{marginTop:'12px'}}>
      <Container>
        <AnimatedTable />
      </Container>
    </div>
  );
}

export default KenoMultiplayerTable;