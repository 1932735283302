import React from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col, Card } from "react-bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import { isMobile, wait, Event } from "../../../Helper";
import storage from "../../../Storage";
import BankRoll from "../../Components/Game/BankRoll";
import Engine from "./Engine";
import Canvas from "./Graphic";
import MobileCanvas from "./Graphic/Mobile";
import HistoryList from "./includes/HistoryList";
import Help from "./includes/Help";
import Bet from "./Bet";
import Queue from "./Queue";
import Bottom from "./Bottom";
import MobileBottom from "./MobileBottom";
import Loading from "../Loading";

class Index extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            height: null,
            mobile: false,
            isLogged: storage.getKey("logged") !== null ? true : false,
            padding: "p-1"
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        wait(600).then(() => {
            this._Mounted = true;
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        let { engine } = this.state;

        // Start Engine
        engine.started = true;

        wait(500).then(() => {
            engine.getStatus();
        });
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        if (this.getWidth() < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }

        if (isMobile()) {
            this.setState({ mobile: true });
        }
        else {
            this.setState({ mobile: false });
        }

        if (isMobile()) {
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    getWidth() {
        return document.documentElement.clientWidth || document.body.clientWidth;
    }

    render() {
        let { engine, col, padding, mobile, ovh } = this.state;
        return (
            <>
                <Helmet>
                    <title>Crash - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Crash Game, Online Crash Game, Bitcoin Crash Game, Blockchain Crash Game, Best Crypto Crash Game" />
                    <meta name="description" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/crash" />
                </Helmet>
                <div
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    {this._Mounted ?
                        <Row className={"animated fadeIn " + ovh}>
                            <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                                {/* BANKROLL */}
                                <Card className="mb-0">
                                    <Card.Body className="px-1 pb-1 pt-2">
                                        <Row>
                                            <Col md={6} className={'col-6 text-left'}>
                                                <BankRoll game={'crash'} />
                                            </Col>
                                            <Col md={6} className={'col-6 text-right'}>
                                                <Help />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* History List */}
                                <Card className="mb-0 animated fadeIn">
                                    <Card.Body className="p-1 card-body">
                                        <HistoryList engine={engine} game={'crash'} t={this.props.t} />
                                    </Card.Body>
                                </Card>

                                {/* GAME */}
                                <Card className="mb-0">
                                    <Card.Body className={'p-1 animated fadeIn'} id="roll-panel">
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto'}>
                                                {!mobile ?
                                                    <Canvas engine={engine} mobile={this.state.mobile} />
                                                    :
                                                    <MobileCanvas engine={engine} mobile={this.state.mobile} />
                                                }
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* BET */}
                                <Card className="mb-0">
                                    <Card.Body className={padding}>
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto'}>
                                                <Bet engine={engine} mobile={this.state.mobile} isLogged={this.state.isLogged} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* Bottom */}
                                <Card className="mb-0">
                                    <Card.Body className={padding}>
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto'}>
                                                {!mobile ?
                                                    <Bottom engine={engine} mobile={this.state.mobile} isLogged={this.state.isLogged} />
                                                    :
                                                    <MobileBottom engine={engine} t={this.props.t} />
                                                }
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Index;