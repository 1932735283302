import * as PIXI from "pixi.js";
import * as Filter from "pixi-filters";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import Particle from "./Particle.js";
import { wait  } from "../../../../Helper";

/*
    * Register Pixi Plugin for GSAP
*/
gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const Effect = {};

/** 
    *  Light On/Off Object
*/
Effect.Light = function (object, time) {
    let stop = false;

    const start = () => {
        if (stop) return;
        gsap.to(object, { duration: .5, pixi: { brightness: 3, combineCMF: true } });
        wait(500).then(() => {
            min()
        })
    }

    const min = () => {
        gsap.to(object, { duration: .5, pixi: { brightness: 1, combineCMF: true } });
        wait(500).then(() => {
            start()
        })
    }

    start()

    wait(time * 1000).then(() => {
        stop = true;
    })
}

/** 
    *  Heartbeat Animate Object
*/
Effect.HeartBeat = function (object, time) {
    let stop = false;

    const start = () => {
        if (stop) return;
        gsap.to(object.scale, { x: object.scale.x + .05, y: object.scale.y + .05 });
        wait(500).then(() => {
            min()
        })
    }

    const min = () => {
        gsap.to(object.scale, { x: object.scale.x - .05, y: object.scale.y - .05 });
        wait(500).then(() => {
            start()
        })
    }

    start()

    wait(time * 1000).then(() => {
        stop = true;
    })
}

/** 
    * Animate Flash object
*/
Effect.Flash = function (object, time) {
    let stop = false;

    const up = () => {
        gsap.to(object, { duration: 1, pixi: { alpha: 1 } });
        wait(700).then(() => {
            down()
        })
    }
    const down = () => {
        if (stop) return;
        gsap.to(object, { duration: 1, pixi: { alpha: .1 } });
        wait(700).then(() => {
            up()
        })
    }

    up();

    wait(time * 1000).then(() => {
        stop = true;
    })
}

/** 
    * Animate Fade In Object
*/
Effect.FadeIn = function (object) {
    object.alpha = 0;
    gsap.to(object, { duration: 3, pixi: { alpha: 1 } });
}

/** 
    * Animate Move In Object
*/
Effect.MoveIn = function (object, target) {
    gsap.to(object, { duration: 1, pixi: { x: target } });
}

/** 
    * Add Filter to Object
    ** Etc: KawaseBlurFilter, GlowFilter, DropShadowFilter, BulgePinchFilter, BloomFilter , AsciiFilter, AdvancedBloomFilter , OutlineFilter, OldFilmFilter, SimpleLightmapFilter 
*/
Effect.Filter = function (object, filter) {
    const f = new Filter[filter];
    object.filters = [f]
}

/** 
    * Add Particle to Object
*/
Effect.Particle = function (object, image) {
    new Particle(
        object,
        [null],
        {
            "lifetime": {
                "min": 1,
                "max": 2
            },
            "frequency": 0.016,
            "emitterLifetime": 1,
            "maxParticles": 20,
            "addAtBack": false,
            "pos": {
                "x": 50,
                "y": 50
            },
            "behaviors": [
                {
                    "type": "alpha",
                    "config": {
                        "alpha": {
                            "list": [
                                {
                                    "time": 0,
                                    "value": 1
                                },
                                {
                                    "time": 1,
                                    "value": 0.22
                                }
                            ]
                        }
                    }
                },
                {
                    "type": "moveSpeed",
                    "config": {
                        "speed": {
                            "list": [
                                {
                                    "time": 0,
                                    "value": 20
                                },
                                {
                                    "time": 1,
                                    "value": 90
                                }
                            ]
                        }
                    }
                },
                {
                    "type": "scale",
                    "config": {
                        "scale": {
                            "list": [
                                {
                                    "time": 0,
                                    "value": 1.20
                                },
                                {
                                    "time": 1,
                                    "value": 2
                                }
                            ]
                        },
                        "minMult": 0.5
                    }
                },
                {
                    "type": "rotation",
                    "config": {
                        "accel": 0,
                        "minSpeed": 0,
                        "maxSpeed": 100,
                        "minStart": 0,
                        "maxStart": 200
                    }
                },
                {
                    "type": "textureRandom",
                    "config": {
                        "textures": [
                            "assets/images/tower/" + image + '.png'
                        ]
                    }
                },
                {
                    "type": "spawnPoint",
                    "config": {}
                }
            ]
        });
}

export default Effect;