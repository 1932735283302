import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Faq - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, bidwinx.com Slot Games" />
                    <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/faq" />
                </Helmet>
                <div>
                    <Card>
                        <Card.Body>
                            <p className="about-us-page">
                                <h2 className="text-secondary">FAQ</h2>
                            </p>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <p className="about-us-page">
                            <h4 class="text-secondary">How does a Bidwinx work?</h4> 
                            "Bidwinx operates on BSC blockchain It enables users to engage in transactions directly with one another as opposed to relying on a central authority."
                            <h4 class="text-secondary">What is fair game in casino?</h4>    
                            The game is said to be “fair” if it allows all players to have strictly the same chance of winning or losing. In other words, the same probabilities apply to every game played. This calculation is based on the entire game in question.
                            <h4 class="text-secondary">How do you play Provably fair?</h4>
                            The gambling site creates a seed number which is then hashed and sent to the player.
                            <h4 class="text-secondary">How to deposit?</h4>
                            After login with metamask or any web3 wallet Go to &gt; Wallet &gt; Choose deposit amount &gt; Confirm metamask sign and finish.
                            <h4 class="text-secondary">How long does it take to deposit and withdraw?</h4>
                            Deposits/Withdraw from BSC happen using a state sync mechanism and take about 3-7 minutes. If you encounter any problem during deposit or Withdraw, you can visit www.bscscan.com to check your transaction.
                            <h4 class="text-secondary">How INR deposit works?</h4>
                            Bidwinx have many options for INR deposit User can deposit manually with UPI and QR Code or with Payment Gateways which includes Netbanking, UPI, Phone Pe, Google Pay, Paytm or Payment through Cards. Deposit may take upto 1 to 2 hrs depending upon user volume.
                            </p>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}
