import React from "react";
import { connect } from "react-redux";
import { useHover } from "../../../Hooks";
import {
  __,
  wait,
  isMobile,
  isTablet,
  getRandomInt,
  Event,
  playAudio,
} from "../../../Helper";
import C from "../../../Constant";
import storage from "../../../Storage";
import Canvas from "./Canvas";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      token: storage.getKey("token"),
      isLogged: storage.getKey("logged") !== null ? true : false,
      canvas: null,
      buttons: <ButtonSection clicked={this.clicked} />,
      data: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    if (this._isMounted) {
      this.setUpCanvas();
      engine.on(C.PLAY_ROULETTE, (data) => this.play(data));
      engine.on(C.BUSTED_ROULETTE, (data) => this.endGame(data));
      Event.on("coin_changed", (c) => this.coinChanged(c));
      Event.on("reset_table", () => this.resetTable());
      if (isMobile() || isTablet()) {
        let c = document.getElementById("roulette-wheel");
        c.style.width = "100%";
        c.style.height = "100%";
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.destroy();
  }

  destroy = () => {
    this.setState({ engine: null, canvas: null });
  };

  clicked = (buttonNumber) => {
    let amount = this.props.selected_number;
    const left = getRandomInt(1, 10);
    const bottom = getRandomInt(1, 10);
    document.getElementById(
      "d" + buttonNumber
    ).innerHTML += `<img style="left: ${left}px; bottom: ${bottom}px" src="/assets/images/roulette/chip_green.png" class="custom-chip2" />`;

    this.state.engine.data.push({
      table: buttonNumber,
      amount: amount,
    });

    this.state.engine.emit("roulette_add_amount", amount);
  };

  hover = (val) => {
    console.log(val);
    if (val === 112) {
    } else if (val === 212) {
    } else if (val === 312) {
    }
  };

  setUpCanvas() {
    if (this._isMounted) {
      const c = document.getElementById("roulette-wheel");
      const ctx = c.getContext("2d");
      let canvas = new Canvas(c, ctx);
      this.setState({ canvas: canvas });
      canvas.init();
      // canvas.changeChip(storage.getKey('coin') !== null ? storage.getKey('coin') : 'BTC');
    }
  }

  coinChanged(c) {
    if (this._isMounted) {
      this.state.canvas.changeChip(c);
      this.resetTable();
    }
  }

  play = (data) => {
    if (this._isMounted) {
      this.state.canvas.putBallAtSlot(data.target);
    }
  };

  endGame(data) {
    if (this._isMounted) {
      this.state.engine.data = [];
      this.state.engine.emit("busted_roulette");
      return this.resetTable();
    }
  }

  resetTable = () => {
    this.setState({
      loading: true,
      buttons: null,
    });
    this.setState({
      loading: false,
      buttons: <ButtonSection clicked={this.clicked} />,
    });
    if (this.state.canvas !== null) {
      console.log(this.state.canvas, "rou");
      this.state.canvas.removeBall();
    }
  };

  refresh = () => {
    this.state.engine.data = [];
    this.setState({
      loading: true,
      buttons: null,
    });
    wait(200).then(() => {
      this.setState({
        loading: false,
        buttons: <ButtonSection clicked={this.clicked} />,
      });
    });

    this.state.engine.emit("roulette_reset_amount");
  };

  render() {
    return (
      <div className=" animated fadeIn">
        <div className="text-center" id="cc">
          <canvas
            id="roulette-wheel"
            width="400"
            height="450"
            style={{ width: 300, height: 350 }}
          ></canvas>
        </div>
        <div class="roulette_options">
          <button
            className="btn bg-cs btn-xs reset_table"
            onClick={this.refresh}
          >
            <i className="mdi mdi-refresh" />
          </button>
        </div>
        {this.state.loading ? (
          <div style={{ height: 240 }}>
            <div className="game-loader  l-2  text-center">
              <div
                className="spinner-grow text-purple text-info my-3"
                role="status"
              />
            </div>
          </div>
        ) : (
          this.state.buttons
        )}
      </div>
    );
  }
}

function ButtonSection(props) {
  const { clicked } = props;

  const [hoverRef1, isHovered1] = useHover();
  const [hoverRef2, isHovered2] = useHover();
  const [hoverRef3, isHovered3] = useHover();
  const [hoverRef4, isHovered4] = useHover();
  const [hoverRef5, isHovered5] = useHover();
  const [hoverRef6, isHovered6] = useHover();
  const [hoverRef7, isHovered7] = useHover();
  const [hoverRef8, isHovered8] = useHover();
  const [hoverRef9, isHovered9] = useHover();
  const [hoverRef10, isHovered10] = useHover();
  const [hoverRef11, isHovered11] = useHover();
  const [hoverRef12, isHovered12] = useHover();

  const row1 = [3, 6, 9, 12, 15, 18, 12, 24, 27, 30, 33, 36];
  const row2 = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
  const row3 = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];

  const even = [
    2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
  ];
  const odd = [
    1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35,
  ];

  const reds = [
    1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
  ];
  const blacks = [
    2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
  ];

  if (isHovered4) {
    row1.forEach((num, i) => {
      if (document.getElementById("d" + num))
        document.getElementById("d" + num).classList.add("active");
    });
  } else if (isHovered5) {
    row2.forEach((num, i) => {
      if (document.getElementById("d" + num))
        document.getElementById("d" + num).classList.add("active");
    });
  } else if (isHovered6) {
    row3.forEach((num, i) => {
      if (document.getElementById("d" + num))
        document.getElementById("d" + num).classList.add("active");
    });
  } else {
    [row1, row2, row3].forEach((num, i) => {
      if (document.getElementById("d" + num))
        document.getElementById("d" + num).classList.remove("active");
    });

    if (isHovered7) {
      for (var i = 1; i < 19; i++) {
        if (document.getElementById("d" + i))
          document.getElementById("d" + i).classList.add("active");
      }
    } else if (isHovered8) {
      for (var i = 19; i < 37; i++) {
        if (document.getElementById("d" + i))
          document.getElementById("d" + i).classList.add("active");
      }
    } else {
      if (isHovered9) {
        even.forEach((num, i) => {
          if (document.getElementById("d" + num))
            document.getElementById("d" + num).classList.add("active");
        });
      } else if (isHovered10) {
        odd.forEach((num, i) => {
          if (document.getElementById("d" + num))
            document.getElementById("d" + num).classList.add("active");
        });
      } else {
        if (isHovered11) {
          reds.forEach((num, i) => {
            if (document.getElementById("d" + num))
              document.getElementById("d" + num).classList.add("active");
          });
        } else if (isHovered12) {
          blacks.forEach((num, i) => {
            if (document.getElementById("d" + num))
              document.getElementById("d" + num).classList.add("active");
          });
        } else {
          [even, odd].forEach((num, i) => {
            if (document.getElementById("d" + num))
              document.getElementById("d" + num).classList.remove("active");
          });

          for (var i = 1; i < 37; i++) {
            if (document.getElementById("d" + i))
              document.getElementById("d" + i).classList.remove("active");
          }

          if (isHovered1) {
            for (var i = 1; i < 13; i++) {
              if (document.getElementById("d" + i))
                document.getElementById("d" + i).classList.add("active");
            }
          } else if (isHovered2) {
            for (var i = 13; i < 25; i++) {
              if (document.getElementById("d" + i))
                document.getElementById("d" + i).classList.add("active");
            }
          } else if (isHovered3) {
            for (var i = 25; i < 37; i++) {
              if (document.getElementById("d" + i))
                document.getElementById("d" + i).classList.add("active");
            }
          } else {
            for (var i = 1; i < 37; i++) {
              if (document.getElementById("d" + i))
                document.getElementById("d" + i).classList.remove("active");
            }
          }
        }
      }
    }
  }

  return (
    <div className="roulette">
      <table>
        <tr className="nums">
          <td
            id={"d0"}
            onClick={(e) => clicked(0)}
            className="num green zero"
            rowspan="3"
          >
            <span>0</span>
          </td>
          <td id={"d3"} onClick={(e) => clicked(3)} className="num red">
            <span>3</span>
          </td>
          <td id={"d6"} onClick={(e) => clicked(6)} className="num black">
            <span>6</span>
          </td>
          <td id={"d9"} onClick={(e) => clicked(9)} className="num red">
            <span>9</span>
          </td>
          <td id={"d12"} onClick={(e) => clicked(12)} className="num red">
            <span>12</span>
          </td>
          <td id={"d15"} onClick={(e) => clicked(15)} className="num black">
            <span>15</span>
          </td>
          <td id={"d18"} onClick={(e) => clicked(18)} className="num red">
            <span>18</span>
          </td>
          <td id={"d21"} onClick={(e) => clicked(21)} className="num red">
            <span>21</span>
          </td>
          <td id={"d24"} onClick={(e) => clicked(24)} className="num black">
            <span>24</span>
          </td>
          <td id={"d27"} onClick={(e) => clicked(27)} className="num red">
            <span>27</span>
          </td>
          <td id={"d30"} onClick={(e) => clicked(30)} className="num red">
            <span>30</span>
          </td>
          <td id={"d33"} onClick={(e) => clicked(33)} className="num black">
            <span>33</span>
          </td>
          <td id={"d36"} onClick={(e) => clicked(36)} className="num red">
            <span>36</span>
          </td>
          <td
            id={"d2_1"}
            onClick={(e) => clicked("2_1")}
            ref={hoverRef4}
            className="sector"
            data-sector="1"
          >
            <span className="vt">2:1</span>
          </td>
        </tr>
        <tr className="nums">
          <td className="hidden"></td>
          <td id={"d2"} onClick={(e) => clicked(2)} className="num black">
            <span>2</span>
          </td>
          <td id={"d5"} onClick={(e) => clicked(5)} className="num red">
            <span>5</span>
          </td>
          <td id={"d8"} onClick={(e) => clicked(8)} className="num black">
            <span>8</span>
          </td>
          <td id={"d11"} onClick={(e) => clicked(11)} className="num black">
            <span>11</span>
          </td>
          <td id={"d14"} onClick={(e) => clicked(14)} className="num red">
            <span>14</span>
          </td>
          <td id={"d17"} onClick={(e) => clicked(17)} className="num black">
            <span>17</span>
          </td>
          <td id={"d20"} onClick={(e) => clicked(20)} className="num black">
            <span>20</span>
          </td>
          <td id={"d23"} onClick={(e) => clicked(23)} className="num red">
            <span>23</span>
          </td>
          <td id={"d26"} onClick={(e) => clicked(26)} className="num black">
            <span>26</span>
          </td>
          <td id={"d29"} onClick={(e) => clicked(29)} className="num black">
            <span>29</span>
          </td>
          <td id={"d32"} onClick={(e) => clicked(32)} className="num red">
            <span>32</span>
          </td>
          <td id={"d35"} onClick={(e) => clicked(35)} className="num black">
            <span>35</span>
          </td>
          <td
            id={"d2_2"}
            onClick={(e) => clicked("2_2")}
            ref={hoverRef5}
            className="sector"
            data-sector="2"
          >
            <span className="vt">2:1</span>
          </td>
        </tr>
        <tr className="nums">
          <td className="hidden"></td>
          <td id={"d1"} onClick={(e) => clicked(1)} className="num red">
            <span>1</span>
          </td>
          <td id={"d4"} onClick={(e) => clicked(4)} className="num black">
            <span>4</span>
          </td>
          <td id={"d7"} onClick={(e) => clicked(7)} className="num red">
            <span>7</span>
          </td>
          <td id={"d10"} onClick={(e) => clicked(10)} className="num black">
            <span>10</span>
          </td>
          <td id={"d13"} onClick={(e) => clicked(13)} className="num black">
            <span>13</span>
          </td>
          <td id={"d16"} onClick={(e) => clicked(16)} className="num red">
            <span>16</span>
          </td>
          <td id={"d19"} onClick={(e) => clicked(19)} className="num red">
            <span>19</span>
          </td>
          <td id={"d22"} onClick={(e) => clicked(22)} className="num black">
            <span>22</span>
          </td>
          <td id={"d25"} onClick={(e) => clicked(25)} className="num red">
            <span>25</span>
          </td>
          <td id={"d28"} onClick={(e) => clicked(28)} className="num black">
            <span>28</span>
          </td>
          <td id={"d31"} onClick={(e) => clicked(31)} className="num black">
            <span>31</span>
          </td>
          <td id={"d34"} onClick={(e) => clicked(34)} className="num red">
            <span>34</span>
          </td>
          <td
            id={"d2_3"}
            onClick={(e) => clicked("2_3")}
            ref={hoverRef6}
            className="sector"
            data-sector="3"
          >
            <span className="vt">2:1</span>
          </td>
        </tr>
        <tr>
          <td className="empty"></td>
          <td
            id={"d112"}
            onClick={(e) => clicked(112)}
            ref={hoverRef1}
            data-num="112"
            colspan="4"
            className="sector"
            data-sector="4"
          >
            {" "}
            1st 12
          </td>
          <td
            id={"d212"}
            onClick={(e) => clicked(212)}
            ref={hoverRef2}
            data-num="212"
            colspan="4"
            className="sector"
            data-sector="5"
          >
            {" "}
            2nd 12
          </td>
          <td
            id={"d312"}
            onClick={(e) => clicked(312)}
            ref={hoverRef3}
            data-num="312"
            colspan="4"
            className="sector"
            data-sector="6"
          >
            {" "}
            3rd 12
          </td>
          <td className="empty"></td>
        </tr>
        <tr>
          <td className="empty"></td>
          <td
            id={"d1_18"}
            onClick={(e) => clicked("1_18")}
            ref={hoverRef7}
            colspan="2"
            className="sector"
            data-sector="7"
          >
            1 to 18
          </td>
          <td
            id={"deven"}
            onClick={(e) => clicked("even")}
            ref={hoverRef9}
            colspan="2"
            className="sector"
            data-sector="8"
          >
            EVEN
          </td>
          <td
            id={"dred"}
            onClick={(e) => clicked("red")}
            ref={hoverRef11}
            colspan="2"
            className="sector red"
            data-sector="9"
          ></td>
          <td
            id={"dblack"}
            onClick={(e) => clicked("black")}
            ref={hoverRef12}
            colspan="2"
            className="sector black"
            data-sector="10"
          ></td>
          <td
            id={"dodd"}
            onClick={(e) => clicked("odd")}
            ref={hoverRef10}
            colspan="2"
            className="sector"
            data-sector="11"
          >
            ODD
          </td>
          <td
            id={"d19_36"}
            onClick={(e) => clicked("19_36")}
            ref={hoverRef8}
            colspan="2"
            className="sector"
            data-sector="12"
          >
            19 to 36
          </td>
          <td className="empty"></td>
        </tr>
      </table>
      <div className="controlls">
        <div className="btn btn-zero" data-num="0"></div>
        {/* Col 6 */}
        <div className="col1">
          <div className="row1">
            <div className="btn v rm cv" data-num="0,3"></div>
            <div className="btn m rm cm" data-num="3"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="0,2,3"></div>
            <div className="btn v rm cv" data-num="0,2"></div>
            <div className="btn h rh cm" data-num="2,3"></div>
            <div className="btn m rm cm" data-num="2"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="0,1,2"></div>
            <div className="btn v rm cv" data-num="0,1"></div>
            <div className="btn c rb cv" data-num="0,1,2,3"></div>
            <div className="btn h rh cm" data-num="1,2"></div>
            <div className="btn m rm cm" data-num="1"></div>
            <div className="btn h rb cm" data-num="1,2,3"></div>
          </div>
          <div className="row4">
            <div
              className="btn ms4 rm cm"
              data-type="sector"
              data-sector="4"
            ></div>
          </div>
          <div className="row5">
            <div
              className="btn ms2 rm cm"
              data-type="sector"
              data-sector="7"
            ></div>
          </div>
        </div>
        {/* Col 2 */}
        <div className="col2">
          <div className="row1">
            <div className="btn v rm cv" data-num="3,6"></div>
            <div className="btn m rm cm" data-num="6"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="2,3,5,6"></div>
            <div className="btn v rm cv" data-num="2,5"></div>
            <div className="btn h rh cm" data-num="5,6"></div>
            <div className="btn m rm cm" data-num="5"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="1,2,4,5"></div>
            <div className="btn v rm cv" data-num="1,4"></div>
            <div className="btn c rb cv" data-num="1,2,3,4,5,6"></div>
            <div className="btn h rh cm" data-num="4,5"></div>
            <div className="btn m rm cm" data-num="4"></div>
            <div className="btn h rb cm" data-num="4,5,6"></div>
          </div>
        </div>
        {/* Col 3*/}
        <div className="col3">
          <div className="row1">
            <div className="btn v rm cv" data-num="6,9"></div>
            <div className="btn m rm cm" data-num="9"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="5,6,8,9"></div>
            <div className="btn v rm cv" data-num="5,8"></div>
            <div className="btn h rh cm" data-num="8,9"></div>
            <div className="btn m rm cm" data-num="8"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="4,5,7,8"></div>
            <div className="btn v rm cv" data-num="4,7"></div>
            <div className="btn c rb cv" data-num="4,5,6,7,8,9"></div>
            <div className="btn h rh cm" data-num="7,8"></div>
            <div className="btn m rm cm" data-num="7"></div>
            <div className="btn h rb cm" data-num="7,8,9"></div>
          </div>
          <div className="row5">
            <div
              className="btn ms2 rm cm"
              data-type="sector"
              data-sector="8"
            ></div>
          </div>
        </div>
        {/* Col 4 */}
        <div className="col4">
          <div className="row1">
            <div className="btn v rm cv" data-num="9,12"></div>
            <div className="btn m rm cm" data-num="12"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="8,9,11,12"></div>
            <div className="btn v rm cv" data-num="8,11"></div>
            <div className="btn h rh cm" data-num="11,12"></div>
            <div className="btn m rm cm" data-num="11"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="7,8,10,11"></div>
            <div className="btn v rm cv" data-num="7,10"></div>
            <div className="btn c rb cv" data-num="7,8,9,10,11,12"></div>
            <div className="btn h rh cm" data-num="10,11"></div>
            <div className="btn m rm cm" data-num="10"></div>
            <div className="btn h rb cm" data-num="10,11,12"></div>
          </div>
        </div>
        {/* Col 5 */}
        <div className="col5">
          <div className="row1">
            <div className="btn v rm cv" data-num="12,15"></div>
            <div className="btn m rm cm" data-num="15"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="11,12,14,15"></div>
            <div className="btn v rm cv" data-num="11,14"></div>
            <div className="btn h rh cm" data-num="14,15"></div>
            <div className="btn m rm cm" data-num="14"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="10,11,13,14"></div>
            <div className="btn v rm cv" data-num="10,13"></div>
            <div className="btn c rb cv" data-num="10,11,12,13,14,15"></div>
            <div className="btn h rh cm" data-num="13,14"></div>
            <div className="btn m rm cm" data-num="13"></div>
            <div className="btn h rb cm" data-num="13,14,15"></div>
          </div>
          <div className="row4">
            <div
              className="btn ms4 rm cm"
              data-type="sector"
              data-sector="5"
            ></div>
          </div>
          <div className="row5">
            <div
              className="btn ms2 rm cm"
              data-type="sector"
              data-sector="9"
            ></div>
          </div>
        </div>
        {/* Col 6 */}
        <div className="col6">
          <div className="row1">
            <div className="btn v rm cv" data-num="15,18"></div>
            <div className="btn m rm cm" data-num="18"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="14,15,17,18"></div>
            <div className="btn v rm cv" data-num="14,17"></div>
            <div className="btn h rh cm" data-num="17,18"></div>
            <div className="btn m rm cm" data-num="17"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="13,14,16,17"></div>
            <div className="btn v rm cv" data-num="13,16"></div>
            <div className="btn c rb cv" data-num="13,14,15,16,17,18"></div>
            <div className="btn h rh cm" data-num="16,17"></div>
            <div className="btn m rm cm" data-num="16"></div>
            <div className="btn h rb cm" data-num="16,17,18"></div>
          </div>
        </div>
        {/* Col 7 */}
        <div className="col7">
          <div className="row1">
            <div className="btn v rm cv" data-num="18,21"></div>
            <div className="btn m rm cm" data-num="21"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="17,18,20,21"></div>
            <div className="btn v rm cv" data-num="17,20"></div>
            <div className="btn h rh cm" data-num="20,21"></div>
            <div className="btn m rm cm" data-num="20"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="16,17,19,20"></div>
            <div className="btn v rm cv" data-num="16,19"></div>
            <div className="btn c rb cv" data-num="16,17,18,19,20,21"></div>
            <div className="btn h rh cm" data-num="19,20"></div>
            <div className="btn m rm cm" data-num="19"></div>
            <div className="btn h rb cm" data-num="19,20,21"></div>
          </div>
          <div className="row5">
            <div
              className="btn ms2 rm cm"
              data-type="sector"
              data-sector="10"
            ></div>
          </div>
        </div>
        {/* Col 8 */}
        <div className="col8">
          <div className="row1">
            <div className="btn v rm cv" data-num="21,24"></div>
            <div className="btn m rm cm" data-num="24"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="20,21,23,24"></div>
            <div className="btn v rm cv" data-num="20,23"></div>
            <div className="btn h rh cm" data-num="23,24"></div>
            <div className="btn m rm cm" data-num="23"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="19,20,22,23"></div>
            <div className="btn v rm cv" data-num="19,22"></div>
            <div className="btn c rb cv" data-num="19,20,21,22,23,24"></div>
            <div className="btn h rh cm" data-num="22,23"></div>
            <div className="btn m rm cm" data-num="22"></div>
            <div className="btn h rb cm" data-num="22,23,24"></div>
          </div>
        </div>
        {/* Col 9 */}
        <div className="col9">
          <div className="row1">
            <div className="btn v rm cv" data-num="24,27"></div>
            <div className="btn m rm cm" data-num="27"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="23,24,26,27"></div>
            <div className="btn v rm cv" data-num="23,26"></div>
            <div className="btn h rh cm" data-num="26,27"></div>
            <div className="btn m rm cm" data-num="26"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="22,23,25,26"></div>
            <div className="btn v rm cv" data-num="22,25"></div>
            <div className="btn c rb cv" data-num="22,23,24,25,26,27"></div>
            <div className="btn h rh cm" data-num="25,26"></div>
            <div className="btn m rm cm" data-num="25"></div>
            <div className="btn h rb cm" data-num="25,26,27"></div>
          </div>
          <div className="row4">
            <div
              className="btn ms4 rm cm"
              data-type="sector"
              data-sector="6"
            ></div>
          </div>
          <div className="row5">
            <div
              className="btn ms2 rm cm"
              data-type="sector"
              data-sector="11"
            ></div>
          </div>
        </div>
        {/* Col 10 */}
        <div className="col10">
          <div className="row1">
            <div className="btn v rm cv" data-num="27,30"></div>
            <div className="btn m rm cm" data-num="30"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="26,27,29,30"></div>
            <div className="btn v rm cv" data-num="26,29"></div>
            <div className="btn h rh cm" data-num="29,30"></div>
            <div className="btn m rm cm" data-num="29"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="25,26,28,29"></div>
            <div className="btn v rm cv" data-num="25,28"></div>
            <div className="btn c rb cv" data-num="25,26,27,28,29,30"></div>
            <div className="btn h rh cm" data-num="28,29"></div>
            <div className="btn m rm cm" data-num="28"></div>
            <div className="btn h rb cm" data-num="28,29,30"></div>
          </div>
        </div>
        {/* Col 11 */}
        <div className="col11">
          <div className="row1">
            <div className="btn v rm cv" data-num="30,33"></div>
            <div className="btn m rm cm" data-num="33"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="29,30,32,33"></div>
            <div className="btn v rm cv" data-num="29,32"></div>
            <div className="btn h rh cm" data-num="32,33"></div>
            <div className="btn m rm cm" data-num="32"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="28,29,31,32"></div>
            <div className="btn v rm cv" data-num="28,31"></div>
            <div className="btn c rb cv" data-num="28,29,30,31,32,33"></div>
            <div className="btn h rh cm" data-num="31,32"></div>
            <div className="btn m rm cm" data-num="31"></div>
            <div className="btn h rb cm" data-num="31,32,33"></div>
          </div>
          <div className="row5">
            <div
              className="btn ms2 rm cm"
              data-type="sector"
              data-sector="12"
            ></div>
          </div>
        </div>
        {/* Col 12 */}
        <div className="col12">
          <div className="row1">
            <div className="btn v rm cv" data-num="33,36"></div>
            <div className="btn m rm cm" data-num="36"></div>
          </div>
          <div className="row2">
            <div className="btn c rh cv" data-num="32,33,35,36"></div>
            <div className="btn v rm cv" data-num="32,35"></div>
            <div className="btn h rh cm" data-num="35,36"></div>
            <div className="btn m rm cm" data-num="35"></div>
          </div>
          <div className="row3">
            <div className="btn c rh cv" data-num="31,32,34,35"></div>
            <div className="btn v rm cv" data-num="31,34"></div>
            <div className="btn c rb cv" data-num="31,32,33,34,35,36"></div>
            <div className="btn h rh cm" data-num="34,35"></div>
            <div className="btn m rm cm" data-num="34"></div>
            <div className="btn h rb cm" data-num="34,35,36"></div>
          </div>
        </div>
        <div className="col13">
          <div className="row1">
            <div
              className="btn m rm cm"
              data-type="sector"
              data-sector="1"
            ></div>
          </div>
          <div className="row2">
            <div
              className="btn m rm cm"
              data-type="sector"
              data-sector="2"
            ></div>
          </div>
          <div className="row3">
            <div
              className="btn m rm cm"
              data-type="sector"
              data-sector="3"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selected_number: state.items.selected_number,
});

export default connect(mapStateToProps, null)(Game);
