import React from 'react';
import styled from 'styled-components';

const TermsOfService = () => (
  <Container>
    <ContentSection>
      <SectionTitle>Terms of Service</SectionTitle>
      <SectionContent>
        This end user agreement (the "Agreement") should be read by you (the "User" or "you") in its entirety prior to your use of NANOGAMES.IO's service or products. Please note that the Agreement constitutes a legally binding agreement between you and NANOGAMES.IO (referred to herein as "NANOGAMES.IO", "us" or "we") which owns and operates the Internet site found and games described at NANOGAMES.IO (the "Service"). By clicking the "I Agree" button if and where provided and/or using the Service, you consent to the terms and conditions set forth in this Agreement.

      <SectionTitle>1. Grant of License</SectionTitle>
      <SectionContent>
        1.1. Subject to the terms and conditions contained herein, NANOGAMES.IO grants the User a non-exclusive, personal, non-transferable right to use the Service on your personal computer or other device that accesses the Internet in order to access the games available.
      </SectionContent>
      <SectionContent>
        1.2. The Service is not for use by individuals under 18 years of age, individuals under the legal age of majority in their jurisdiction and individuals accessing the Service from jurisdictions from which it is illegal to do so. NANOGAMES.IO is not able to verify the legality of the Service in each jurisdiction and it is the User's responsibility to ensure that their use of the Service is lawful.
      </SectionContent>

      <SectionTitle>2. No Warranties</SectionTitle>
      <SectionContent>
        2.1. NANOGAMES.IO disclaims any and all warranties, expressed or implied, in connection with the service which is provided to you "as is" and we provide you with no warranty or representation whatsoever regarding its quality, fitness for purpose, completeness or accuracy.
      </SectionContent>
 

    
      <SectionTitle>3. Authority/Terms of Service</SectionTitle>
      <SectionContent>
        You agree to the game rules described on the NANOGAMES.IO website. NANOGAMES.IO retains authority over the issuing, maintenance, and closing of the Service. The decision of NANOGAMES.IO's management, concerning any use of the Service, or dispute resolution, is final and shall not be open to review or appeal.
      </SectionContent>
   

      <SectionTitle>4. Your Obligations as a Player</SectionTitle>
      <SectionContent>
        4.1. You hereby declare and warrant that:
        <ul>
          <ListItem>You are over 18 years of age or such a higher minimum legal age of majority as stipulated if the jurisdiction of Your residence (e.g. Estonia – 21 years) and, under the laws applicable to You, legally allowed to participate in the Games offered on the Website.</ListItem>
          <ListItem>You participate in the Games strictly in your personal non-professional capacity for recreational and entertainment reasons only.</ListItem>
          <ListItem>You participate in the Games on your own behalf and not on behalf of any other person.</ListItem>
          <ListItem>All information that You provide to NANOGAMES.IO during the term of validity of this agreement is true, complete, and correct, and that You shall immediately notify NANOGAMES.IO of any change of such information.</ListItem>
          <ListItem>You are solely responsible for reporting and accounting for any taxes applicable to You under relevant laws for any winnings that You receive from NANOGAMES.IO.</ListItem>
        </ul>
      </SectionContent>
 

      <SectionTitle>5. Prohibited Uses</SectionTitle>
      <SectionContent>
        5.1. PERSONAL USE. The Service is intended solely for the User's personal use. The User is only allowed to wager for his/her personal entertainment and may not create multiple accounts, including for the purpose of collusion and/or abuse ofservice.
      </SectionContent>
      <SectionContent>
        5.2. JURISDICTIONS. Persons located in or residents of Aruba, Bonaire, Curacao, Costa Rica, France, Netherlands, Saba, Statia, St Martin, USA (the "Prohibited Jurisdictions") are not permitted make use of the Service.
      </SectionContent>
      </SectionContent>
    </ContentSection>

 



  </Container>
);

const Container = styled.div`

`;

const ContentSection = styled.div`
    background-color: #36393f;
  color: #b9bbbe;
  padding: 24px;
  border-radius: 20px;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
`;

const ListItem = styled.li`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
`;

export default TermsOfService;