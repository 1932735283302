import React, { Component } from "react";
import {Card} from "react-bootstrap";
import ManualBet from './components/ManualBet';
// import AutoBet from './components/AutoBet';

class Parent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ManualBet mobile={this.props.mobile} engine={this.props.engine} />
        );
    }
}

export default Parent;
