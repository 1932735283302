import styled from "styled-components";

const ContentSection = styled.div`
  background-color: #36393f;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  height:100vh;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
`;


const SupportHelp = () => (
    <ContentSection>
      {/* <SectionTitle>Support</SectionTitle> */}
      <SectionContent>You have a question? Can't find the answer on the website? Don't worry! you can contact our 24-hour online customer support any time, Our customer support team is happy to answer any of your questions.</SectionContent>
    </ContentSection>
  );

  
  export default SupportHelp