import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";

class Menus extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
        this.setState({ show: false, effect: 'zoomOut' });
    }

    handleShow(){
        this.setState({ show: true, effect: 'zoomIn' });
    }

    render() {
        return(
            <>
                <span onClick={this.handleShow} className={"btn btn-sm bg-cs2 cpt animated fadeInDown"}>
                    <i className={'mdi mdi-information mr-1'} />
                    Help
                </span>
                <Modal
                    size="md"
                    scrollable={true}
					backdrop="static"
                    centered={true}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header className="Header">
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-helper">
                        <p className="text-center">
                            <img className="img-fluid" src="/assets/images/covers_new/____cr1.png" alt="help" style={{ width: 220 }} />
                        </p>
                        <p className={'font-light text-white font-15'}>
                            Once you’ve got some cryptocurrency and deposited it into your gaming wallet, you’re ready to play. Simply select an amount to bet and a multiplier and make your wager. The multiplier will increase rapidly once you bet, but be careful — the longer you wait, the more likely it is that the game will crash! If you cash out in time, however, you’ll receive your wager back at the multiplier that you stopped the game at.<br/>
                                <b>Crash Game Tips & Tricks</b><br/>
                                Bank your bonus! Playing with a base bet and banking your winnings is a relaxing way to play crash games with minimal risk
                                Consider using a recovery strategy. If you lose a bet and you want to see if you can recover, take your loss and triple it then aim for a wager similar to 1.33 for your cash out.
                                Have fun! Crash is a simple game — it’s possible to win big playing crash!
                        </p>
                        <h4 className="text-white">
                            What Is HASH and MD5 ?
                        </h4>
                        <p className={'font-light text-white font-15'}>
                            Each round of the game has a <span className="text-success">HASH</span> that is used to verify the result. (you can use verify result link in the left sidebar). <br/>
                            <span className="text-warning">MD5</span> is the result of the <span className="text-success">HASH</span> conversion that you can see it during the game.<br/>
                            <span className="text-warning">MD5</span> value is just to confirm that the user knows that the result (busted value) can't change during the game.
                        </p>
                        <p className={'font-light text-white font-15'}>
                            Here is a example: <br/>
                            <code>
                                HASH: 7da1df045b5362b0297e20946c64a1c0e37de244c5abe63cf31bfa8d35cb0bae <br/>
                                MD5: e85770ef0eb25aaf15a04761c23d6cc0 <br/>
                                RESULT: 13.83
                            </code>
                            <br/>
                            First the game generates a random <span className="text-success">HASH</span>, then converts this value to the <span className="text-warning">MD5</span> and shows it to you, then the counter starts. <br/>
                        </p>
                        <h4 className="text-white">
                            How can validate MD5 ?
                        </h4>
                        <p className={'font-light text-white font-15'}>
                            Just need to convert <span className="text-success">HASH</span> value to <span className="text-warning">MD5</span> !
                            <br/>
                            <code>md5(HASH)</code>
                            <br/>
                            <a rel="noopener noreferrer" target="_blank" href="https://emn178.github.io/online-tools/md5.html">Here is a simple tool (click)</a>,
                             put the <span className="text-success">HASH</span> value then you can see <span className="text-warning">md5</span> value. 
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Menus;