import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import ManualBet from './includes/ManualBet';
import AutoBet from './includes/AutoBet';
import { isMobile } from "../../../Helper";

class Bet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual'
        };
    }

    componentDidMount() {
        if (isMobile()) {
            this.setState({ padding: 'p-1', margin: 'mt-1' })
        }
    }

    betMode = (type) => {
        this.setState({ bet: type });
    }

    render() {
        let { margin, bet } = this.state;
        let { isLogged, mobile, engine } = this.props;
        return (
            <>
                {bet === 'manual' ?
                    <ManualBet engine={engine} mobile={mobile} isLogged={isLogged} />
                    :
                    <AutoBet engine={engine} isLogged={isLogged} />
                }
                <div>
              <span style={{fontSize:11,marginLeft:5}}>
                {this.props.coin ==='INR' ?
                 
              "(Minimum Bet - 20 INR)"
              :
              "(Minimum Bet - 0.5 USDT)"
              }
              </span>
              </div>
                <div className={"btn-group btn-group-toggle 'tcr ml-1 mt-1 mb-2" + margin} data-toggle="buttons">
                    <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                        <input onClick={e => this.betMode('manual')} type="radio" /> Manual
                    </label>
                    <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                        <input onClick={e => this.betMode('auto')} type="radio" /> Auto
                    </label>
                </div>
            </>
        );
    }
}

export default Bet;