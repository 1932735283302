import * as PIXI from "pixi.js";
import { __, wait } from "../../../Helper";

PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

class Canvas {
    constructor(engine) {
        this.app = new PIXI.Application({
            width: 600,
            height: 350,
            resolution: 1,
            transparent: true,
            view: document.getElementById('game')
        });

        this.engine = engine
        this.started = false
        this.moveCards1 = false
        this.moveCards2 = false
        this.moveCards3 = false
        this.moveCards4 = false
        this.moveCards5 = false
        this.activePlayer = false
        this.bustedGame = false
        this.clicked = null
        this.winner = null
        this.cards = []
        this.levels = []
        this.currentLevel = 0;
    }

    init() {
        this.container = new PIXI.Container();
        this.container.sortableChildren = true;
        this.app.stage.addChild(this.container);

        PIXI.Loader.shared.onProgress.add(this.onProgress);
        PIXI.Loader.shared
            .load(e => this.load());
    }

    onProgress(loader, resource) {
        var loading = document.getElementById('loading');
        if (loading !== null) {
            loading.innerText = loader.progress;
            if (loader.progress >= 99) loading.remove();
        }
    }

    load() {
        this.configureCards()
        this.configureMessage()
        this.configureLevels()

        this.app.ticker.add((delta) => this.animation(delta, 0));
    }

    animation(delta, count) {

        let cards = ['heart', 'spade', 'wajit'];

        if (this.started) {
            count += 1.0;

            //Game Started

            cards.forEach((name, i) => {
                this.cards[name].scale.x -= 0.1
                if (this.cards[name].scale.x <= -1) {
                    this.cards[name].texture = PIXI.Texture.from('./assets/images/card-back.svg');
                    this.moveCards1 = true;
                    this.started = false;
                }
            })
        }

        if (this.result) {
            if (this.clicked.scale.x <= .7) {
                this.clicked.scale.x += 0.1
            }
        }

        if (this.winner === true) {
            this.message.x = this.app.screen.width / 2.4
            this.message.text = 'Winner !'
        } else if (this.winner === false) {
            this.message.x = this.app.screen.width / 2.3
            this.message.text = 'Lost !'
        }

        if (this.moveCards1) {
            wait(500).then(() => {

                count += 4.0;

                if (this.cards['heart'].x < 490)
                    this.cards['heart'].x += count * Math.PI

                if (this.cards['wajit'].x > 110)
                    this.cards['wajit'].x -= count * Math.PI

                this.moveCards1 = false;
                this.moveCards2 = true;

            })
        }

        if (this.moveCards2) {
            wait(500).then(() => {

                count += 4.0;

                if (this.cards['spade'].x < 480)
                    this.cards['spade'].x += count * Math.PI

                if (this.cards['heart'].x > 310)
                    this.cards['heart'].x -= count * Math.PI


                this.moveCards2 = false;
                this.moveCards3 = true;
            })
        }

        if (this.moveCards3) {
            wait(500).then(() => {

                count += 4.0;

                if (this.cards['wajit'].x < 290)
                    this.cards['wajit'].x += count * Math.PI

                if (this.cards['heart'].x > 110)
                    this.cards['heart'].x -= count * Math.PI


                this.moveCards3 = false;
                this.moveCards4 = true;
            })
        }

        if (this.moveCards4) {
            wait(500).then(() => {

                count += 4.0;

                if (this.cards['heart'].x < 290)
                    this.cards['heart'].x += count * Math.PI

                if (this.cards['spade'].x > 310)
                    this.cards['spade'].x -= count * Math.PI


                this.moveCards4 = false;
                this.moveCards5 = true;
            })
        }

        if (this.moveCards5) {
            wait(500).then(() => {

                count += 4.0;

                if (this.cards['wajit'].x < 490)
                    this.cards['wajit'].x += count * Math.PI

                if (this.cards['heart'].x > 110)
                    this.cards['heart'].x -= count * Math.PI

                this.message.x = this.app.screen.width / 3.7
                this.message.text = "FIND THE ACE OF SPADE";
                this.activePlayer = true;
                this.moveCards5 = false
            })
        }
    }

    configureMessage() {
        const style = new PIXI.TextStyle({
            fontFamily: 'Tahoma',
            fontSize: 22,
            fill: '#FFF',
            fontWeight: 700
        });
        this.message = new PIXI.Text('', style);
        this.message.x = this.app.screen.width / 3.1
        this.message.y = 15
        this.container.addChild(this.message);
    }

    configureLevels() {
        for (var i = 1; i < 7; i++) {
            this.levels[i] = new PIXI.Sprite.from('./assets/images/level_off.png');
            this.levels[i].x = 80 * i
            this.levels[i].y = this.app.screen.height - 60

            const style = new PIXI.TextStyle({
                fontFamily: 'Tahoma',
                fontSize: 22,
                fill: '#FFF',
                fontWeight: 700
            });
            var level = new PIXI.Text(i, style);
            level.x = 18
            level.y = 11
            this.levels[i].addChild(level);
            this.container.addChild(this.levels[i]);
        }
    }

    reset() {
        this.message.text = '';
        this.message.x = this.app.screen.width / 3.1
        this.started = false
        this.moveCards1 = false
        this.moveCards2 = false
        this.moveCards3 = false
        this.moveCards4 = false
        this.moveCards5 = false
        this.activePlayer = false
        this.clicked = null
        this.winner = null

        this.cardPlace.children.forEach((card, i) => {
            this.cardPlace.removeChild(card);
        })

        this.cardPlace.removeChildren();

        this.container.removeChild(this.cardPlace)

        wait(100).then(() => {
            this.configureCards();
        })
    }

    play() {
        this.reset()
        this.message.text = ""
        this.message.x = this.app.screen.width / 2.6
        this.message.text = "SHUFFLING..."
        this.started = true;

        if (this.levels[this.currentLevel - 1])
            this.levels[this.currentLevel - 1].texture = PIXI.Texture.from('./assets/images/level_off.png');

        if (!__.isUndefined(this.levels[this.currentLevel]))
            this.levels[this.currentLevel].texture = PIXI.Texture.from('./assets/images/level_on.png');
    }

    configureCards() {
        this.cards = []
        this.cardPlace = new PIXI.Container();
        this.cardPlace.sortableChildren = true;

        let cards = ['heart', 'spade', 'wajit'];

        cards.forEach((name, i) => {
            this.cards[name] = new PIXI.Sprite.from(`./assets/images/card-${name}.png`);
            this.cards[name].anchor.set(.5)
            this.cards[name].width = 150;
            this.cards[name].height = 216;
            this.cards[name].scale.x = .8;
            this.cards[name].scale.y = .8;
            this.cards[name].name = name;
            this.cards[name].x = 200 * i + 100
            this.cards[name].id = i;
            this.cards[name].y = this.app.screen.height / 2.1
            this.cards[name].interactive = true;
            this.cards[name].buttonMode = true;
            this.cards[name].on("click", (e) => this.onClick(this.cards[name]));
            this.cardPlace.addChild(this.cards[name]);
        })

        this.container.addChild(this.cardPlace)
    }

    onClick(card) {
        if (!this.activePlayer) return;
        this.clicked = card;
        this.engine.target = card.id;
        this.engine.clicked();
    }

    busted(result, isWin) {
        wait(1000).then(() => {
            if (isWin) {
                this.winner = true;
            } else {
                this.winner = false;
            }


            let cards = ['heart', 'spade', 'wajit'];

            cards.forEach((n, i) => {
                this.cardPlace.removeChild(this.cards[n]);
            })

            result.forEach((name, i) => {
                wait(50 * i).then(() => {
                    this.cards[name] = new PIXI.Sprite.from(`./assets/images/card-${name}.png`);
                    this.cards[name].anchor.set(.5)
                    this.cards[name].width = 150;
                    this.cards[name].height = 216;
                    this.cards[name].scale.x = .8;
                    this.cards[name].scale.y = .8;
                    this.cards[name].name = name;
                    this.cards[name].x = 200 * i + 100
                    this.cards[name].y = this.app.screen.height / 2.1
                    this.cards[name].interactive = true;
                    this.cardPlace.addChild(this.cards[name]);
                })
            })

            wait(2000).then(() => {
                if (isWin) {
                    this.currentLevel++
                }
                else {
                    this.currentLevel = 1;
                }
            })
        })
    }
}

export default Canvas
