import React from 'react';
import styled from 'styled-components';
import UserCreditModal from '../../Pages/Parts/User/WalletModal'

const Container = styled.div`
  background-color: #d35400;
  color: white;
  padding: 20px;
`;

const Header = styled.h1`
  font-size: 24px;
  margin-bottom: 5px;
`;

const Subheader = styled.h2`
  font-size: 20px;
  color: #ffd700;
  margin-top: 0;
`;

const Description = styled.p`
  font-size: 14px;
`;

const TiersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const TierCard = styled.div`
  width: 22%;
  border-radius: 10px;
  padding: 10px;
  position: relative;
`;

const TierImage = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
`;

const DepositButton = styled.button`
  position: absolute;
  top: 340px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${props => props.isFirst ? '#ffd700' : 'gray'};
  color: ${props => props.isFirst ? 'black' : 'white'};
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  opacity: ${props => props.isFirst ? 1 : 0};
  transition: opacity 0.3s;
  ${TierImage}:hover & {
    opacity: 1;
  }
`;

const Card = styled.div`
  background: linear-gradient(135deg, #00925D 0%, #1E3B4D 100%);
  border-radius: 12px;
  padding: 24px;
  color: white;
  width: 100%;
  margin-top:12px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin: 4px 0 40px 0;
  opacity: 0.9;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  
  &::after {
    content: '›';
    margin-left: 4px;
  }
`;

const ProgressText = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  font-size: 12px;
  opacity: 0.7;
`;

const MedalText = styled.div`
  text-align: center;
  
  span {
    display: block;
    &:last-child {
      opacity: 0.7;
    }
  }
`;


class Promotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tiers: [
        { color: '#f39c12', title: 'First Deposit', bonus: '180%', src: '/assets/images/promo/promo-1.png' },
        { color: '#e67e22', title: 'Second Deposit', bonus: '240%', src: '/assets/images/promo/promo-2.png' },
        { color: '#9b59b6', title: 'Third Deposit', bonus: '300%', src: '/assets/images/promo/promo-3.png' },
        { color: '#8e44ad', title: 'Fourth Deposit', bonus: '360%', src: '/assets/images/promo/promo-4.png' },
      ],
      showWalletModal: false,
      showCurrencyModal: false,
      activeSection: 'deposit',
    };
  }

  render() {
    return (
      <div style={{ marginTop: '20px' }}>
        <Container>
          <Header>GREAT BONUS FOR EVERY DEPOSIT</Header>
          <Subheader>PPD UNLOCK SPEED +5%</Subheader>
          <Description>Available for all players</Description>
          <TiersContainer>
            {this.state.tiers.map((tier, index) => (
              <TierCard key={index} color={tier.color}>
                <TierImage>
                  <img src={tier.src} style={{ borderRadius: '18px' }} alt={`Tier ${index + 1}`} />
                  <DepositButton onClick={() => this.setState({ showWalletModal: true })} isFirst={index === 0}>Deposit Now</DepositButton>
                </TierImage>
              </TierCard>
            ))}
          </TiersContainer>
        </Container>

        <Card>
      <Title>Master Medals</Title>
      <Subtitle>Witness every step of your becoming a Master</Subtitle>
      <BottomRow>
        <Details>Details</Details>
        <ProgressText>
          <MedalText>
            <span>0</span>
            <span>Medals</span>
          </MedalText>
          <MedalText>
            <span>5</span>
            <span>Medals</span>
          </MedalText>
          <MedalText>
            <span>10</span>
            <span>Medals</span>
          </MedalText>
          <MedalText>
            <span>15</span>
            <span>Medals</span>
          </MedalText>
          <MedalText>
            <span>Max</span>
          </MedalText>
        </ProgressText>
      </BottomRow>
    </Card>
        <UserCreditModal

          showWalletModal={this.state.showWalletModal}
          showCurrencyModal={this.state.showCurrencyModal}
          activeSection={this.state.activeSection}
          onToggleWalletModal={() => this.setState(prevState => ({ showWalletModal: !prevState.showWalletModal }))}
          onToggleCurrencyModal={() => this.setState(prevState => ({ showCurrencyModal: !prevState.showCurrencyModal }))}
          onSetActiveSection={(sectionId) => this.setState({ activeSection: sectionId })}


        />
      </div>
    );
  }
}

export default Promotions;