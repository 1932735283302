import React, { Component, Suspense } from "react";
import ReactTooltip from "react-tooltip";
import {Col, Modal, Row, Card} from "react-bootstrap";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import UserLink from "../Stat/Modal";
import Verify from "../../../../App/Pages/Parts/Sidebar/Verify";
import {__, decode, encode, fixDate, forceSatoshiFormat, Event, wait, timeConvertor, sendNotfication} from "../../../../Helper";

class Game extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: null,
            details: [],
            cards: [],
            clientName: storage.getKey('name'),
            country: storage.getKey('country') ? storage.getKey('country'): "Global"
        };
    }

    componentDidMount() {
        this._isMounted = true;
    
        this.setState({ details: this.props.game,  show: true, effect: 'zoomIn' })

        Event.on('single_game_modal_close', (result) => {
            this.handleClose();
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleClose = () => {
        this.setState({ detail: true, tip: false, show: false, loading: true, effect: 'zoomOut'});
    };

    share = () => {
        let { hash } = this.state.details;
        if(storage.getKey('share') === hash) return;
        storage.setKey('share', hash);
        socket.emit(C.ADD_CHAT, encode({
            country: this.state.country,
            message: this.state.details
        }));
    }

    verify = () => {
        Event.emit('game_verify', this.state.details.hash)
    }

    render(){
        let { uid, name, amount, game, profit, won, coin, created, hash, gid, id, result, cashout, slot, direct, force } = this.state.details;

        let isLost = false;
        let listResult = null;
        let date = fixDate(created);

        cashout = cashout !== undefined ? parseFloat(cashout) / 100 : result;

        if(direct){
            date = timeConvertor(created);
        }

        // if is fake bot
        if(force){
            date = "a few minutes ago"
        }

        profit = profit !== undefined ? __.toNumber(profit) : __.toNumber(won);

        if (profit === 0 || profit === 0.00000000){
            isLost = true;
        }

        let flex = false;

        if(!__.isUndefined(result))
        {
            if(!__.isArray(result))
                listResult = result
            else
            {
                flex = true;
                listResult = result.map((number, i) => <MakeCircle key={__.toString(i)} t={this.props.t} type={'sm'} number={number} />);
            }
        }

        listResult = game === 'crash' ? cashout : listResult;

        if(game === 'crash')
        {
            if(listResult === "NaN")
                listResult = "Betting..."
        }
        
        let selected;

        if(game === 'hilo')
        {
            let cards = [];
            selected = result.selected;
            let uniq = __.uniq(result.cards);
            for(var i = 0; i < uniq.length; i++){
                var num = uniq[i];
                var card = <><img src={'/assets/images/video-poker/cards/' + num + '.png'} className={'img-fluid mt-1'} alt="Last Card" data-tip={'Card ' + (num)} /></>;
                cards.push(card);
            }
            result = <>{cards}</>;
        }

        if(game === 'videopoker')
        {
            let initialHand = [];
            let finalHand = [];
            let addHoldToFinal = [];
            
            if(!__.isNull(result))
            {
                result.initialHand.forEach((e, i) => {
                    var c = e.card;
                    var r = parseFloat(c.rank)
                    var card = <div key={__.toString(i)} className={e.hold === true ? "handCard-modal holder" : "handCard-modal"} id="handCardDisplay0">
                        <img className="img-fluid" src={'/assets/images/video-poker/cards/' + r + '.png'} alt="Card" />
                    </div>;
                    if(c.hold === true){
                        addHoldToFinal.push(c)
                    }
                    initialHand.push(card);
                });

                let final = __.concat(result.finalHand, addHoldToFinal)

                final.forEach((e, i) => {
                    var c = e.card;
                    var r = parseFloat(c.rank)
                    var card = <div key={__.toString(i)} className={e.hold === true ? "handCard-modal holder" : "handCard-modal"} id="handCardDisplay0">
                        <img className="img-fluid" src={'/assets/images/video-poker/cards/' + r + '.png'} alt="Card" />
                    </div>;
                    finalHand.push(card);
                });
            }

            result = <>
                <h4 className="font-12">initial Hand</h4>
                <br/>
                <div className="media">{initialHand}</div>
                <br/>
                <h4 className="font-12">final Hand</h4>
                <br/>
                <div className="media">{finalHand}</div>
            </>;
        }

        if(game === 'blackjack')
        {
            let pCards = [];
            let dCards = [];

            if(!__.isNull(result) && __.isArray(result.pCards))
            {
                result.pCards.forEach((card, i) => {
                    
                    var c = <div key={__.toString(i)} className="carder up" id={"pcard-" + card.value}>
                                <span className="pos red">
                                    <span className="rank">{card.rank}</span>
                                </span>
                              </div>
                    pCards.push(c);
                });

                let firstCards = <div id="phand">{pCards}</div>;

                result.dCards.forEach((card, i) => {
                    var c = <><div key={__.toString(i)} className="carder up" id={"pcard-" + card.value}>
                                <span className="pos red">
                                    <span className="rank">{card.rank}</span>
                                </span>
                              </div>
                            </>;
                    dCards.push(c);
                });

                let secondCards = <div id="phand">{dCards}</div>;

                result = <div className="blackjack-modal"><div className="first">{firstCards}</div><div className="second">{secondCards}</div></div>
            }
            else result = result;
        }

        if(__.isNaN(profit))
            profit = 0;

        return(
            <>
            { this._isMounted &&
                <>
                    <ReactTooltip />
                        <Row className="text-center game-modal">
                            <Col lg="6" md="6" sm="12" className="font-12">
                                <Card className={'mb-1 brd15 text-white'}>
                                    <Card.Body className="p-2 text-uppercase">
                                        <div className="media">
                                            <div className="l">
                                                {name}
                                            </div>
                                            <div className="r">
                                               BET ID: { (gid !== undefined || !gid || gid !== "") ? gid : id }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="text-white font-12">
                                <Card className={'mb-1 brd15 text-white'}>
                                    <Card.Body className="p-2 text-uppercase">
                                        <div className="media">
                                            <div className="l">
                                                <span className="text-white"> Date / Time</span>
                                            </div>
                                            <div className="r">
                                             {date}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="6" md="6" sm="12">
                                <Card className={'mb-1 brd15 text-white'}>
                                    <Card.Body className="p-2 text-uppercase">
                                        <div className="media">
                                            <div className="l">
                                                <span className="text-white"> BET Amount</span>
                                            </div>
                                            <div className="r">
                                                <img className="mini-coin-9" src={'/assets/images/' + __.upperCase(coin) + '.png'} alt="Coin" />
                                                <b className={'num-style'}>{forceSatoshiFormat(amount)} {__.upperCase(coin)}</b>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="6" md="6" sm="12">
                                <Card className={'mb-1 brd15 text-white'}>
                                    <Card.Body className="p-2 text-uppercase">
                                    <div className="media">
                                            <div className="l">
                                                <span className="text-white"> Profit</span>
                                            </div>
                                            <div className="r">
                                                <img className="mini-coin-9" src={'/assets/images/' + __.upperCase(coin) + '.png'} alt="Coin" />
                                                <b className={ !isLost ? 'num-style text-success' : 'num-style text-danger' }>{forceSatoshiFormat(profit)} {__.upperCase(coin)}</b>
                                            </div>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>

                            { game === 'crash' ? 
                            <>
                                <Col lg="6" md="6" sm="12">
                                    <Card className={'mb-1 brd15'}>
                                        <Card.Body className="p-2 text-white text-uppercase">
                                            <div className="media">
                                                <div className="l">
                                                    Payout
                                                </div>
                                                <div className="r">
                                                   {parseFloat(listResult).toFixed(2)}x
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="6" md="6" sm="12">
                                    <Card className={'mb-1 brd15'}>
                                        <Card.Body className="p-2 text-white text-uppercase">
                                          <div className="media">
                                                <div className="l">
                                                    Result
                                                </div>
                                                <div className="r">
                                                    {
                                                        result ? result + "x" : 'Game is Running...' 
                                                    }
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                            :
                            <>
                                {game === 'hilo' ?
                                <>
                                    <Col lg="6" md="6" sm="12">
                                        <Card className={'mb-1 brd15'}>
                                            <Card.Body className="p-2 text-white text-uppercase">
                                                <img src={'/assets/images/icons/hilo.png'} className={'img-fluid w-15 single-game'} data-tip={'hilo'} alt={'Hilo'}/>
                                                Result on <span className="text-warning">{selected}</span>
                                                <br/>
                                                <div className={ "mt-2 font-15 hilo-result"}>
                                                    {result}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                                :
                                <>
                                    { game === 'videopoker' ?
                                        <Col lg="6" md="6" sm="12">
                                            <Card className={'mb-1 brd15'}>
                                                <Card.Body className="p-2 text-white text-uppercase">
                                                    <img src={'/assets/images/icons/videopoker.png'} className={'img-fluid w-15 single-game'} data-tip={'Video Poker'} alt={'Video Poker'}/>
                                                    <div className={ "mt-2 font-15 vpoker-result"}>
                                                        {result}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    :
                                    <>
                                        {game === 'blackjack' ?
                                            <>
                                            <Col lg="6" md="6" sm="12">
                                                    <Card className={'mb-1 brd15'}>
                                                        <Card.Body className="p-2 text-white text-uppercase">
                                                            <img src={'/assets/images/icons/blackJack.png'} className={'img-fluid w-15 single-game'} data-tip={'Blackjack'} alt={'Blackjack'}/>
                                                            Result
                                                            <br/>
                                                            <div className={ "mt-2 font-15 hilo-result"}>
                                                                {result}
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </>
                                            :
                                            <>
                                                { (listResult !== null) &&
                                                <Col lg="6" md="6" sm="12">
                                                    <Card className={'mb-1 brd15'}>
                                                        <Card.Body className="p-2 text-white text-uppercase">
                                                            <img src={'/assets/images/icons/' + game + '.png'} className={'img-fluid w-15 single-game'} data-tip={game} alt={game}/>
                                                            { game === 'crash' ? 'Payout' : 'Result'}
                                                            <br/>
                                                            <div className={ flex ? "mt-3 font-15 media center-flex ovh" : "mt-2 font-15"}>
                                                                {listResult}
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                }
                                            </>
                                        }
                                    </>
                                    }
                                </>
                            }
                            </>
                            }
                             <Col md={ game === 'crash' ? 12 : 6 } sm={6}>
                                {
                                    (__.toString(hash).length > 0) &&
                                    <>
                                        <Card className={'mb-1 brd15'}>
                                            <Card.Body className="p-1 text-white text-uppercase">
                                            <div className="form-group mb-1">
                                                <label className="text-white mt-1 crs-def">Check Game</label>
                                            {/*    <input type="text" className="form-control text-white brd15 mb-2"
                                                style={{ background: 'transparent', fontSize: 11, textAlign: 'center' }} value={__.toString(hash)} readOnly={true} />
                                            */}</div>
                                            {
                                                !__.isUndefined(hash) &&
                                                <>
                                                    {(__.isUndefined(slot) || slot === null) &&
                                                        <>
                                                            <button onClick={this.verify} className="btn btn-cs8 btn-block btn-xs btn-block no-shadow">
                                                                <i className="mdi mdi-shield nts" /> Verify Result
                                                            </button>
                                                        </>
                                                    }
                                                </>
                                            }
                                            </Card.Body>
                                        </Card>
                                    </>
                                }
                             </Col>
                        </Row>
                </>
             }
            </>
        );
    }
}

function MakeCircle(props){
    const copyHash = (value) =>{
      var t = document.createElement("input");
      t.value = value;
      document.body.appendChild(t);
      t.select();
      t.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(t);
      sendNotfication('Copied !', 'info', 'top-center');
    };

    let result = props.number;

    if(!__.isUndefined(result.num))
    {
        return(
            <>
                <div className={'avatar-box align-self-center mx-1 my-1 cpt thumb-' + props.type} 
                    data-tip={ 'Click to copy hash' + ":" + result.hash}
                    onClick={e => copyHash(result.hash)}>
                    <span className="avatar-title bg-purple rounded text">{result.num}</span>
                </div>
            </>
        ); 
    }

    return(
        <>
            <div className={'avatar-box align-self-center mx-1 my-1 thumb-' + props.type}>
                <span className="avatar-title bg-purple rounded text">{result}</span>
            </div>
        </>
    );
}

export default Game
