import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import storage from "../../../Storage";
// import { getUID, SITE_URL } from "../../../Helper";
import Terms from "./Terms";
import List from "./List";
import styled from 'styled-components';
import { Copy, Star, Check } from 'lucide-react';
import AffiliateDashboard from '../../Components/AffiliateDashboard'
import {Link} from 'react-router-dom';
import Cookies from "js-cookie";


const AffiliateContainer = styled.div`
  border-radius: 20px;
  background-color: #5B2380;
  position: relative;
  overflow: hidden;
  height: 500px;
  margin-top: 40px;

  @media (max-width: 768px) {
    height: auto;
    min-height: 400px;
  }
`;

const AffiliateImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;

  @media (max-width: 768px) {
    opacity: 0.3;
    max-height: 80%;
  }
`;

const CardAffiliate = styled.div`
  background: linear-gradient(135deg, #8e2de2, #4a00e0);
  border-radius: 20px;
  padding: 24px;
  color: white;
  max-width: 300px;
  font-family: Arial, sans-serif;
  position: absolute;
  top: 80px;
  left: 153px;

  @media (max-width: 1024px) {
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    margin: 20px auto;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  margin: 0 0 16px;
  text-align: center;
  color: #FFB512;
`;

const Stars = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const Commission = styled.p`
  font-size: 18px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const ReferralLink = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

// const Link = styled.span`
//   font-size: 14px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// `;

const Button = styled.button`
  background-color: #ffd700;
  color: #4a00e0;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
  &:hover {
    background-color: #ffed4a;
  }
`;

const ButtonRewardBottom = styled.button`
  background-color: #31343B;
  margin-top: 12px;
  color: #43B306;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  width: 40%;
  
  &:hover {
    background-color: #333;
  }
`;

const CardContainerReward = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
  
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const CardReward = styled.div`
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 20px;
  color: white;
  flex: 1;
`;

const CardTitleReward = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const GreenText = styled.span`
  color: #4caf50;
`;

const Amount = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
  color: #EFA900;
`;

const SubText = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
`;
const AffliateDashbaord = styled(Link)`
position:absolute;
top:12px;
right:12px;
`;

const ButtonRewardAffilate = styled.button`
  background-color: #DE8B38;
  color: #4caf50;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  

`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;


// Add these styled components at the end of your file
const MembersTableContainer = styled.div`
    margin: 20px auto;
    width:100%;
    padding: 20px;
    background-color: #1E2024;
    border-radius: 10px;
`;

const MembersTableTitle = styled.h2`
    color: white;
    text-align: center;
    margin-bottom: 15px;
`;

const MembersCount = styled.p`
    color: #4caf50;
    text-align: center;
    margin-bottom: 15px;
`;

const MembersTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    
    th, td {
        padding: 12px;
        text-align: left;
        // border-bottom: 1px solid #2E3034;
        color: white;
    }
    
    th {
        background-color: #2E3034;
    }
`;

const NoMembers = styled.p`
    color: white;
    text-align: center;
    font-style: italic;
`;


const getUID = () => parseFloat(Cookies.get("uid"));

const Affiliate = () => {
    const [token] = useState(storage.getKey('token') || null);
    const [uid] = useState(getUID()); // Get UID immediately from cookie
    const [logged, setLogged] = useState(false);
    const [refrelCode, setRefrelCode] = useState("");
    const [refrelLink, setRefrelLink] = useState("");
    const [copiedLink, setCopiedLink] = useState(false);
    const [copiedCode, setCopiedCode] = useState(false);
    const [openDashbaord, setOpenDashbaord] = useState(false);
    const [members, setMembers] = useState([]);
    const [totalMembers, setTotalMembers] = useState(0);
    const [ownerName, setOwnerName] = useState("");
    const [todayRewards, setTodayRewards] = useState(0);

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
        if (token !== null) {
            setLogged(true);
        }

        // Fetch all data immediately
        fetchAllData();
    }, []);

    const fetchAllData = () => {
        getRefrelCode();
        getRefrelLink();
        getMembers();
        getTodayRewards();
    };

    const getTodayRewards = () => {
        fetch(`https://api.ibitplay.com/api/rewards/${uid}?filter=today`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.totalAmount) {
                setTodayRewards(data.totalAmount);
            }
        })
        .catch(error => console.log("error fetching today's rewards", error));
    };

    const getMembers = () => {
        fetch(`https://api.ibitplay.com/api/members/${uid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            setOwnerName(data.ownername);
            setTotalMembers(data.totalMembers);
            setMembers(data.members);
        })
        .catch(error => console.log("error fetching members", error));
    };

    const getRefrelCode = () => {
        fetch(`https://api.ibitplay.com//get-referral-code/${uid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.referralCode) {
                setRefrelCode(data.referralCode);
            }
        })
        .catch(error => console.log("error", error));
    };

    const getRefrelLink = () => {
        fetch(`https://api.ibitplay.com/get-referral-link/${uid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.referralLink) {
                setRefrelLink(data.referralLink);
            }
        })
        .catch(error => console.log("error", error));
    };

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text).then(() => {
            if (type === 'link') {
                setCopiedLink(true);
                setTimeout(() => setCopiedLink(false), 2000);
            } else {
                setCopiedCode(true);
                setTimeout(() => setCopiedCode(false), 2000);
            }
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };


    return (
        <>
            <Helmet>
                <title>Affiliate - Crypto Casino Games</title>
                <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, PlayCrash.com Slot Games" />
                <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                <link rel="canonical" href="/affiliate" />
            </Helmet>
            <div>

  <AffiliateContainer>
  <AffliateDashbaord to="/affiliate-dashbaord" ><ButtonRewardAffilate>Affiliate dashboard</ButtonRewardAffilate></AffliateDashbaord>
    <AffiliateImage
      src="https://static.nanogames.io/assets/banner.character.ce48e661.png"
      alt="Affiliate Banner Character"
    />
    <CardAffiliate>
      <Stars>
        {[...Array(5)].map((_, i) => (
          <Star key={i} fill="#ffd700" stroke="#ffd700" size={24} />
        ))}
      </Stars>
      <Title>AFFILIATE</Title>
      <Commission>
        COMMISSION READY EVERYDAY
        <br />
        $100 JUST A REFERRAL AWAY
      </Commission>
      <ReferralLink>
        <input 
          style={{ backgroundColor: 'transparent', border: 'none', outline: '0', color: 'white', width: '90%' }} 
          type="text" 
          value={logged ? refrelLink : "Please login to see your link."} 
          readOnly
        />
        <CopyButton onClick={() => copyToClipboard(refrelLink, 'link')}>
          {copiedLink ? <Check size={20} color="#4caf50" /> : <Copy size={20} />}
        </CopyButton>
      </ReferralLink>
      
      <ReferralLink>
        <input 
          style={{ backgroundColor: 'transparent', border: 'none', outline: '0', color: 'white', width: '90%' }} 
          type="text" 
          value={logged ? refrelCode : "Please login to see your code."} 
          readOnly
        />
        <CopyButton onClick={() => copyToClipboard(refrelCode, 'code')}>
          {copiedCode ? <Check size={20} color="#4caf50" /> : <Copy size={20} />}
        </CopyButton>
      </ReferralLink>
    </CardAffiliate>
  </AffiliateContainer>

  <CardContainerReward>
    <CardReward>
      <CardTitleReward>
        <img src="https://static.nanogames.io/assets/rewards.dadef713.png" alt="USD Icon" width="200" height="200" />
        <span>EXTRA <GreenText>USD REWARDS</GreenText></span>
      </CardTitleReward>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a style={{ textAlign: 'center', color: '#191B1E' }} href="#">USD Rewards Rules &gt;</a>
      </div>
      <div style={{ backgroundColor: '#1E2024', borderRadius: '10px', textAlign: 'center', padding: '6px' }}>
        <Amount>0 USD</Amount>
        <SubText>Newly Available USD Rewards</SubText>
      </div>
      <SubText>Received: 0 USD | Locked: 0 USD</SubText>
      <SubText>Your current friends staying active will unlock you more USD Rewards</SubText>
      <div style={{ display:'flex', justifyContent:'center', width:'100%' }}>
        <ButtonRewardBottom>Withdraw</ButtonRewardBottom>
      </div>
    </CardReward>

    <CardReward>
      <CardTitleReward>
        <img src="https://static.nanogames.io/assets/commission.62eb3f82.png" alt="Bitcoin Icon" width="200" height="200" />
        <span>MY <GreenText>COMMISSION REWARDS</GreenText></span>
      </CardTitleReward>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a style={{ textAlign: 'center', color: '#191B1E' }} href="#">Commission Reward Rules &gt;</a>
      </div>
      <div style={{ backgroundColor: '#1E2024', borderRadius: '10px', textAlign: 'center', padding: '6px' }}>
        <Amount>0 USD</Amount>
        <SubText>Newly Available Commission Rewards</SubText>
      </div>
      <div style={{ backgroundColor: '#1E2024', borderRadius: '10px', textAlign: 'center', padding: '8px', marginBottom:'4px', marginTop:'8px' }}>
        <SubText>Commission You've Received in Total: 0 USD</SubText>
      </div>
      <div style={{ display:'flex', justifyContent:'center', width:'100%' }}>
        <ButtonRewardBottom>Withdraw</ButtonRewardBottom>
      </div>
    </CardReward>
  </CardContainerReward>

  <MembersTableContainer>
    <MembersTableTitle>Your Friends</MembersTableTitle>
    <MembersCount>Total Friends: {totalMembers}</MembersCount>
    { members&& members.length > 0 ? (
      <MembersTable>
        <thead>
          <tr>
            <th>Friend Name</th>
            <th>Commision Recieved</th>
          </tr>
        </thead>
        <tbody>
          { members&& members.length>0 && members.map((member, index) => (
            <tr key={index}>
              <td>{member.membername}</td>
              <td>{member.total_commission}</td>
            </tr>
          ))}
        </tbody>
      </MembersTable>
    ) : (
      <NoMembers>No referrals yet</NoMembers>
    )}
  </MembersTableContainer>
</div>

        </>
    );
};

export default Affiliate;