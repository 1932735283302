// import React from 'react'
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import Cookies from "js-cookie";
// import Sidebar from './Parts/Sidebar';
// import Footer from './Parts/Footer';
// import socket from "../../Socket";
// import { Event, decode, encode, wait, isMobile, sendNotfication } from "../../Helper";
// import WalletAlert from "../../App/Components/User/Wallet/Alert";
// import C from "../../Constant";
// import storage from "../../Storage";

// import UserHeader from './Parts/Header/Header-User';
// import GuestHeader from './Parts/Header/Header-Guest';
// import SidebarNew from '../Components/SiebarNew';
// import UserHeaderNew from '../Components/SiebarNew/UserHeader';

// class Index extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             auth: false,
//             header: <GuestHeader t={this.props.t} location={this.props.location} />
//         }
//     }

//     componentDidMount() {
//         document.body.scrollTop = 0;
//         document.documentElement.scrollTop = 0;

//         socket.on(C.ONLINE, (status) => this.onlineUser(decode(status)));

//         Event.on('showAuthModal', () => {
//             sendNotfication("Please Login or Register !", 'success', 'bottom-left', true);
//         })

//         /**
//          * Initialize Authentication
//          */
//         const sessionCookie = Cookies.get("session");

//         if (sessionCookie) {
//             socket.emit(C.ONLINE, encode({
//                 jwt_token: storage.getKey('jwt_token'), //fake
//                 user_token: storage.getKey('user_token'), //fake
//                 security_key: storage.getKey('security_key'), //fake
//                 secret_user: storage.getKey('secret_user'), //fake
//                 secret_realtime: storage.getKey('secret_realtime'), //fake
//                 client_system: storage.getKey('client_system'), //fake
//                 token_key: storage.getKey('token_key'), //fake
//                 secret_token: storage.getKey('secret_token') //fake
//             }));
//         }
//     }

//     activeAuth = (status) => {
//         this.setState({ auth: status });
//     }

//     onlineUser = (data) => {
//         wait(500).then(() => {
//             if (data.status === true) {
//                 storage.setKey('logged', true);
//                 Cookies.set("uid", data.id, { expires: 14 });
//                 Cookies.set("auth", true, { expires: 14 });
//                 storage.setKey('name', data.name);
//                 storage.setKey('email', data.email);
//                 storage.setKey('credit', data.credit);
//                 storage.setKey('avatar', data.avatar);
//                 storage.setKey('friends', data.friends);
//                 storage.setKey('room', data.room);

//                 wait(100).then(() => {
//                     this.setState({ header: <UserHeader t={this.props.t} location={this.props.location} /> });
//                 })
//             }
//             else {
//                 wait(1000).then(() => {
//                     localStorage.clear();
//                     this.setState({ header: <GuestHeader t={this.props.t} location={this.props.location} /> })
//                 })
//             }
//         })
//     }

//     render() {
//         let { header } = this.state;
//         let { content } = this.props; // Pages / Games Contents
//         let wallet; // Show Wallet if User don't Have Cash

//         try {
//             wallet = this.props.get_wallet.show;
//         }
//         catch (e) { }

//         const { t } = this.props;
//         return (
//             <>
//                 {header}
//                 {
//                     !isMobile() &&
//                     <div className="under-top"></div>
//                 }
//                 <div className="page-wrapper">
//                     {
//                         !isMobile() &&
//                         <Sidebar t={t} />
//                         // (<SidebarNew/>)
//                     }
//                     <div className="page-content" id={'page-content'} >
//                         {wallet &&
//                             <WalletAlert t={t} uid={this.props.get_wallet.uid} />
//                         }
//                         {content}
//                         <Footer />
//                     </div>
//                 </div>
//             </>

//         //     <div className="app-container">
//         //     {header}
//         //     <div className="main-content">
//         //       {!isMobile() && <Sidebar t={t} />}
//         //       <div className="scrollable-content">
//         //         {wallet && (
//         //           <WalletAlert t={t} uid={this.props.get_wallet.uid} />
//         //         )}
//         //         {content}
//         //         <Footer />
//         //       </div>
//         //     </div>
//         //   </div>
//         );
//     }
// }

// Index.propTypes = {
//     get_wallet: PropTypes.string
// };

// const mapStateToProps = state => ({
//     get_wallet: state.items.get_wallet
// });

// export default connect(mapStateToProps, null)(Index);



import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import SidebarHello from '../Components/HelloGameLayoutComponent/SidebarHello';
import Header from '../Components/HelloGameLayoutComponent/HederHello';
import styled from 'styled-components';
import { Event, decode, encode, wait, isMobile, sendNotfication } from "../../Helper";
import WalletAlert from "../../App/Components/User/Wallet/Alert";
import C from "../../Constant";
import storage from "../../Storage"
import UserHeader from './Parts/Header/Header-User';
import GuestHeader from './Parts/Header/Header-Guest';
import socket from "../../Socket";
import MobileMenu from './Parts/Menu/MobileMenu';
import HelloFooter from '../Components/HelloGameLayoutComponent/HelloFooter';

const SIDEBAR_WIDTH = 239;
const HEADER_HEIGHT = 60;

const AppContainer = styled.div`
  display: flex;
  background-color:#222529;
  color: white;
  font-family: Arial, sans-serif;
    // z-index:1000;
`;

const MainArea = styled.div`
  flex: 1;
  padding-top:60px;
  margin-left:239px;
  min-height: 100vh;
  width: calc(100vw - ${SIDEBAR_WIDTH}px);
  overflow-x: hidden;
    z-index:-1 important;
margin-left: ${props => props.isSmallScreen ? '0px' : props.isCollapsed ? '80px' : '239px'};

`;

const ContentContainer = styled.div`
  padding: 20px;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);

`;

const HeaderMainConatiner = styled.div`
position:fixed;
top: 0;
left: ${props => props.isSmallScreen ? '0px' : props.isCollapsed ? '80px' : '239px'};
right: 0;
z-index: 666;
  width: 100% !important;
`;



const Index = ({ t, location, get_wallet, content }) => {
  const [auth, setAuth] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCollapsedMobile, setIsCollapsedMobile] = useState(false);

  console.log("iscolllllllll", isCollapsed)
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [header, setHeader] = useState(<GuestHeader  isCollapsedMobile={isCollapsedMobile} setIsCollapsedMobile={setIsCollapsedMobile} />);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.on(C.ONLINE, (status) => onlineUser(decode(status)));

    Event.on('showAuthModal', () => {
      sendNotfication("Please Login or Register !", 'success', 'bottom-left', true);
    });

    const sessionCookie = Cookies.get("session");
    if (sessionCookie) {
      socket.emit(C.ONLINE, encode({
        jwt_token: storage.getKey('jwt_token'),
        user_token: storage.getKey('user_token'),
        security_key: storage.getKey('security_key'),
        secret_user: storage.getKey('secret_user'),
        secret_realtime: storage.getKey('secret_realtime'),
        client_system: storage.getKey('client_system'),
        token_key: storage.getKey('token_key'),
        secret_token: storage.getKey('secret_token')
      }));
    }

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);

    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const activeAuth = (status) => {
    setAuth(status);
  };

  const onlineUser = (data) => {
    wait(500).then(() => {
      if (data.status === true) {
        storage.setKey('logged', true);
        Cookies.set("uid", data.id, { expires: 14 });
        Cookies.set("auth", true, { expires: 14 });
        storage.setKey('name', data.name);
        storage.setKey('email', data.email);
        storage.setKey('credit', data.credit);
        storage.setKey('avatar', data.avatar);
        storage.setKey('friends', data.friends);
        storage.setKey('room', data.room);

        wait(100).then(() => {
          setHeader(<UserHeader isCollapsedMobile={isCollapsedMobile} setIsCollapsedMobile={setIsCollapsedMobile} />);
        });
      }
      else {
        wait(1000).then(() => {
          localStorage.clear();
          setHeader(<GuestHeader  isCollapsedMobile={isCollapsedMobile} setIsCollapsedMobile={setIsCollapsedMobile}  />);
        });
      }
    });
  };

  let wallet;
  try {
    wallet = get_wallet.show;
  }
  catch (e) { }

  return (
    <>
      <AppContainer>
        {
          isSmallScreen ? (<MobileMenu isCollapsedMobile={isCollapsedMobile} setIsCollapsedMobile={setIsCollapsedMobile} />) : (<SidebarHello isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />)
        }
        <MainArea isSmallScreen={isSmallScreen} isCollapsed={isCollapsed}>
          <HeaderMainConatiner isSmallScreen={isSmallScreen} isCollapsed={isCollapsed}>

            {header}
          </HeaderMainConatiner>
          <ContentContainer isSmallScreen={isSmallScreen} isCollapsed={isCollapsed}>
            {content}
          </ContentContainer>
          <HelloFooter/>
        </MainArea>
        
      </AppContainer>
    </>
  );
};

AppContainer.propTypes = {
  get_wallet: PropTypes.string
};

const mapStateToProps = state => ({
  get_wallet: state.items.get_wallet
});

export default connect(mapStateToProps, null)(Index);














