const games = [{
        gamename: "Fruit Super Nova",
        provider: "evo", 
        gameid: "fruitsupernova",
        default_image: "/assets/images/slots/fruitsupernova.jpg",
    }, {
        gamename: "Elven Princess",
        provider: "evo", 
        gameid: "elvenprincess",
        default_image: "/assets/images/slots/elvenprincess.jpg",
    }, {
        gamename: "Legend of ra",
        provider: "evo", 
        gameid: "legendofra",
        default_image: "/assets/images/slots/legendofra.jpg",
    }, {
        gamename: "Vegas Nights",
        provider: "evo", 
        gameid: "vegasnights",
        default_image: "/assets/images/slots/vegasnights.jpg",
    }, {
        gamename: "Fruit Burst",
        provider: "evo", 
        gameid: "fruitburst",
        default_image: "/assets/images/slots/fruitburst.jpg",
    }, {
        gamename: "Dungeon",
        provider: "evo", 
        gameid: "dungeon",
        default_image: "/assets/images/slots/dungeon.jpg",
    }, {
        gamename: "Slavs",
        provider: "evo", 
        gameid: "slavs",
        default_image: "/assets/images/slots/slavs.jpg",
    },
    {
        gamename: "Nuke World",
        provider: "evo", 
        gameid: "nukeworld",
        default_image: "/assets/images/slots/nukeworld.jpg",
    }, {
        gamename: "Fluffy Rangers",
        provider: "evo", 
        gameid: "fluffyrangers",
        default_image: "/assets/images/slots/fluffyrangers.jpg",
    }, {
        gamename: "Dolphins Treasure",
        provider: "evo", 
        gameid: "dolphinstreasure",
        default_image: "/assets/images/slots/dolphinstreasure.jpg",
    }, {
        gamename: "Clash of Pirates",
        provider: "evo", 
        gameid: "clashofpirates",
        default_image: "/assets/images/slots/clashofpirates.jpg",
    }, {
        gamename: "Charming Queens",
        provider: "evo", 
        gameid: "charmingqueens",
        default_image: "/assets/images/slots/charmingqueens.jpg",
    }, {
        gamename: "Reign Of Dragons",
        provider: "evo", 
        gameid: "reignofdragons",
        default_image: "/assets/images/slots/reignofdragons.jpg",
    }, {
        gamename: "Battle Tanks",
        provider: "evo", 
        gameid: "battletanks",
        default_image: "/assets/images/slots/battletanks.jpg",
    }, {
        gamename: "Ace Round",
        provider: "evo", 
        gameid: "aceround",
        default_image: "/assets/images/slots/aceround.jpg",
    },
    {
        gamename: "Trip to the Future",
        provider: "evo", 
        gameid: "triptothefuture",
        default_image: "/assets/images/slots/triptothefuture.jpg",
    }, {
        gamename: "Football",
        provider: "evo", 
        gameid: "football",
        default_image: "/assets/images/slots/football.jpg",
    }, {
        gamename: "Atlantis",
        provider: "evo", 
        gameid: "atlantis",
        default_image: "/assets/images/slots/atlantis.jpg",
    }, {
        gamename: "Sindbad",
        provider: "evo", 
        gameid: "sindbad",
        default_image: "/assets/images/slots/sindbad.jpg",
    },
    {
        gamename: "Seventies",
        provider: "evo", 
        gameid: "seventies",
        default_image: "/assets/images/slots/seventies.jpg",
    }, {
        gamename: "Island Totems",
        provider: "evo", 
        gameid: "islandtotems",
        default_image: "/assets/images/slots/islandtotems.jpg",
    }, {
        gamename: "Brutal Santa",
        provider: "evo", 
        gameid: "brutalsanta",
        default_image: "/assets/images/slots/brutalsanta.jpg",
    }, {
        gamename: "Hungry Night",
        provider: "evo", 
        gameid: "hungrynight",
        default_image: "/assets/images/slots/hungrynight.jpg",
    }, {
        gamename: "Rise Of Horus",
        provider: "evo", 
        gameid: "riseofhorus",
        default_image: "/assets/images/slots/riseofhorus.jpg",
    }, {
        gamename: "Rocket Stars",
        provider: "evo", 
        gameid: "rocketstars",
        default_image: "/assets/images/slots/rocketstars.jpg",
    }, {
        gamename: "Sea Of Spins",
        provider: "evo", 
        gameid: "seaofspins",
        default_image: "/assets/images/slots/seaofspins.jpg",
    }, {
        gamename: "Raccoon Tales",
        provider: "evo", 
        gameid: "raccoontales",
        default_image: "/assets/images/slots/raccoontales.jpg",
    }, {
        gamename: "Tree Of Light",
        provider: "evo", 
        gameid: "treeoflight",
        default_image: "/assets/images/slots/treeoflight.jpg",
    }, {
        gamename: "Wild Bullets",
        provider: "evo", 
        gameid: "wildbullets",
        default_image: "/assets/images/slots/wildbullets.jpg",
    }, {
        gamename: "Runes Of Destiny",
        provider: "evo", 
        gameid: "runesofdestiny",
        default_image: "/assets/images/slots/promo.jpg",
    }, {
        gamename: "Ellens Fortune",
        provider: "evo", 
        gameid: "ellensfortune",
        default_image: "/assets/images/slots/ellensfortune.jpg",
    }, {
        gamename: "Unlimited Wishes",
        provider: "evo", 
        gameid: "unlimitedwishes",
        default_image: "/assets/images/slots/unlimitedwishes.jpg",
    }, {
        gamename: "Sweet Sugar",
        provider: "evo", 
        gameid: "sweetsugar",
        default_image: "/assets/images/slots/sweetsugar.jpg",
    }, {
        gamename: "Blackjack",
        provider: "evo", 
        gameid: "blackjack",
        default_image: "/assets/images/slots/blackjack.jpg",
    }, {
        gamename: "Texas Holdem Poker 3d",
        provider: "evo", 
        gameid: "texasholdempoker3d",
        default_image: "/assets/images/slots/texasholdempoker3d.jpg",
    }, {
        gamename: "Europian Roulette",
        provider: "evo", 
        gameid: "europeanroulette",
        default_image: "/assets/images/slots/europianroulette.jpg",
    }, {
        gamename: "Baccarat",
        provider: "evo", 
        gameid: "baccarat",
        default_image: "/assets/images/slots/baccarat.jpg",
    }, {
        gamename: "French Roulette Classic",
        provider: "evo", 
        gameid: "frenchrouletteclassic",
        default_image: "/assets/images/slots/frenchrouletteclassic.jpg",
    }, {
        gamename: "American Roulette",
        provider: "evo", 
        gameid: "americanroulette",
        default_image: "/assets/images/slots/americanroulette.jpg",
    }, {
        gamename: "Roll the Dice",
        provider: "evo", 
        gameid: "rolldice",
        default_image: "/assets/images/slots/rollthedice.jpg",
    }, {
        gamename: "Magic Wheel",
        provider: "evo", 
        gameid: "magicwheel",
        default_image: "/assets/images/slots/magicwheel.jpg",
    }, {
        gamename: "More or Less",
        provider: "evo", 
        gameid: "moreorless",
        default_image: "/assets/images/slots/moreorless.jpg",
    }, {
        gamename: "Bomb Squad",
        provider: "evo", 
        gameid: "bombsquad",
        default_image: "/assets/images/slots/bombsquad.jpg",
    }, {
        gamename: "Four Aces",
        provider: "evo", 
        gameid: "fouraces",
        default_image: "/assets/images/slots/fouraces.jpg",
    }, {
        gamename: "Thimbles",
        provider: "evo", 
        gameid: "thimbles",
        default_image: "/assets/images/slots/thimbles.jpg",
    }, {
        gamename: "Rock Paper Scissors",
        provider: "evo", 
        gameid: "rockpaperscissors",
        default_image: "/assets/images/slots/rockpaperscissors.jpg",
    }, {
        gamename: "Wheel of Time",
        provider: "evo", 
        gameid: "wheeloftime",
        default_image: "/assets/images/slots/wheeloftime.jpg",
    }, {
        gamename: "High Striker",
        provider: "evo", 
        gameid: "highstriker",
        default_image: "/assets/images/slots/highstriker.jpg",
    }, {
        gamename: "Mine field",
        provider: "evo", 
        gameid: "minefield",
        default_image: "/assets/images/slots/minefield.jpg",
    }, {
        gamename: "Panchin Girl",
        provider: "evo", 
        gameid: "pachingirl",
        default_image: "/assets/images/slots/panchingirl.jpg",
    }, {
        gamename: "Red Queen",
        provider: "evo", 
        gameid: "redqueen",
        default_image: "/assets/images/slots/redqueen.jpg",
    }, {
        gamename: "Mafia",
        provider: "evo", 
        gameid: "mafia",
        default_image: "/assets/images/slots/mafia.jpg",
    }, {
        gamename: "ET Races",
        provider: "evo", 
        gameid: "etraces",
        default_image: "/assets/images/slots/etraces.jpg",
    }, {
        gamename: "Christmas Party",
        provider: "evo", 
        gameid: "christmasparty",
        default_image: "/assets/images/slots/christmasparty.jpg",
    }, {
        gamename: "Mysteries Of Theeast",
        provider: "evo", 
        gameid: "mysteriesoftheeast",
        default_image: "/assets/images/slots/mysteriesoftheeast.jpg",
    }, {
        gamename: "Night Of The Living Tales",
        provider: "evo", 
        gameid: "livingtales",
        default_image: "/assets/images/slots/nightofthelivingtales.jpg",
    }, {
        gamename: "Crown Anchor",
        provider: "evo", 
        gameid: "crownanchor",
        default_image: "/assets/images/slots/crownanchor.jpg",
    }, {
        gamename: "Indian Poker",
        provider: "evo", 
        gameid: "indianpoker",
        default_image: "/assets/images/slots/indianpoker.jpg",
    }, {
        gamename: "cabaret",
        provider: "evo", 
        gameid: "cabaret",
        default_image: "/assets/images/slots/cabaret.jpg",
    }
];

export default games;
