import React from 'react';
import styled from 'styled-components';

const data = [
    { level: 'SVIP 1', xpAmount: 8577000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 2', xpAmount: 10625000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 3', xpAmount: 12033000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 4', xpAmount: 13441000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 5', xpAmount: 14849000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 6', xpAmount: 16257000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 7', xpAmount: 17665000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 8', xpAmount: 19073000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 9', xpAmount: 20481000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 10', xpAmount: 23553000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 11', xpAmount: 26625000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 12', xpAmount: 29697000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 13', xpAmount: 32769000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 14', xpAmount: 35841000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 15', xpAmount: 38913000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 16', xpAmount: 41985000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 17', xpAmount: 45057000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 18', xpAmount: 51713000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 19', xpAmount: 58369000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 20', xpAmount: 65025000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 21', xpAmount: 71681000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 22', xpAmount: 78337000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 23', xpAmount: 84993000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 24', xpAmount: 91649000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 25', xpAmount: 98305000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 26', xpAmount: 112641000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 27', xpAmount: 126977000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 28', xpAmount: 141313000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 29', xpAmount: 155649000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 30', xpAmount: 169985000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 31', xpAmount: 184321000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 32', xpAmount: 198657000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' },
    { level: 'SVIP 33', xpAmount: 212993000, xpProgress: 'Not Started', star: 2, bonus: '/assets/images/diamondStar.webp', iconPath: '/assets/images/diamondStar.webp' }
  ];

const Table = styled.table`
  width: 100%;
  color: #ffffff;
  border-collapse: collapse;
`;

const TableContainer = styled.div`
  width: 100%;
  background-color: #1E2024;
  color: #ffffff;
  padding: 12px;
  border-radius: 12px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  font-weight: normal;
  color: #F5F6F7;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #282B30;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  color: #F5F6F7;
`;

const LevelIcon = styled.img`
  width: 14px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
`;

const ProgressBar = styled.div`
  width: 50px;
  height: 4px;
  background-color: #2e2e2e;
  border-radius: 2px;
`;

const StarContainer = styled.div`
  display: flex;
`;

const Star = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.active ? '#ffffff' : '#4e4e4e'};
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  margin-right: 2px;
`;

const BonusIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const DiamondStarTable = () => {
    return (
        <div style={{margin:'10px 0'}}>
            
            <TableContainer>
                <div style={{padding:'20px'}}>
                    <div style={{ color: '#F5F6F7' }}>Diamond Card</div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Level</TableHeader>
                            <TableHeader>XP Amount</TableHeader>
                            <TableHeader>XP Progress Bar</TableHeader>
                            <TableHeader>Star</TableHeader>
                            <TableHeader>Bonus</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <LevelIcon src={row.iconPath} alt={row.level} />
                                    {row.level}
                                </TableCell>
                                <TableCell>{row.xpAmount}</TableCell>
                                <TableCell>
                                    {row.xpProgress}
                                    <ProgressBar />
                                </TableCell>
                                <TableCell>
                                    <StarContainer>
                                        {['/assets/images/diamondStar.webp', '/assets/images/diamondStar.webp', '/assets/images/diamondStar.webp', '/assets/images/diamondStar.webp', '/assets/images/diamondStar.webp'].map((_, i) => (
                                            <LevelIcon key={i} src={row.iconPath} alt={row.level} />
                                        ))}
                                    </StarContainer>
                                </TableCell>
                                <TableCell>
                                    <BonusIcon src={row.bonus} alt={row.bonus} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default DiamondStarTable;