import React from 'react';
import styled from 'styled-components';


const data= [

    { level: 'VIP 08', xpAmount: 5000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 09', xpAmount: 7000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 10', xpAmount: 9000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 11', xpAmount: 11000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 12', xpAmount: 13000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 13', xpAmount: 15000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 14', xpAmount: 17000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 15', xpAmount: 21000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 16', xpAmount: 25000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 17', xpAmount: 29000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 18', xpAmount: 33000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 19', xpAmount: 37000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 20', xpAmount: 41000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 21', xpAmount: 45000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' }
  ];

  // { level: 'VIP 08', minXP: 5000, maxXP: 5999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 09', minXP: 6000, maxXP: 6999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 10', minXP: 7000, maxXP: 7999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 11', minXP: 8000, maxXP: 8999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 12', minXP: 9000, maxXP: 9999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 13', minXP: 10000, maxXP: 10999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 14', minXP: 11000, maxXP: 11999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 15', minXP: 12000, maxXP: 12999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 16', minXP: 13000, maxXP: 13999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 17', minXP: 14000, maxXP: 14999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 18', minXP: 15000, maxXP: 15999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 19', minXP: 16000, maxXP: 16999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 20', minXP: 17000, maxXP: 17999, card: 'silver', iconPath: '/assets/images/starVip.png' },
  // { level: 'VIP 21', minXP: 18000, maxXP: 18999, card: 'silver', iconPath: '/assets/images/starVip.png' },

const Table = styled.table`
  width: 100%;
  color: #ffffff;
  border-collapse: collapse;
`;

const TableContainer = styled.div`
  width: 100%;
  background-color: #1E2024;
  color: #ffffff;
  padding: 12px;
  border-radius: 12px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  font-weight: normal;
  color: #F5F6F7;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #282B30;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  color: #F5F6F7;
`;

const LevelIcon = styled.img`
  width: 14px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
`;

const ProgressBar = styled.div`
  width: 50px;
  height: 4px;
  background-color: #2e2e2e;
  border-radius: 2px;
`;

const StarContainer = styled.div`
  display: flex;
`;

const Star = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.active ? '#ffffff' : '#4e4e4e'};
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  margin-right: 2px;
`;

const BonusIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const SilverTable = () => {
    return (
        <div style={{margin:'10px 0'}}>
            
            <TableContainer>
                <div style={{padding:'20px'}}>
                    <div style={{ color: '#F5F6F7' }}>Silver Card</div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Level</TableHeader>
                            <TableHeader>XP Amount</TableHeader>
                            <TableHeader>XP Progress Bar</TableHeader>
                            <TableHeader>Star</TableHeader>
                            <TableHeader>Bonus</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <LevelIcon src={row.iconPath} alt={row.level} />
                                    {row.level}
                                </TableCell>
                                <TableCell>{row.xpAmount}</TableCell>
                                <TableCell>
                                    {row.xpProgress}
                                    <ProgressBar />
                                </TableCell>
                                <TableCell>
                                    <StarContainer>
                                        {['/assets/images/starVip.png', '/assets/images/starVip.png', '/assets/images/starVip.png', '/assets/images/starVip.png', '/assets/images/starVip.png'].map((_, i) => (
                                            <LevelIcon key={i} src={row.iconPath} alt={row.level} />
                                        ))}
                                    </StarContainer>
                                </TableCell>
                                <TableCell>
                                    <BonusIcon src={row.bonus} alt={row.bonus} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default SilverTable;