import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Card, Col, Row} from "react-bootstrap";
import storage from "../../../Storage";
import RangeCredit from "../../Components/Game/Addons";
import {gameCoin} from "../../../actions/gameCoin";
import {setPlay} from "../../../actions/gamePlay";
import {setWallet} from "../../../actions/gameWallet";
import {Event, __, forceSatoshiFormat, isValidNumber, sendNotfication, wait, isMobile, playAudio} from "../../../Helper";
import C from "../../../Constant";

class Bet extends React.Component {
    _isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            started: false,
            bet: 'manual',
            isLogged: storage.getKey('logged') !== null ? true : false,
            buttonText: 'BET',
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            inputDisabled: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentDidMount(){
        if(this._isMounted){
            let { engine } = this.state;

            wait(300).then(() => {
                if(isMobile()){
                    this.setState({ padding: 'p-1'})
                }
            });
            
            this.props.gameCoin();
            engine.on(C.PLAY_THREE_CARD_MONTE, (data) => this.play(data));
            engine.on(C.BUSTED_THREE_CARD_MONTE, (data) => this.busted(data));
            engine.on(C.ERROR_THREE_CARD_MONTE, (data) => this.error(data));
            engine.on("stop_playing", () => this.stopManual());
        }
    }
    
    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        let { engine } = this.state;
        engine.init = false;
        this._isMounted = false;
    }
    
    stopManual() {
        if(this._isMounted){
            this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
        }
    }

    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({inputDisabled: false, started: false, buttonText: 'BET'});
        }
    }

    play(data){
        if(this._isMounted){
            playAudio('dice2.mp3')
        }
    }

    busted(data){
        if(this._isMounted){
            this.setState({ inputDisabled: false });
        }
    }

    placeBet(){
        if(this._isMounted)
        {
            let { engine, amount } = this.state;
            wait(500)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin = this.props.coin;
                    engine.start();
                });
        }
    }

    isCorrectBet(amount){
        if(this._isMounted)
        {
            if(__.isUndefined(amount)) return;
            
            if(amount === '')
                return false;

            if(!isValidNumber(amount))
                return false;
            
            if(parseFloat(amount) <= 0){
                this.setState({ amount: forceSatoshiFormat(0.00000010) });
            }
            else {
                amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
                this.setState({ amount: forceSatoshiFormat(amount) });
            }
            return true;
        }        
    }

    handleBet(e){
        if(this._isMounted)
        {
            e.preventDefault();

            let { isLogged, amount } = this.state;

           // Check User
            if(!isLogged){
               return Event.emit('showAuthModal', true);
            }

            // Check is Correct Bet
            if(!this.isCorrectBet(amount))
                return false;

            this.setState({inputDisabled: true});
            this.placeBet();
        }
    }

    handleInputChange(event){
        if(this._isMounted){
            let target = event.target;
            let value  = target.value;
            let name   = target.name;

            if(name === 'amount'){
                this.setState({amount:value})
                if(!isValidNumber(value)) return;
            }
            
            if(name === 'amount'){
                storage.setKey('lam', value);
            }

            this.setState({ [name]: value });
        }
    }
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
    }

    render() {
        let { bet, inputDisabled, started, padding } = this.state;

        return(
            <>
                {this._isMounted &&
                        <>
                            <form className="w-100 mt-1"  onSubmit={(e) => {
                e.preventDefault();
                if(this.props.coin==='INR'){
                  if(this.state.amount < 50){
                    sendNotfication('Enter valid amount')
                   
                  }else{
                    this.handleBet(e);
                  }
                }
                else if (this.props.coin ==='USDT'){
                   if(this.state.amount < 1){
                  sendNotfication('Enter valid amount')

                }else{
                  this.handleBet(e);

                }
              }else{
                this.handleBet(e);

              }
              }}>
                                <Row>
                                    <Col sm={12} className={'d-md-none d-sm-block'}>
                                        {bet === 'manual' &&
                                        <Button variant={'btn btn-xs btn-block btn-bet py-2 mb-2'}
                                                disabled={inputDisabled}
                                                type="submit">
                                            {this.state.buttonText}
                                        </Button>
                                        }
                                    </Col>
                                    <Col xl={6} md={7}>
                                      <div className={"form-group mb-1 bet-input"}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                                        BET
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    disabled={inputDisabled}
                                                    className="form-control"
                                                    id="amount" name="amount"
                                                    value={this.state.amount}
                                                    autoComplete={"off"}
                                                    onKeyUp={this.handleInputChange}
                                                    onChange={this.handleInputChange} />
                                                <div className="input-group-append">
                                                    <RangeCredit set={this.setAmount} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6} md={5}>
                                      <div className={"form-group mb-1 bet-input"}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                                        WIN AMOUNT
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    disabled={true}
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={(forceSatoshiFormat(this.state.amount/2))}
                                                   />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5} sm={12} className="m-auto hidden-sm">
                                        <div className={ "form-group rev mb-1"}>
                                            <Button variant={'btn btn-md btn-block mt-2 btn-bet'}
                                                    disabled={inputDisabled}
                                                    type="submit">
                                                {this.state.buttonText}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
              <span style={{fontSize:11,marginLeft:5}}>
                {this.props.coin ==='INR' ?
              "(Minimum Bet - 50 INR)"
              :
              "(Minimum Bet - 0.0001  USDT)"
              }
              </span>
              </div>
                            </form>
                        </>
                }
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
