import React from 'react';
import storage from "../../../Storage";
import {wait, chkd, isMobile, getSingleRandomInt, Event, __} from "../../../Helper";
import C from "../../../Constant";
import "../../../Static/css/goal.css"

class Game extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            engine: this.props.engine,
            started: false,
            risk: 'medium',
            risks: [ 1.30, 1.60, 1.90, 2.20 ],
            round: 1,
            round1: true,
            round2: true,
            round3: true,
            round4: true
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;

        engine.on(C.PLAY_GOAL, () => this.play());
        engine.on(C.CLICKED_GOAL, (data) => this.clicked(data));
        engine.on(C.BUSTED_GOAL, (data) => this.endGame(data));
        engine.on(C.CASHOUT_GOAL, (data) => this.endGame(data));
        Event.on('goal_risk', (val) => this.changeRisk(val));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeRisk = (val) => {
        if(this._isMounted)
        {
            var data = [ 1.30, 1.60, 1.90, 2.20 ];

            if(val === 'High')
            {
                data = [  1.30, 1.60, 1.90, 2.20 ];
            }
            
            this.setState({ risks: data })
        }
    }

    play = () => {
        if(this._isMounted){
            this.setState({ started: true, round1: false });
        }
    };

    clicked(data){
        if(this._isMounted){
            let { id } = data;
            ++this.state.round;

            if(this.state.round === 2)
                this.setState({ round2: false, round1: true, round3: true, round4: true});
            if(this.state.round === 3)
                this.setState({ round3: false, round1: true, round2: true, round4: true });
            if(this.state.round === 4)
                this.setState({ round4: false, round1: true, round2: true, round3: true});
        }
    }

    endGame(data){
        if(this._isMounted){
            let { mines } = data;
            this.setState({ started: false });
            wait(2200).then(() => {
                // reset game
                this.setState({ round : 1, round1: true, round2: true, round3: true, round4: true  });
                var ball = document.getElementById('ball');
                var ballStart = document.getElementById('ballStart');
                ball.style.top = '50%'
                ball.style.left = 'unset'
                ballStart.appendChild(ball);
            })
        }
    }

    select = (id) => {
        if(this._isMounted){
            let { engine, started } = this.state;
            if(started)
            {
                wait(50).then(() => {
                    engine.init = true;
                    engine.land = id;
                    engine.clicked();
                    this.moveBall(id)
                })
            }
        }
    };

    moveBall = (id) => {
        if(this._isMounted){
            var ball = document.getElementById('ball');
            var col = document.getElementById('c_' + id);
            ball.classList.add('ball-animation');
            col.appendChild(ball)

            wait(500).then(() => {
                ball.classList.remove('ball-animation');
            })

            ball.style.top = '12px'
            ball.style.left = '16px'
        }
    };

    render() {
        const { round1, round2, round3, round4, risks } = this.state;
        return(
            <div className="goal animated fadeIn">
                <div className="grids">
                   <div class="column" id="e_1">
                        {!round1 &&
                            <i className="mdi mdi-arrow-down-bold-box" />
                        }
                        <div class="goal-numbers"><button type="button" id="c_1" class="btn btn-soft-secondary btn-xl btn-block" disabled={round1} onClick={e => this.select(1)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_2" class="btn btn-soft-secondary btn-xl btn-block" disabled={round1} onClick={e => this.select(2)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_3" class="btn btn-soft-secondary btn-xl btn-block" disabled={round1} onClick={e => this.select(3)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_4" class="btn btn-soft-secondary btn-xl btn-block" disabled={round1} onClick={e => this.select(4)}></button></div>
                        <span class="goal-multiplier">{risks[0]}x</span>
                    </div>
                   <div class="column" id="e_2">
                        {!round2 &&
                            <i className="mdi mdi-arrow-down-bold-box" />
                        }
                        <div class="goal-numbers"><button type="button" id="c_5" class="btn btn-soft-secondary btn-xl btn-block" disabled={round2} onClick={e => this.select(5)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_6" class="btn btn-soft-secondary btn-xl btn-block" disabled={round2} onClick={e => this.select(6)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_7" class="btn btn-soft-secondary btn-xl btn-block" disabled={round2} onClick={e => this.select(7)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_8" class="btn btn-soft-secondary btn-xl btn-block" disabled={round2} onClick={e => this.select(8)}></button></div>
                        <span class="goal-multiplier">{risks[1]}x</span>
                    </div>
                   <div class="column" id="e_3">
                        {!round3 &&
                            <i className="mdi mdi-arrow-down-bold-box" />
                        }
                        <div class="goal-numbers"><button type="button" id="c_9" class="btn btn-soft-secondary btn-xl btn-block" disabled={round3} onClick={e => this.select(9)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_10" class="btn btn-soft-secondary btn-xl btn-block" disabled={round3} onClick={e => this.select(10)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_11" class="btn btn-soft-secondary btn-xl btn-block" disabled={round3} onClick={e => this.select(11)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_12" class="btn btn-soft-secondary btn-xl btn-block" disabled={round3} onClick={e => this.select(12)}></button></div>
                        <span class="goal-multiplier">{risks[2]}x</span>
                    </div>
                   <div class="column" id="e_4">
                        {!round4 &&
                            <i className="mdi mdi-arrow-down-bold-box" />
                        }
                        <div class="goal-numbers"><button type="button" id="c_13" class="btn btn-soft-secondary btn-xl btn-block" disabled={round4} onClick={e => this.select(13)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_14" class="btn btn-soft-secondary btn-xl btn-block" disabled={round4} onClick={e => this.select(14)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_15" class="btn btn-soft-secondary btn-xl btn-block" disabled={round4} onClick={e => this.select(15)}></button></div>
                        <div class="goal-numbers"><button type="button" id="c_16" class="btn btn-soft-secondary btn-xl btn-block" disabled={round4} onClick={e => this.select(16)}></button></div>
                        <span class="goal-multiplier">{risks[3]}x</span>
                    </div>
                </div>
                <div id="ballStart">
                    <div id="ball" className="ball-wrapper"><img src="/assets/images/gball.png" /></div>
                </div>
            </div>
        );
    }
}

export default Game;
