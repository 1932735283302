/* eslint-disable */
import jQuery from 'jquery';
import GameObj from "./Object";
import {setActions, getWinner, showAlert} from "./Game";
import {renderCard} from "./Deal";
var $ = jQuery.noConflict();

function Player(engine, credit) {
    var hand = [],
        wager = 0,
        cash = parseFloat(credit),
        bank = 0,
        ele = "",
        score = "";

    this.getElements = function () {
        if (this === GameObj['player']) {
            ele = "#phand";
            score = "#pcard-0 .popover-content";
        } else {
            ele = "#dhand";
            score = "#dcard-0 .popover-content";
        }

        return { ele: ele, score: score };
    };

    this.getHand = function () {
        return hand;
    };

    this.setHand = function (card) {
        hand.push(card);
    };

    this.resetHand = function () {
        hand = [];
    };

    this.getWager = function () {
        return wager;
    };

    this.setWager = function (money) {
        wager += parseFloat(money, 0);
    };

    this.resetWager = function () {
        wager = 0;
    };

    this.checkWager = function () {
        return wager <= cash ? true : false;
    };

    this.getCash = function () {
        return cash.formatMoney(2, ".", ",");
    };

    this.setCash = function (money) {
        cash += money;
        this.updateBoard();
    };

    this.setBank = function (money) {
        bank += money;
        this.updateBoard();
    };

    this.flipCards = function () {
        $(".down").each(function () {
            $(this).removeClass("down").addClass("up");
            renderCard(false, false, false, $(this));
        });

        $("#dcard-0 .popover-content").html(GameObj['dealer'].getScore());
    };

    Player.prototype.hit = function (dbl) {
        var pscore;

        GameObj['deal'].dealCard(1, 0, [this]);
        pscore = GameObj['player'].getScore();

        if (dbl || pscore > 21) {
            GameObj['running'] = false;

            setTimeout(function () {
                GameObj['player'].stand();
            }, 500);
        } else {
            this.getHand();
        }

        setActions();

        GameObj['player'].updateBoard();
    };

    Player.prototype.stand = function () {
        var timeout = 0;

        GameObj['running'] = false;
        GameObj['dealer'].flipCards();

        function checkDScore() {
            if (GameObj['dealer'].getScore() < 17 && GameObj['player'].getScore() <= 21) {
                timeout += 200;

                setTimeout(function () {
                    GameObj['dealer'].hit();
                    checkDScore();
                }, 500);
            } else {
                setTimeout(function () {
                    getWinner();
                }, timeout);
            }
        }

        checkDScore();
    };

    Player.prototype.dbl = function () {
        var wager = this.getWager();

        if (this.checkWager(wager * 2)) {
            $("#double").prop("disabled", true);
            this.setWager(wager);
            this.setCash(-wager);

            this.hit(true);
        } else {
            $("#alert").removeClass("alert-info alert-success").addClass("alert-error");
            showAlert("You don't have enough cash to double down!");
        }
    };

    Player.prototype.split = function () {
        $("#alert").removeClass("alert-info alert-success").addClass("alert-error");
        showAlert("Split function is not yet working.");
    };

    Player.prototype.insure = function () {
        var wager = this.getWager() / 2,
            newWager = 0;

        $("#insurance").prop("disabled", true);
        this.setWager(wager);

        if (this.checkWager()) {
            newWager -= wager;
            this.setCash(newWager);
            GameObj['insured'] = wager;
        } else {
            this.setWager(-wager);
            $("#alert").removeClass("alert-info alert-success").addClass("alert-error");
            showAlert("You don't have enough for insurance!");
        }
    };

    Player.prototype.getScore = function () {
        var hand = this.getHand(),
            score = 0,
            aces = 0,
            i;

        for (i = 0; i < hand.length; i++) {
            score += hand[i].value;

            if (hand[i].value === 11) {
                aces += 1;
            }

            if (score > 21 && aces > 0) {
                score -= 10;
                aces--;
            }
        }

        return score;
    };

    Player.prototype.updateBoard = function () {
        var score = "#dcard-0 .popover-content";

        if (this === player) {
            score = "#pcard-0 .popover-content";
        }

        $(score).html(this.getScore());
    };
}

export default Player;
