import React from 'react';
import styled from 'styled-components';


const data = [
    { level: 'VIP 38', xpAmount: 321000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 39', xpAmount: 377000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 40', xpAmount: 433000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 41', xpAmount: 489000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 42', xpAmount: 545000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 43', xpAmount: 601000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 44', xpAmount: 657000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 45', xpAmount: 713000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 46', xpAmount: 769000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 47', xpAmount: 897000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 48', xpAmount: 1025000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 49', xpAmount: 1153000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 50', xpAmount: 1281000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 51', xpAmount: 1409000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 52', xpAmount: 1537000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 53', xpAmount: 1665000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 54', xpAmount: 1793000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 55', xpAmount: 2081000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 56', xpAmount: 2369000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 57', xpAmount: 2657000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 58', xpAmount: 2945000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 59', xpAmount: 3233000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 60', xpAmount: 3521000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 61', xpAmount: 3809000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 62', xpAmount: 4097000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 63', xpAmount: 4737000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 64', xpAmount: 5377000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 65', xpAmount: 6017000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 66', xpAmount: 6657000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 67', xpAmount: 7297000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 68', xpAmount: 7937000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 69', xpAmount: 8577000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/platinumStar.webp' },

    ]

const Table = styled.table`
  width: 100%;
  color: #ffffff;
  border-collapse: collapse;
`;

const TableContainer = styled.div`
  width: 100%;
  background-color: #1E2024;
  color: #ffffff;
  padding: 12px;
  border-radius: 12px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  font-weight: normal;
  color: #F5F6F7;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #282B30;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  color: #F5F6F7;
`;

const LevelIcon = styled.img`
  width: 14px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
`;

const ProgressBar = styled.div`
  width: 50px;
  height: 4px;
  background-color: #2e2e2e;
  border-radius: 2px;
`;

const StarContainer = styled.div`
  display: flex;
`;

const Star = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.active ? '#ffffff' : '#4e4e4e'};
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  margin-right: 2px;
`;

const BonusIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const PlatinumTable = () => {
    return (
        <div style={{margin:'10px 0'}}>
            
            <TableContainer>
                <div style={{padding:'20px'}}>
                    <div style={{ color: '#F5F6F7' }}>Platinum Card</div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Level</TableHeader>
                            <TableHeader>XP Amount</TableHeader>
                            <TableHeader>XP Progress Bar</TableHeader>
                            <TableHeader>Star</TableHeader>
                            <TableHeader>Bonus</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <LevelIcon src={row.iconPath} alt={row.level} />
                                    {row.level}
                                </TableCell>
                                <TableCell>{row.xpAmount}</TableCell>
                                <TableCell>
                                    {row.xpProgress}
                                    <ProgressBar />
                                </TableCell>
                                <TableCell>
                                    <StarContainer>
                                        {['/assets/images/goldStar.png', '/assets/images/goldStar.png', '/assets/images/goldStar.png', '/assets/images/goldStar.png', '/assets/images/goldStar.png'].map((_, i) => (
                                            <LevelIcon key={i} src={row.iconPath} alt={row.level} />
                                        ))}
                                    </StarContainer>
                                </TableCell>
                                <TableCell>
                                    <BonusIcon src={row.bonus} alt={row.bonus} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PlatinumTable;