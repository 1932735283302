import React from "react";
import {Row, Col, Modal} from "react-bootstrap";
import Swal from 'sweetalert2';
import storage from "../../../Storage";
import {forceSatoshiFormat, __} from "../../../Helper";
import C from "../../../Constant";
import GameModal from "../../Components/Game/Stat/Modal";
import {userUID} from "../../../Auth";

class History extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            show: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleShow(){
        this.setState({ show: true, effect: 'zoomIn' });
    }

    handleClose(){
        this.setState({ show: false, effect: 'zoomOut' });
    }

    render(){
        return(<>
            <button className={'btn btn-sm bg-cs cpt mr-1 animated fadeInDown keno-h-btnz bg-cs2'} onClick={e => this.handleShow(e)}>
                <i className="mdi mdi-power mr-1"/>
                History
            </button>
            <Modal
                size="md"
				backdrop="static"
                centered={true}
                show={this.state.show}
                onHide={this.handleClose}
                aria-labelledby="login-md-modal"
                className={'animated ' + this.state.effect}
            >
                <Modal.Header>
                    Keno History
                    <button type="button" className="close p-2" onClick={this.handleClose}>
                        <i className={'mdi mdi-close'}/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Parent engine={this.props.engine} clicked={this.handleClose} />
                </Modal.Body>
            </Modal>
        </>);
    }
}

class Parent extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            players: [],
            game_id: '',
            numbers: '',
            hash: '',
            md5: ''
        };
    }

    onChange(event, players, hash, md5, game_id, numbers) {
        this.setState({
            md5: md5,
            hash: hash,
            numbers: numbers,
            game_id: game_id,
            players: players,
            color: (numbers >= 1.9 ? 'success' : 'danger')
        });
    }

    render () {
        const { players, hash, md5, game_id, numbers } = this.state;
        return <Child engine={this.props.engine} clicked={this.props.clicked.bind(this)} onChange={this.onChange.bind(this, players, hash, md5, game_id, numbers)} />
    }
}

class Child extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            historyRow: [],
            players: [],
            game_id: '',
            busted: '',
            hash: '',
            color: '',
            md5: ''
        };
    }

    componentDidMount(){
        this._isMounted = true;

        let { engine } = this.state;

        engine.getStatus();
        engine.on(C.STATUS_KENO, (data) => this.gameSync(data));
        engine.on(C.BUSTED_KENO, (data) => this.makeList(data));
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    makeList(data){
        if (this._isMounted) {
            let busted  = data.crash;
            let color = 'white';
            let numbers, won, amount;

            data.players.forEach((data, i) => {
                if(parseFloat(data.uid) === parseFloat(userUID())){
                    numbers = data.crash;
                    amount = forceSatoshiFormat(data.amount);
                    won = '-' + forceSatoshiFormat(data.amount);
                    color = 'danger';
                }
            });

            data.winners.forEach((data, i) => {
                if(parseFloat(data.uid) === parseFloat(userUID())){
                    numbers = data.crash;
                    amount = forceSatoshiFormat(data.amount);
                    won = forceSatoshiFormat(data.amount);
                    color = 'success';
                }
            });

            let row = <Block clicked={this.props.clicked} numbers={numbers} won={won} amount={amount}
                             players={data.players} hash={data.hash} md5={data.md5}
                             game_id={data.game_id} busted={busted} color={color} />;

            this.state.historyRow.unshift(row);

            this.setState({
                game_id: data.game_id,
                players: data.players,
                hash: data.hash,
                md5: data.md5,
                busted: busted,
                color: color
            });

            this.props.onChange(data)
        }
    }

    gameSync(list){
        if (this._isMounted) {
            let data = list.crashes;

            data.forEach((array, i) => {
                let numbers, won, amount;
                let color = 'white';

                array.players.forEach((data, i) => {
                    if(parseFloat(data.uid) === parseFloat(userUID())){
                        numbers = data.crash;
                        amount = forceSatoshiFormat(data.amount);
                        won = '-' + forceSatoshiFormat(data.amount);
                        color = 'danger';
                    }
                    else {
                        numbers = '-';
                        amount = '-';
                        won = '-';
                    }
                });

                array.winners.forEach((data, i) => {
                    if(parseFloat(data.uid) === parseFloat(userUID())){
                        numbers = (data.crash);
                        amount = forceSatoshiFormat(data.amount);
                        won = forceSatoshiFormat(data.amount);
                        color = 'success';
                    }
                    else {
                        numbers = '-';
                        amount = '-';
                        won = '-';
                    }
                });

                let row = <Block clicked={this.props.clicked} numbers={numbers} won={won} amount={amount}
                                 players={array.f} hash={array.hash} md5={array.md5}
                                 game_id={array.game_id} busted={array.crash} color={color} />;

                this.setState(state => ({ historyRow: [row, ...state.historyRow] }));

                this.props.onChange(array)
            });
        }
    }

    render(){
        this.state.historyRow.length = 20;
        return (
            <>
                <Row className="p-2">
                    <Col xs="6">ID</Col>
                    {/*
						<Col xs="2">NUMBERS</Col>
						<Col xs="2">BET</Col>
						<Col xs="2">PROFIT</Col>
					*/}
                    <Col xs="6" className="text-center">SHA256</Col>
                </Row>
                <div className="history-list-keno">
                    {this.state.historyRow}
                </div>
            </>
        );
    }
}

class Block extends React.Component {
    handleModal(md5, hash){
        Swal.fire({
            html: '<label>HASH</label>' +
                '<input type="text" class="form-control bg-soft-dark" value="' + hash + '" readonly>' +
                '<label class="mt-3">MD5</label>' +
                '<input type="text" class="form-control bg-soft-dark" value="' + md5 + '" readonly>',
        })
    }
    render(){

        function createMarkup(numbers) {
            let res = '';
            let dotted = '';

            if(!__.isUndefined(numbers)){
                if(__.isArray(numbers))
                {
                    if(numbers.length > 3) {
                        dotted = '...';
                        numbers.length = 3;
                    }
                    numbers.forEach((number, i) => {
                        res += "<div class=\"avatar-box thumb-xs align-self-center\">\n" +
                            "<span class=\"avatar-title bg-soft-info rounded-circle text\">"+number+"</span>\n" +
                            "</div>";
                    });
                }
            }
            return {__html: res + dotted};
        }

        let { game_id, color, amount, won, numbers, hash, md5 } = this.props;

        return(
            <Row className="mt-1 pt-0">
                <Col xs="6" className="mt-2">
                    <GameModal clicked={this.props.clicked} game_id={game_id} title={game_id} color={color} />
                </Col>
                {/*
					<Col xs="2" className="pt-1" dangerouslySetInnerHTML={createMarkup(numbers)} />
					<Col xs="2" className="mt-2">{amount ? amount: '-'}</Col>
					<Col xs="2" className="mt-2">{won ? won: '-'} {coin ? coin: '-'}</Col>
				*/}
                <Col xs="6" className="mt-1 cpt" onClick={() => { this.handleModal(md5, hash) }}>
                    <input
                        type="text"
                        className={ "form-control font-12 h-100 no-radius cpt bg-soft-" + color }
                        value={hash.substr(0,80) + '...'}
                        readOnly={true}
                    />
                </Col>
            </Row>
        );
    }
}

export default History;
