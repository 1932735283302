import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronRight, ChevronLeft } from 'lucide-react';

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  // max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
`;

const CarouselTrack = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${(props) => props.translate}%);
`;

const CarouselItem = styled.div`
  flex: 0 0 25%;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 0 0 50%;
  }

  @media (max-width: 480px) {
    flex: 0 0 100%;
  }
`;

const ImageCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    object-fit: cover;
    border-radius: 10px;
  }
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }

  /* Hide the button on mobile screens */
  @media (max-width: 768px) {
    display: none;
  }
`;

const PrevButton = styled(NavButton)`
  left: 10px;
`;

const NextButton = styled(NavButton)`
  right: 10px;
`;


// Add your image URLs here with height and width
const carouselItems = [
  { url: '/assets/images/crousel/4.png', width: '100%', height: '90%' },
  { url: '/assets/images/crousel/2.png', width: '100%', height: '90%' },
  { url: '/assets/images/crousel/1.png', width: '100%', height: '90%' },
  { url: '/assets/images/crousel/3.webp', width: '100%', height: '90%' },
  { url: '/assets/images/crousel/4.png', width: '100%', height: '90%' },
  { url: '/assets/images/crousel/2.png', width: '100%', height: '90%' },
  { url: '/assets/images/crousel/1.png', width: '100%', height: '90%' },
  { url: '/assets/images/crousel/3.webp', width: '100%', height: '90%' },
];

const CustomSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4); // default to 4 items per page

  // Update itemsPerPage based on window width
  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth <= 480) {
        setItemsPerPage(1); // 1 item per page on small screens
      } else if (window.innerWidth <= 768) {
        setItemsPerPage(2); // 2 items per page on medium screens
      } else {
        setItemsPerPage(4); // 4 items per page on large screens
      }
    };

    updateItemsPerPage(); // call the function to set the initial value

    window.addEventListener('resize', updateItemsPerPage);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? carouselItems.length - itemsPerPage : prev - itemsPerPage));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev >= carouselItems.length - itemsPerPage ? 0 : prev + itemsPerPage));
  };

  // Autoplay functionality
  useEffect(() => {
    const autoplay = setInterval(handleNext, 5000); // Change slide every 5 seconds

    return () => {
      clearInterval(autoplay); // Clear interval on component unmount
    };
  }, [handleNext]);

  return (
    <CarouselContainer>
      <CarouselTrack translate={-currentIndex * (100 / itemsPerPage)}>
        {carouselItems.map((item, index) => (
          <CarouselItem key={index}>
            <ImageCard width={item.width} height={item.height}>
              <img src={item.url} alt={`carousel item ${index}`} />
            </ImageCard>
          </CarouselItem>
        ))}
      </CarouselTrack>
      <PrevButton onClick={handlePrev}>
        <ChevronLeft />
      </PrevButton>
      <NextButton onClick={handleNext}>
        <ChevronRight />
      </NextButton>
    </CarouselContainer>
  );
};

export default CustomSlider;
