import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Card, Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Engine from "./Engine";
import Loading from "../Loading";
import { isMobile, wait, Event } from "../../../Helper";
import MagicWheelPopup from './Graph';

class Index extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            margin: 'mt-3',
            padding: "p-1",
            showPopup: false
        };
        this.handleShowPopUp = this.handleShowPopUp.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    handleShowPopUp() {
        this.setState((prevState) => ({
            showPopup: !prevState.showPopup
        }));
    }
    componentDidMount() {
        this._Mounted = true;
        let { engine } = this.state;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);

        wait(300).then(() => {
            this._Mounted = false;
        });

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (clientWidth < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }

        if (isMobile()) {
            this.setState({ padding: 'p-0', margin: 'mt-1', ovh: 'ovh' });
        }
    }
    render() {
        let { col, padding, ovh } = this.state;

        const help = "Spin The Wheel, other than red pieces '24x', If the arrow stops on the number or in the  'next' piece one, the next wheel spins and your score can calculate.";

        return (
            <>
                <Helmet>
                    <title>Magic Wheel - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Magic Wheel Game, Online Magic Wheel Game, Bitcoin Magic Wheel Game, Blockchain Magic Wheel Game, Best Crypto Magic Wheel Game, PlayCrash.com Magic Wheel" />
                    <meta name="description" content={help} />
                    <meta name="og:title" content="Play Online Magic Wheel - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Magic Wheel - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/magic-wheel" />
                </Helmet>
                <div>
                    {this._Mounted ?
                        <div className={`${col} ${padding}`} style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {this.state.showPopup && (
                                <MagicWheelPopup
                                    onClose={this.handleShowPopUp}
                                    engine={this.state.engine}
                                />
                            )}
                            <Row className={"animated fadeIn " + ovh}>
                                <div className="d-none">Crypto Magic Wheel Game Description: {help}</div>
                                <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                                    {/* BANKROLL */}
                                    <Card className="mb-0">
                                        <Card.Body className="px-2 py-3">
                                            <Row>
                                                <Col md={6} className={'col-6 text-left'}>
                                                    <BankRoll game={'magic_wheel'} />
                                                </Col>
                                                <div style={{ display: 'flex' }}>

                                                    <Col md={6} className={'col-6 text-right'}>
                                                        <TopBarGames help={help} image="/assets/images/covers_new/____hilo.png" />
                                                    </Col>
                                                    <div onClick={this.handleShowPopUp} style={{ marginLeft: '26px', alignItems: 'center', height: '37px', width: '37px', borderRadius: '5px', backgroundColor: "#1B2336", display: 'flex', justifyContent: 'center' }}>
                                                        <img style={{ height: '17px', width: '17px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />

                                                    </div>
                                                </div>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    {/* GAME */}
                                    <Card className="mb-0">
                                        <Card.Body className={'bg-dice-options hash_diceBG p-1'} id="roll-panel">
                                            <Row>
                                                <Col sm={12} md={12} className={'m-auto'}>
                                                    <Game engine={this.state.engine} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    {/* BET */}
                                    <Card className="mb-0">
                                        <Card.Body className={padding}>
                                            <Row>
                                                <Col sm={12} md={10} className={'m-auto'}>
                                                    <Bet engine={this.state.engine} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    {/* QUEUE */}
                                    <Card className="mb-5">
                                        <Card.Body className="p-1">
                                            <Row>
                                                <Col sm={12} md={12} className={'m-auto px-1'}>
                                                    <Queue t={this.props.t} game={'magic_wheel'} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                </Col>
                            </Row>
                        </div>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Index;
