// import React from 'react';
// import { Modal, Dropdown } from 'react-bootstrap';
// import styled from 'styled-components';
// import { Search, ChevronRight, Wallet, ArrowDownUp, Vault, WalletCards } from 'lucide-react';
// import PerfectScrollbar from 'perfect-scrollbar';
// import 'perfect-scrollbar/css/perfect-scrollbar.css';
// import { __, wait, decode, encode, forceSatoshiFormat, Event } from "../../../Helper";
// import socket from "../../../Socket";
// import storage from "../../../Storage";
// import C from "../../../Constant";
// import coins from "../../coins";

// const SearchContainer = styled.div`
//   display: flex;
//   align-items: center;
//   background-color: #f5f5f5;
//   border-radius: 8px;
//   padding: 8px 12px;
//   margin-bottom: 16px;
// `;

// const SearchInput = styled.input`
//   border: none;
//   background: transparent;
//   margin-left: 8px;
//   flex-grow: 1;
//   &:focus {
//     outline: none;
//   }
// `;

// const ManageListButton = styled.button`
//   background: none;
//   border: none;
//   color: #007bff;
//   cursor: pointer;
//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const Divider = styled.hr`
//   margin: 16px 0;
//   border-top: 1px solid #e0e0e0;
// `;

// const ResponsiveModal = styled(Modal)`
//   .modal-dialog {
//   height:80vh !imporatnt;
//     @media (min-width: 576px) {
//       max-width: 90%;
//       width: 400px;
//     }
//     @media (min-width: 768px) {
//       max-width: 85%;
//       width: 500px;
//     }
//     @media (min-width: 992px) {
//       max-width: 80%;
//       width: 600px;
//     }
//     @media (min-width: 1200px) {
//       max-width: 75%;
//       width: 500px;
//     }
//   }
// `;
// const ModalHeader = styled(Modal.Header)`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1rem;
//   border-bottom: 1px solid #e9ecef;
// `;


// class CoinSelection extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       show: false,
//       showManage: false,
//       isTransferIn: true,
//       token: storage.getKey('token'),
//       coin: storage.getKey('coin') ? storage.getKey('coin') : "INR",
//       activeClientCoins: storage.getKey('active_coins') ? storage.getKey('active_coins') : null,
//       selectedCoin: storage.getKey('coin'),
//       credits: {},
//       coins: [],
//       manage_coins: [],
//       different: [],
//       credit: false,
//       usd: storage.getKey('usd') === "true" ? true : false,
//       searchTerm: "",
//       showWalletModal: false,
//       showCurrencyModal: false,
//       activeSection: 'deposit'
//     };
//     this.selectCoin = this.selectCoin.bind(this);
//     this.wrapperRef = React.createRef();
//     this.handleClickOutside = this.handleClickOutside.bind(this);
//     this.sections = [
//       { id: 'deposit', label: 'Deposit', icon: Wallet },
//       { id: 'withdraw', label: 'Withdraw', icon: WalletCards },
//       { id: 'swap', label: 'Swap', icon: ArrowDownUp },
//       { id: 'vaultpro', label: 'Vault Pro', icon: Vault },
//     ];
//   }

//   componentDidMount() {
//     if (this.state.token !== null)
//       socket.emit(C.CREDIT, encode({ token: this.state.token, coin: this.state.coin }));

//     socket.on(C.CREDIT, data => this.getCredit(decode(data)));
//     socket.on(C.UPDATE_CREDIT, data => this.updateCredit(decode(data)));

//     if (this.state.activeClientCoins === null) {
//       var arr = [];
//       for (var i = 1; i < 8; i++) {
//         arr.push(i)
//       }
//       storage.setKey('active_coins', arr);
//     }

//     this.setUpManageCoins();

//     window.addEventListener('mousedown', this.handleClickOutside);
//   }

//   componentWillUnmount() {
//     window.removeEventListener('mousedown', this.handleClickOutside);
//   }

//   handleClickOutside(event) {
//     if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
//       this.setState({ show: false });
//     }
//   }

//   getCredit(data) {
//     let { credit } = data;

//     this.setState({ credits: credit });

//     let currentCoin = __.lowerCase(this.props.coin);
//     let currentCredit = forceSatoshiFormat(this.state.credits[currentCoin]);
//     this.setState({ credit: currentCredit });
//     storage.setKey('credit', currentCredit);

//     this.props.setCredit(currentCredit);
//     this.updateAllCoins();
//   }

//   updateCredit(data) {
//     let credit = data.value;
//     let coin = data.coin;

//     let different;
//     let arr;

//     if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
//       different = __.toNumber(credit) - __.toNumber(this.state.credit);
//       arr = {
//         amount: different,
//         color: 'text-success'
//       }
//     } else {
//       different = __.toNumber(this.state.credit) - __.toNumber(credit);
//       arr = {
//         amount: different,
//         color: 'text-danger'
//       }
//     }

//     let check = forceSatoshiFormat(different);

//     if (check.toString() !== '0.00000000') {
//       this.setState(state => ({ different: [arr, ...state.different] }));
//     }

//     var realCredit = credit
//     credit = forceSatoshiFormat(credit);

//     this.setState({ credit: credit });
//     storage.setKey('credit', credit);

//     this.updateParentCoin(__.lowerCase(coin), credit);
//     this.updateAllCoins(__.lowerCase(coin), realCredit);

//     this.props.setCredit(credit);
//   }

//   updateAllCoins(newCoin = null, newVal = null) {
//     const activeClientCoins = storage.getKey('active_coins');
//     this.setState({ coins: [] });

//     let ac = null, style, value, coin;

//     if (activeClientCoins !== null) {
//       ac = activeClientCoins.split(",");

//       __.reverse(ac).forEach((num, key) => {
//         var item = __.find(coins, { 'id': parseFloat(num) });
//         if (__.isUndefined(item)) return;
//         style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc height-highlight' : '';

//         if (newVal !== null)
//           value = newVal;
//         else
//           value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);

//         if (__.lowerCase(item.preffix) === newCoin) {
//           this.state.credits[newCoin] = value
//         }

//         if (newCoin !== null) {
//           coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix, newCoin, newVal)} key={key} style={{margin:'30px 0'}} className={"num-style " + style}>
//             <div  style={{color:'white',margin:'14px 0'}}  className="float-left">
//               <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
//               {item.preffix}
//             </div>
//             {__.lowerCase(item.preffix) === newCoin ?
//               <div  style={{color:'white',margin:'14px 0'}}  className="float-right">{value}</div>
//               :
//               <div  style={{color:'white',margin:'14px 0'}}  className="float-right">{forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)])}</div>
//             }
//           </Dropdown.Item>;
//         } else {
//           coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} style={{margin:'30px 0'}} className={"num-style " + style}>
//             <div style={{color:'white',margin:'14px 0'}} className="float-left">
//               <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
//               {item.preffix}
//             </div>
//             <div  style={{color:'white',margin:'14px 0'}}  className="float-right">{value}</div>
//           </Dropdown.Item>;
//         }

//         this.setState(state => ({ coins: [coin, ...state.coins] }));
//       });
//     } else {
//       coins.forEach((item, key) => {
//         style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
//         value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
//         coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} style={{margin:'30px 0'}} className={"num-style " + style}>
//           <div  style={{color:'white',margin:'14px 0'}}  className="float-left">
//             <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
//             {item.preffix}
//           </div>
//           <div  style={{color:'white',margin:'14px 0'}}  className="float-right">{value}</div>
//         </Dropdown.Item>;
//         this.setState(state => ({ coins: [coin, ...state.coins] }));
//       });
//     }
//   }

//   selectCoin(name, newCoin, newVal) {
//     if (storage.getKey('coin') === name) return;
//     storage.setKey('coin', name);

//     let credit = this.state.credits[__.lowerCase(name)];
//     this.setState({ coin: name, credit: credit, selectedCoin: name });

//     this.props.setCoin(name);
//     this.props.setCredit(credit);

//     wait(200).then(() => {
//       this.updateAllCoins(newCoin, newVal);
//       Event.emit('coin_changed', name);
//       if (this.props.onClose) {
//         this.props.onClose();
//       }
//     })
//   }

//   setUpManageCoins = () => {
//     const activeCoins = storage.getKey('active_coins');
//     let ac = __.split(activeCoins, ",");

//     coins.forEach((item, key) => {
//       let active = ac.some(element => parseFloat(element) === parseFloat(item.id));
//       let disabled = item.preffix === 'BTC';

//       let add = <Dropdown.Item key={key} className={"num-style"}>
//         <div className="float-left text-white ncpt">
//           <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-12'} />
//           {item.preffix}
//         </div>
//         <div className="float-right">
//           <div className="custom-control custom-switch switch-success">
//             <input type="checkbox" className="custom-control-input" id={"switch_" + key} checked={active} disabled={disabled} />
//             <label onClick={(e) => this.activeCoinChange(parseFloat(item.id), active)}
//               className="custom-control-label" htmlFor={"switch_" + key}>{item.name}</label>
//           </div>
//         </div>
//       </Dropdown.Item>;
//       this.setState(state => ({ manage_coins: [add, ...state.manage_coins] }));
//     });
//   }

//   activeCoinChange = (id, active) => {
//     if (id === 1) return;
//     const activeClientCoins = storage.getKey('active_coins')
//     let update;

//     if (!active) {
//       update = activeClientCoins + ',' + id;
//     } else {
//       update = __.replace(activeClientCoins, "," + id, "");
//     }

//     update = __.replace(update, null, "");

//     const compareNumbers = (a, b) => a - b;

//     let arr = [];

//     const regex = /(\d+)/gi;
//     let m;

//     while ((m = regex.exec(update)) !== null) {
//       if (m.index === regex.lastIndex) {
//         regex.lastIndex++;
//       }
//       m.forEach((match, groupIndex) => {
//         match = parseFloat(match);
//         arr.push(match);
//       });
//     }

//     arr.sort(compareNumbers);
//     var c = __.xor(arr);

//     storage.setKey('active_coins', c);

//     this.setUpManageCoins();
//   }

//   handleSearch = (e) => {
//     this.setState({ searchTerm: e.target.value });
//   }

//   usdformat = () => {
//     this.setState({ usd: !this.state.usd });
//     wait(1000).then(() => {
//       storage.setKey('usd', this.state.usd)
//     })
//   }

//   filteredCoins = () => {
//     const { searchTerm, coins } = this.state;
//     return coins.filter(coin => {
//       const coinText = coin.props.children.map(child => {
//         if (typeof child === 'string') {
//           return child.toLowerCase();
//         }
//         return "";
//       }).join(" ");
//       return coinText.includes(searchTerm.toLowerCase());
//     });
//   }

//   render() {
//     const { credit, different, coin, coins, show, searchTerm, usd } = this.state;
//     const filteredCoins = this.filteredCoins();

//     return (
//       <div ref={this.wrapperRef}>
//         <SearchContainer>
//           <Search color="#636A73" size={16} />
//           <SearchInput
//             type="text"
//             placeholder="Search coins..."
//             value={searchTerm}
//             onChange={this.handleSearch}
//           />
//         </SearchContainer>
//         <div style={{ height: '500px', overflowY: 'auto' }}>
//           {filteredCoins}
//         </div>
//         <Divider />
//         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//           <ManageListButton onClick={this.setUpManageCoins}>
//             Manage List
//           </ManageListButton>
//           <div className="custom-control custom-switch switch-info">
//             <input
//               type="checkbox"
//               className="custom-control-input"
//               id="usdSwitch"
//               checked={usd}
//               onChange={this.usdformat}
//             />
//             <label className="custom-control-label" htmlFor="usdSwitch">
//               View in USD
//             </label>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// const CoinSelectionModal = ({ isOpen, setCoin, setCredit, coin, onClose }) => {
//     return (
//       <>
//         <ResponsiveModal show={isOpen} onHide={onClose}>
//         <ModalHeader>
//             <div>Coin</div>
//             <div onClick={onClose}>close</div>
//         </ModalHeader>
//           <Modal.Body>
//             <CoinSelection
//               setCoin={setCoin}
//               setCredit={setCredit}
//               coin={coin}
//               onClose={onClose}
            
//             />
//           </Modal.Body>
//         </ResponsiveModal>
//       </>
//     );
//   };
  


// export default CoinSelectionModal;



import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Search } from 'lucide-react';
import { __, wait, decode, encode, forceSatoshiFormat } from "../../../Helper";
import socket from "../../../Socket";
import storage from "../../../Storage";
import C from "../../../Constant";
import coins from "../../coins";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d131d;
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const ModalDialog = styled.div`
  background: #1E2128;
  border-radius: 12px;
  width: 1000px;
  max-width: 100%;
height: 85%;
  display: flex;
  flex-direction: column;

  // @media (min-width: 576px) {
  //   width: 600px;
  // }
  // @media (min-width: 768px) {
  //   width: 700px;
  // }
  // @media (min-width: 992px) {
  //   width: 800px;
  // }
  // @media (min-width: 1200px) {
  //   width: 1200px;
  // }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #2A2E39;
  color: white;
`;

const ModalBody = styled.div`
  padding: 1rem;
  overflow-y: auto;
  max-height: calc(70vh - 80px);
  scrollbar-width: thin;
  scrollbar-color: #2A2E39 #1E2128;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1E2128;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2A2E39;
    border-radius: 4px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #2A2E39;
  border-radius: 8px;
  padding: 8px 12px;
  margin-bottom: 16px;
`;

const SearchInput = styled.input`
  border: none;
  background: transparent;
  margin-left: 8px;
  flex-grow: 1;
  color: white;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #636A73;
  }
`;

const CoinList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CoinItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: ${props => props.active ? '#2A2E39' : 'transparent'};
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #2A2E39;
  }
`;

const CoinInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
`;

const CoinImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const CoinBalance = styled.div`
  color: white;
  font-family: monospace;
`;

const CoinSelectionModal = ({ isOpen, onClose, setCoin, setCredit, coin: initialCoin }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [credits, setCredits] = useState({});
  const [selectedCoin, setSelectedCoin] = useState(storage.getKey('coin') || "INR");
  const [activeCoins, setActiveCoins] = useState(
    storage.getKey('active_coins')?.split(',').map(String) || ['1']
  );
  const modalRef = useRef();

  useEffect(() => {
    const token = storage.getKey('token');
    if (token) {
      socket.emit(C.CREDIT, encode({ token, coin: selectedCoin }));
    }

    const handleCreditData = (data) => handleCredit(decode(data));
    const handleCreditUpdate = (data) => handleCreditUpdate(decode(data));

    socket.on(C.CREDIT, handleCreditData);
    socket.on(C.UPDATE_CREDIT, handleCreditUpdate);

    return () => {
      socket.off(C.CREDIT, handleCreditData);
      socket.off(C.UPDATE_CREDIT, handleCreditUpdate);
    };
  }, []);

  useEffect(() => {
    if (!storage.getKey('active_coins')) {
      const defaultActiveCoins = ['1', '2', '3', '4', '5', '6', '7'];
      storage.setKey('active_coins', defaultActiveCoins.join(','));
      setActiveCoins(defaultActiveCoins);
    }
  }, []);

  const handleCredit = (data) => {
    const { credit } = data;
    setCredits(credit);
    const currentCoin = __.lowerCase(initialCoin);
    const currentCredit = forceSatoshiFormat(credit[currentCoin]);
    storage.setKey('credit', currentCredit);
    setCredit(currentCredit);
  };

  const handleCreditUpdate = (data) => {
    const { value, coin: updatedCoin } = data;
    const formattedValue = forceSatoshiFormat(value);
    
    setCredits(prev => ({
      ...prev,
      [__.lowerCase(updatedCoin)]: value
    }));

    if (__.lowerCase(updatedCoin) === __.lowerCase(selectedCoin)) {
      setCredit(formattedValue);
      storage.setKey('credit', formattedValue);
    }
  };

  const handleCoinSelect = async (coinName) => {
    if (storage.getKey('coin') === coinName) return;
    
    storage.setKey('coin', coinName);
    const credit = forceSatoshiFormat(credits[__.lowerCase(coinName)] || 0);
    
    setSelectedCoin(coinName);
    setCoin(coinName);
    setCredit(credit);

    try {
      await wait(200);
      Event.emit('coin_changed', coinName);
      onClose();
    } catch (error) {
      console.error('Error during coin selection:', error);
    }
    onClose();
  };

  const filteredCoins = coins
    .filter(coin => {
      if (!activeCoins.includes(coin.id.toString())) return false;
      return coin.preffix.toLowerCase().includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      if (a.preffix === 'BTC') return -1;
      if (b.preffix === 'BTC') return 1;
      return 0;
    });

  return (
    <ModalOverlay show={isOpen} onClick={onClose}>
      <ModalDialog ref={modalRef} onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <div>Select Coin</div>
          <div onClick={onClose} style={{ cursor: 'pointer' }}>×</div>
        </ModalHeader>
        
        <ModalBody>
          <SearchContainer>
            <Search color="#636A73" size={16} />
            <SearchInput
              type="text"
              placeholder="Search coins..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchContainer>

          <CoinList>
            {filteredCoins.map((coin) => (
              <CoinItem
                key={coin.id}
                active={selectedCoin === coin.preffix}
                onClick={() => handleCoinSelect(coin.preffix)}
              >
                <CoinInfo>
                  <CoinImage src={`/assets/images/${coin.image}`} alt={coin.preffix} />
                  {coin.preffix}
                </CoinInfo>
                <CoinBalance>
                  {forceSatoshiFormat(credits[__.lowerCase(coin.preffix)] || 0)}
                </CoinBalance>
              </CoinItem>
            ))}
          </CoinList>
        </ModalBody>
      </ModalDialog>
    </ModalOverlay>
  );
};

export default CoinSelectionModal;