import React from 'react';
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import { Event, wait, isValidNumber, forceSatoshiFormat, sendNotfication } from "../../../Helper";
import C from "../../../Constant";

class Bet extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            bet: 'manual',
            buttonText: 'BET',
            started: false,
            isLogged: storage.getKey("logged") !== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            disabled: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        engine.init = false;
        this._isMounted = false;
    }

    componentDidMount() {
        let { engine } = this.state;
        this._isMounted = true;
        this.props.gameCoin();
        engine.on(C.BUSTED_MAGIC_WHEEL, () => this.busted());
        engine.on(C.ERROR_MAGIC_WHEEL, (data) => this.error(data));
        engine.on("stop_playing", () => this.stopManual());
    }

    stopManual() {
        this.setState({ started: false, buttonText: 'BET', disabled: false });
    }

    error(data) {
        if (this._isMounted) {
            sendNotfication(data.message, 'info', 'top-center');

            if (data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ disabled: false });
        }
    }

    busted = () => {
        this.setState({ disabled: false })
    }

    setBet(e) {
        if (this._isMounted) {
            e.preventDefault();
            let { isLogged, amount } = this.state;

            // Check User
            if (!isLogged) {
                return Event.emit('showAuthModal', true);
            }

            this.setState({ disabled: true });

            if (parseFloat(amount) <= 0) {
                this.setState({ amount: forceSatoshiFormat(0.00000010) });
            }
            else {
                amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
                this.setState({ amount: forceSatoshiFormat(amount) });
            }

            this.placeBet();
        }
    }

    placeBet() {
        if (this._isMounted) {
            this.setState({ disabled: true });

            const { engine, amount } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin = this.props.coin;
                    engine.start();
                })
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        if (target.name === 'amount') {
            this.setState({amount:value})
            if (!isValidNumber(value)) return;
        }
        if (target.name === 'amount') {
            storage.setKey('lam', value);
        }
        this.setState({ [target.name]: value });
    };

    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am);
    }

    render() {
        let { disabled, amount } = this.state;
        return (
            <>
                <Form onSubmit={(e) => this.setBet(e)}>
                    <Row className={'mt-1'}>
                        <Col xl={6} md={6} sm={12}>
                            <div className="form-group mb-1">
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                            BET
                                        </span>
                                    </div>
                                    <input
                                        disabled={disabled}
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter Bet Amount"
                                        value={amount}
                                        autoComplete={"off"}
                                        onKeyUp={this.handleInputChange}
                                        onChange={this.handleInputChange}
                                        className="form-control text-left"
                                        required={true}
                                    />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={5}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                            WIN AMOUNT
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                        value={(forceSatoshiFormat(3 ? (this.state.amount * 3 - (this.state.amount / 2)) / 4 : 0.00000004))}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} sm={12} className="m-auto">
                            <div className={"form-group rev mt-2 mb-0"}>
                                <Button variant={'btn btn-block btn-bet btn-xl mb-2'}
                                    id={'bet'}
                                    disabled={disabled}
                                    type="submit">
                                    {this.state.buttonText}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
