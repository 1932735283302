import React from 'react';
import styled from 'styled-components';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';
import Draggable from 'react-draggable';
import C from '../../../Constant';
import { wait, isMobile, Event, encode, getUID, decode } from "../../../Helper";
import storage from "../../../Storage";
import socket from '../../../Socket';

const PopupWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 5px 10px;
  width: 270px;
  color: white;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: move;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #49BC0D;
`;

const InnerContainer = styled.div`
  background-color: #1E2024;
  border-radius: 12px;
  padding: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #777;
  font-size: 18px;
  cursor: pointer;
`;

const BetInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 12px;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 14px;
  color: #777;
  margin-bottom: 4px;
`;

const Value = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

const GraphContainer = styled.div`
  height: 150px;
  margin-bottom: 16px;
`;

const WinLoseInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  margin-top: 4px;
`;

class DiamondPopup extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      profit: 0.00000000,
      engine: this.props.engine || null,
      wins: 0,
      losses: 0,
      data: [{ value: 0 }],
      userData: null,
      wagered: 0.00000000
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { engine } = this.state;
    
    if (engine) {
      engine.on(C.PLAY_DIAMOND, this.handlePlay);
      engine.on(C.BUSTED_DIAMOND, this.handleBusted);
      engine.on(C.ERROR_DIAMOND, this.handleError);
    }
    socket.on(C.USER_INFO, this.handleUserInfo);
    this.requestUserInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { engine } = this.state;
    
    if (engine) {
      engine.off(C.PLAY_DIAMOND, this.handlePlay);
      engine.off(C.BUSTED_DIAMOND, this.handleBusted);
      engine.off(C.ERROR_DIAMOND, this.handleError);
    }
    socket.off(C.USER_INFO, this.handleUserInfo);
  }

  requestUserInfo = () => {
    socket.emit(C.USER_INFO, encode({
      id: getUID,
      coin: storage.getKey('coin') || '',
      rate: null,
      game: 'all',
      first: true
    }));
  };

  handleUserInfo = (data) => {
    const decodedData = decode(data);
    if (decodedData.status) {
      this.setState({ userData: decodedData });
    }
  };

  handlePlay = (data) => {
    if (this._isMounted && data) {
      console.log("Play data received:", data);
      this.setState(prevState => ({
        wagered: prevState.wagered + parseFloat(data.amount || 0)
      }));
    }
  }

  handleBusted = (data) => {
    if (this._isMounted && data) {
      const newProfit = parseFloat(data.profit || 0);
      
      this.setState(prevState => {
        const currentProfit = parseFloat(prevState.profit);
        const totalProfit = (currentProfit + newProfit).toFixed(8);
        const wins = newProfit > 0 ? prevState.wins + 1 : prevState.wins;
        const losses = newProfit <= 0 ? prevState.losses + 1 : prevState.losses;
        
        const newDataPoint = { value: parseFloat(totalProfit) };
        const updatedData = [...prevState.data, newDataPoint].slice(-20);
        
        this.requestUserInfo();
        return {
          profit: totalProfit,
          wins,
          losses,
          data: updatedData
        };
      });
    }
  }

  handleError = (error) => {
    console.error("Game error:", error);
  }

  handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClose();
  };

  render() {
    const { profit, wins, losses, data, wagered,userData } = this.state;
    const profitNum = parseFloat(profit) || 0;
    const isWinning = profitNum > 0;
    const graphColor = isWinning ? '#67B699' : '#d9534f';

    return (
      <Draggable bounds="parent">
        <PopupWrapper>
          <PopupContainer>
            <Header>
              <Title>Live Stats</Title>
              <CloseButton onClick={this.handleClose}>×</CloseButton>
            </Header>

            <InnerContainer>
              <BetInfo>
                <InfoColumn>
                  <Label>Wagered</Label>
                  <Value>{userData?.profit || '0.00000000'}</Value>
                </InfoColumn>
                <InfoColumn>
                  <Label>Profit</Label>
                  <Value style={{ color: graphColor }}>
                    {profitNum > 0 ? '+' : ''}{profitNum.toFixed(8)}
                  </Value>
                </InfoColumn>
              </BetInfo>
              
              <GraphContainer>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={data}>
                    <Area
                      type="linear"
                      dataKey="value"
                      stroke={graphColor}
                      fill={graphColor}
                      fillOpacity={0.6}
                      isAnimationActive={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </GraphContainer>
            </InnerContainer>
            
            <WinLoseInfo>
              <InfoColumn>
                <Label>Win</Label>
                <Value style={{ color: isWinning ? '#67B699' : 'inherit' }}>{wins}</Value>
              </InfoColumn>
              <InfoColumn>
                <Label>Lose</Label>
                <Value style={{ color: !isWinning ? '#d9534f' : 'inherit' }}>{losses}</Value>
              </InfoColumn>
            </WinLoseInfo>
          </PopupContainer>
        </PopupWrapper>
      </Draggable>
    );
  }
}

export default DiamondPopup;