import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Scroll from "react-scroll";
import Queue from "../Components/Game/Queue";
import { __, isMobile, wait, Event, requestFullScreen } from "../../Helper";
import Frame from "./Frame";
import Loading from "../Games/Loading";
import slots from './Providers/bulk.json';

const Element = Scroll.Element;
const SC = Scroll.scroller;

class Game extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            game: null,
            gameName: null,
            games: [],
            padding: 'p-1',
            provider: null
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        wait(600).then(() => {
            this.handleResize();
        });

        window.addEventListener('resize', this.handleResize);

        let game = this.props.history.location.pathname.replace('/slots/', '');
        this.setState({ game: game });

        SC.scrollTo('slotElement', {
            delay: 3,
            smooth: true,
            containerId: 'st'
        });

        slots.forEach((item, c) => {
            if (item.gameid === game) {
                this.setState({ gameName: item.gamename, provider: item.gameprovider })
            }
        })
    }

    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentWillUnmount() {
        this._Mounted = false;
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (clientWidth < 1540) {
            this.setState({ col: 'col-xl-12' });
        }
        else {
            this.setState({ col: 'col-xl-10' });
        }
        if (isMobile()) {
            this.setState({ padding: 'p-1' });
        }
    }

    fullscreen = () => {
        var elem = document.querySelector(".fullScreen");
        if (elem !== null)
            requestFullScreen(elem);
    }

    render() {
        let { col, padding, game } = this.state;

        var title = this.state.gameName + ' - Crypto Casino Games';
        title = __.startCase(title);
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, PlayCrash.com Slot Games" />
                    <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href={"/" + game} />
                </Helmet>
                <div>
                    {this._Mounted ?
                        <Row>
                            <Col sm={12} className={'m-auto ' + col + ' ' + padding}>
                                <Card className="mb-0">
                                    <Card.Body className="p-1">
                                        <Row>
                                            <Col md={6} className={'col-6 text-left'}>
                                                <p className="mt-2 mb-0 text-white text-upper d-flex">
                                                    <span className="badge bg-soft-secondary p-2 font-12">{this.state.gameName}</span>
                                                    <span className="badge bg-soft-success p-2 font-12 ml-2">{this.state.provider}</span>
                                                    <span className="badge bg-soft-danger p-2 font-12 ml-2">House Edge: 1%</span>
                                                </p>
                                            </Col>
                                            <Col md={6} className={'col-6 text-right'}>
                                                <button className="btn bg-cs2 btn-lg py-0 mt-1 px-2" onClick={this.fullscreen}>
                                                    <i className="mdi mdi-fullscreen" />
                                                </button>
                                                {/*<span className="m-0 mt-1 badge badge-success">{game}</span>*/}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* GAME */}
                                <Card className="mb-0">
                                    <Card.Body className={'bg-dice-options p-1 -staticSnow'} id="roll-panel">
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto ' + padding}>
                                                <div>
                                                    <Element name="slotElement">
                                                        <div id="st">
                                                            <div className="ifr">
                                                                <div className="iframe-wrap">
                                                                    <div className="iframe-main">
                                                                        <Frame game={game} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Element>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* QUEUE */}
                                <Card className="mb-5">
                                    <Card.Body className="p-1">
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto'}>
                                                <Queue t={this.props.t} game={game} slots={true} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* Recommanded */}
                                {
                                    this.state.games.length > 1 &&
                                    <>
                                        <h5 className="text-white font-16">Recommanded</h5>
                                        <Row>
                                            {this.state.games}
                                        </Row>
                                    </>
                                }
                            </Col>
                        </Row>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Game;
