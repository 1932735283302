
const vipLevels = [
    { level: 'VIP 01', minXP: 1, maxXP: 99, card: 'brownz', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 02', minXP: 100, maxXP: 199, card: 'brownz', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 03', minXP: 200, maxXP: 999, card: 'brownz', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 04', minXP: 1000, maxXP: 1999, card: 'brownz', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 05', minXP: 2000, maxXP: 2999, card: 'brownz', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 06', minXP: 3000, maxXP: 3999, card: 'brownz', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 07', minXP: 4000, maxXP: 4999, card: 'brownz', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 08', minXP: 5000, maxXP: 6999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 09', minXP: 7000, maxXP: 8999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 10', minXP: 9000, maxXP: 10999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 11', minXP: 11000, maxXP: 12999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 12', minXP: 13000, maxXP: 14999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 13', minXP: 15000, maxXP: 16999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 14', minXP: 17000, maxXP: 20999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 15', minXP: 21000, maxXP: 24999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 16', minXP: 25000, maxXP: 28999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 17', minXP: 29000, maxXP: 32999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 18', minXP: 33000, maxXP: 36999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 19', minXP: 37000, maxXP: 40999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 20', minXP: 41000, maxXP: 44999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 21', minXP: 45000, maxXP: 18999, card: 'silver', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 22', minXP: 19000, maxXP: 19999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 23', minXP: 20000, maxXP: 20999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 24', minXP: 21000, maxXP: 21999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 25', minXP: 22000, maxXP: 22999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 26', minXP: 23000, maxXP: 23999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 27', minXP: 24000, maxXP: 24999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 28', minXP: 25000, maxXP: 25999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 29', minXP: 26000, maxXP: 26999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 30', minXP: 27000, maxXP: 27999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 31', minXP: 28000, maxXP: 28999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 32', minXP: 29000, maxXP: 29999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 33', minXP: 30000, maxXP: 30999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 34', minXP: 31000, maxXP: 31999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 35', minXP: 32000, maxXP: 32999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 36', minXP: 33000, maxXP: 33999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 37', minXP: 34000, maxXP: 34999, card: 'gold', iconPath: '/assets/images/goldStar.png' },
    { level: 'VIP 38', minXP: 35000, maxXP: 35999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 39', minXP: 36000, maxXP: 36999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 40', minXP: 37000, maxXP: 37999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 41', minXP: 38000, maxXP: 38999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 42', minXP: 39000, maxXP: 39999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 43', minXP: 40000, maxXP: 40999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 44', minXP: 41000, maxXP: 41999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 45', minXP: 42000, maxXP: 42999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 46', minXP: 43000, maxXP: 43999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 47', minXP: 44000, maxXP: 44999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 48', minXP: 45000, maxXP: 45999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 49', minXP: 46000, maxXP: 46999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 50', minXP: 47000, maxXP: 47999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 51', minXP: 48000, maxXP: 48999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 52', minXP: 49000, maxXP: 49999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 53', minXP: 50000, maxXP: 50999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 54', minXP: 51000, maxXP: 51999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 55', minXP: 52000, maxXP: 52999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 56', minXP: 53000, maxXP: 53999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 57', minXP: 54000, maxXP: 54999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 58', minXP: 55000, maxXP: 55999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 59', minXP: 56000, maxXP: 56999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 60', minXP: 57000, maxXP: 57999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 61', minXP: 58000, maxXP: 58999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 62', minXP: 59000, maxXP: 59999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 63', minXP: 60000, maxXP: 60999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 64', minXP: 61000, maxXP: 61999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 65', minXP: 62000, maxXP: 62999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 66', minXP: 63000, maxXP: 63999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 67', minXP: 64000, maxXP: 64999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 68', minXP: 65000, maxXP: 65999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' },
    { level: 'VIP 69', minXP: 66000, maxXP: 99999, card: 'platinum', iconPath: '/assets/images/platinumStar.webp' }
];


export function getVipLevelDetails(xpAmount) {

    xpAmount = parseFloat(xpAmount);

    if (xpAmount === 0) {
        return {
            vipLevel: 'VIP 00',
            currentWagger: '0.0000',
            waggerNeededForNextLevel: '1.0000',
            completionPercentage: '0.00',
            card: 'brownz'
        };
    }

    for (let i = 0; i < vipLevels.length; i++) {
        const vip = vipLevels[i];
        
    
        if (xpAmount >= vip.minXP && xpAmount <= vip.maxXP) {
            const nextVip = vipLevels[i + 1] || vip; 
            const totalXPForLevel = vip.maxXP - vip.minXP + 1; 
            const xpInCurrentLevel = xpAmount - vip.minXP; 
            const completionPercentage = ((xpInCurrentLevel / totalXPForLevel) * 100).toFixed(2); 

            
            return {
                vipLevel: nextVip.level,
                previousVipLevel:vip.level,
                currentWagger: xpAmount.toFixed(2), 
                waggerNeededForNextLevel: (vip.maxXP - xpAmount).toFixed(2), 
                completionPercentage: completionPercentage, 
                card: vip.card
            };
        }
    }

    // If xpAmount doesn't match any level, return an error
    return {
        error: "XP amount exceeds the maximum VIP level"
    };
}


