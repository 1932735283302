import React, { Component } from 'react'
import {Modal, Dropdown} from "react-bootstrap";
import storage from "../../../../Storage";
import {sendNotfication, Event, isMobile} from "../../../../Helper";
import Deposit from "./Deposit";
import Withdrawl from "./Withdrawl";

class Alert extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            wallet: 'deposit',
            headerText: 'Deposit Archive',
            padding: '',
            paddingHeader: ''
        };
        this.handleWallet = this.handleWallet.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.showArchive = this.showArchive.bind(this);
    }


    componentDidMount() {
        if(isMobile()){
            this.setState({ padding: 'p-1', paddingHeader: 'px-2' })
        }

        Event.on('deposit_archive_back', () => {
            this.setState({ headerText: 'Deposit Archive' })
        })

        Event.on('withdraw_archive_back', () => {
            this.setState({ headerText: 'Withdraw Archive' })
        })
    }

    handleWallet(wallet){
        let text = (wallet === 'deposit') ? 'Deposit Archive' : 'Withdraw Archive';
        this.setState({ show: true, wallet: wallet, headerText: text })
    }

    handleClose(wallet){
        this.setState({ show: false })
    }

    showArchive(){
        this.setState({ headerText: 'Back' })
        if(this.state.wallet === 'deposit')
            Event.emit('deposit_archive')
        else if(this.state.wallet === 'withdraw')
            Event.emit('withdraw_archive')
    }

    render() {
        return(
            <>
                <Modal
                    size={'lg'}
                    backdrop={'static'}
                    centered={true}
                    scrollable={false}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-md-modal"
                >
                    <Modal.Header className={this.state.paddingHeader}>
                       <button
                        onClick={this.showArchive}
                        className={"btn bg-cs2 btn-xs text-white"}
                      > { this.state.headerText } 
                      </button>
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className={ this.state.padding + ' wallet-modal'}>
                        {
                            this.state.wallet === 'deposit' &&
                            <Deposit />
                        }
                        {
                            this.state.wallet === 'withdraw' &&
                            <Withdrawl />
                        }
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Alert;
