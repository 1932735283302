import React, { useState } from 'react';
import styled from 'styled-components';
import HelpCenter from './HelpCenter';
import UserAgreement from './UserAgreement';
import CoinAccuracyLimit from './CoinsAndCurrencyLimit';
import SupportHelp from './SupportHelp';
import FeeComponent from './FeeHelp';
import GoogleAuthenticatorComponent from './GoogleAuthenticator';
import FAQComponent from './FAQ';
import RegistrationAndLogin from './RegisterAndLogin';
import CryptocurrencyInfo from './CryptCurrencyInfo';
import PrivacyPolicyHelp from './PrivacyPolicyHelp';
import HGSwap from './HGSwapPolicy';
import TermsOfService from './TermsofServiceHelp';
import ProvidersHelp from './ProvidersHelp';

// Styled components
const HelpCenterContainer = styled.div`
  display: flex;
  color: #ffffff;

  gap: 10px;
  align-items: flex-start; // Aligns children to the top
`;

const SideContent = styled.div`
  width: 200px;
  padding: 20px;
  background-color: #31343C;
  border-radius: 20px;
  align-self: flex-start; // Prevents this from stretching vertically
`;

const SideContentItem = styled.div`
  padding: 10px 0;
  cursor: pointer;
  color: ${props => props.active ? '#57D218' : '#72767d'};
  &:hover {
    color: #ffffff;
  }
`;

const MainContent = styled.div`
  flex: 1;
  min-height: 100%; // Ensures it takes at least full height of the container
`;

// Main HelpCenter component
const ProvablyFair = () => {
    const [activeSection, setActiveSection] = useState('Provably fair');

    const sideContentItems = [
        { name: 'Provably fair', component: HelpCenter },
        { name: 'User Agreement', component: UserAgreement },
        { name: 'Coin Accuracy Limit', component: CoinAccuracyLimit },
        { name: 'Support', component: SupportHelp },
        { name: 'Fee', component: FeeComponent },
        { name: 'Google Authenticator', component: GoogleAuthenticatorComponent },
        { name: 'FAQ', component: FAQComponent },
        { name: 'Cryptocurrency', component: CryptocurrencyInfo },
        { name: 'Privacy Policy', component: PrivacyPolicyHelp },
        { name: 'Registration and Lo...', component: RegistrationAndLogin },
        { name: 'Swap Policy', component: HGSwap },
        { name: 'Terms of Service', component: TermsOfService },
        { name: 'Providers', component: ProvidersHelp }
    ];

    const ActiveComponent = sideContentItems.find(item => item.name === activeSection)?.component || (() => null);

    return (
        <div style={{ marginTop: '48px' }}>

            <div style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0', color: '', display: 'flex', gap: '10px', alignContent: 'center', alignItems: 'center' }}>
                <img style={{height:'20px', width:'20px'}} src='/assets/images/taraju.png' />
                <div>            Help Center</div>
            </div>
            <HelpCenterContainer>

                <SideContent>
                    {sideContentItems.map(item => (
                        <SideContentItem
                            key={item.name}
                            active={activeSection === item.name}
                            onClick={() => setActiveSection(item.name)}
                        >
                            {item.name}
                        </SideContentItem>
                    ))}
                </SideContent>
                <MainContent>
                    <ActiveComponent />
                </MainContent>
            </HelpCenterContainer>
        </div>
    );
};

export default ProvablyFair;