import React from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import BronzeTable from './Bronze';
import SilverTable from './Silver';
import GoldTable from './Gold';
import PlatinumTable from './platinumStar';
import DiamondStarTable from './DiamondStar';

const CarouselWrapper = styled.div`
  position: relative;
  background-color: #1e2124;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 40px;
  overflow-x: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  min-width: 80px;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  padding: 5px;

  &:hover {
    background-color: ${props => props.color ? `${props.color}33` : 'transparent'};
  }
`;

const ImageContainer = styled.div`
  height: 45px;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const EngineImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ItemName = styled.span`
  color: #ffffff;
  font-size: 12px;
`;

const ColorBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${props => props.color};
  margin-top: 5px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;
const ScrollButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1A1B1E;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 5px;
  border-radius:50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #1e2124;
  padding: 10px;
  border-radius: 8px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #2f3136;
  border-radius: 4px;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #5865f2;
  border-radius: 4px;
`;

const LevelIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const LevelCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #5865f2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-right: 8px;
`;

const LevelText = styled.span`
  color: #72767d;
  font-size: 14px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: #72767d;
  font-size: 12px;
`;

const WagerButton = styled.button`
  background-color: #24262B;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content:center;
  cursor: pointer;
  width:300px;
  
  &:hover {
    background-color: #2a2a2a;
  }
`;

const WagerAmount = styled.span`
  color: #ffd700;
  margin-right: 4px;
  font-weight: bold;
`;

const XPText = styled.span`
  color: #4caf50;
  margin-left: 4px;
`;




const engineTypes = [
  { name: 'V04', color: '#9196A8', imagePath: '/assets/images/box.png' },
  { name: 'V08', color: '#E8DAFF', imagePath: '/assets/images/box.png' },
  { name: 'V14', color: '#E8DAFF', imagePath: '/assets/images/box.png' },
  { name: 'V22', color: '#ffa500', imagePath: '/assets/images/box.png' },
  { name: 'V30', color: '#ffa500', imagePath: '/assets/images/box.png' },
  { name: 'V38', color: '#8a2be2', imagePath: '/assets/images/box.png' },
  { name: 'V46', color: '#8a2be2', imagePath: '/assets/images/box.png' },
  { name: 'V54', color: '#8a2be2', imagePath: '/assets/images/box.png' },
  { name: 'V62', color: '#8a2be2', imagePath: '/assets/images/box.png' },
  { name: 'SVIP', color: '#ff0000', imagePath: '/assets/images/box.png' },
  { name: 'SV9', color: '#ff0000', imagePath: '/assets/images/box.png' },
  { name: 'SV17', color: '#ff0000', imagePath: '/assets/images/box.png' },
  { name: 'SV22', color: '#ff0000', imagePath: '/assets/images/box.png' },
];

const EngineCarousel = () => {
  const scrollCarousel = (direction) => {
    const container = document.getElementById('carousel-container');
    const scrollAmount = direction === 'left' ? -100 : 100;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  return (
    <>
      <div style={{width:'100%', display:'flex', justifyContent:'center', margin:'24px 0' }}>
        <WagerButton>
          Wager <WagerAmount> 1 USD</WagerAmount> Get<XPText>1 XP</XPText>
        </WagerButton>
      </div>
      <div style={{marginBottom:'20px', color:'#99a4b0cc', textAlign:'center'}}>
        All wagers are converted to USD at the current rate
      </div>
      <CarouselWrapper>
        <div style={{fontSize:'16px', marginTop:'30px', textAlign:'center', padding:'12px'}}>Whole VIP Bonus</div>
        <ScrollButton className="left" onClick={() => scrollCarousel('left')}>
          <ChevronLeft size={18} />
        </ScrollButton>
        
        <CarouselContainer id="carousel-container">
          {engineTypes.map((engine, index) => (
            <CarouselItem key={index} color={engine.color}>
              <ImageContainer>
                <EngineImage src={engine.imagePath} alt={engine.name} />
              </ImageContainer>
              <ItemName>{engine.name}</ItemName>
              <ColorBar color={engine.color} />
            </CarouselItem>
          ))}
        </CarouselContainer>
        <ScrollButton className="right" onClick={() => scrollCarousel('right')}>
          <ChevronRight size={18} />
        </ScrollButton>
      </CarouselWrapper>
      <ProgressBarContainer>
        <LevelIndicator>
          <LevelCircle>V0</LevelCircle>
          <LevelText>V01</LevelText>
        </LevelIndicator>
        <ProgressBar>
          <Progress progress={0} />
        </ProgressBar>
        <div style={{margin:'30px 0', color:'#99a4b0cc', textAlign:'', padding:'0 12px;'}}>
          Hellogame VIP Level System sets 14 VIP Secret Treasures. Although there are rewards for every VIP level, the rewards for 14 secret treasures are exceptionally generous. Rewards increase as the VIP card level climbs. Speed up!
        </div>
      </ProgressBarContainer>

      <BronzeTable/>
      <SilverTable/>
      <GoldTable/>
      <PlatinumTable/>
      <DiamondStarTable/>
    </>
  );
};

export default EngineCarousel;