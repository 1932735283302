import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
   background-color: #36393f;
  color: #b9bbbe;
  padding: 24px;
  border-radius: 20px;
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 16px;
`;

const Paragraph = styled.div`
  margin-bottom: 16px;
  line-height: 1.5;
`;

const Link = styled.a`
  color: #00b0f4;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const GoogleAuthenticatorComponent = () => {
  return (
    <Container>
      <Title>Google Authenticator</Title>
      <Paragraph>
        Google authenticator could provide an additional layer of security protection for your account. It is a software token that
        implements a 2-step verification program. In order to use Google authenticator, you must have a mobile phone because it
        will be installed as a mobile application. You can also use Google authenticator when offline.
      </Paragraph>
      <Paragraph>
        Google authenticator uses an algorithm to calculate one-time passwords based on time. Simply put, once the application
        is started, Google authenticator will display a randomly generated 6-digit sequence of numbers, ie, a one-time password. If
        you have enabled 2FA in your account, then in addition to entering your usual password, you also need to enter the
        randomly generated one-time password. 2FA provides additional security protection because it makes sure that others
        cannot login to your account with your username and password alone.
      </Paragraph>
      <Paragraph>
        We highly values the security of the players, so we strongly recommend you to use Google authenticator. This small extra
        step could protect you against necessary headache and anxiety.
      </Paragraph>
      <Paragraph>
        If you have enabled Google authenticator, you will be prompted to complete 2FA every time you login or withdraw.
        Furthermore, please print out the QR code or write down the manual code that could recover your account. In case of
        mobile phone breakdown or loss, this will help you to a great extent.
      </Paragraph>
      <Paragraph>
        To know more about Google authenticator. <Link href="#">Google authenticator</Link>
      </Paragraph>
    </Container>
  );
};

export default GoogleAuthenticatorComponent;