import MyEmitter from "events";
import socket from "../../../Socket";
import {encode, decode, forceSatoshiFormat, randomString} from "../../../Helper";
import C from "../../../Constant";

function Engine() {
    var self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = 'crash';

    /**
     * Event Trigger
     */
    self.trigger = new MyEmitter();

    /**
     * Initialize Player
     */
    self.init = false;

    /**
     * Script Form Data
     */
    self.formData = [];

    /**
     * Amount for the round
     */
    self.amount = null;

    /**
     * Payout value for the round
     */
    self.payout = null;

    /**
     * Target User for following
     */
    self.targetUser = null;

    /**
     * Game Status
     */
    self.gameStatus = null;

    /**
     * Player coin
     */
    self.coin = null;

    /**
     * Player Status
     */
    self.playerStatus = null;

    /**
     * Game History
     */
    self.history = [];

    /**
     * User Is Playing Game
     */
    self.isPlaying = false;

    /**
     * User Is Holding for Game
     */
    self.isHolding = false;

    /**
     * Game Options
     */
    self.time = null;
    self.md5 = null;
    self.force = null;

    /**
     * Table Management Properties
     */
    self.tableData = [];
    self.visibleRowsCount = 20;
    self.lastUpdateTime = Date.now();
    self.updateInterval = null;
    self.currentMultiplier = 1.00;
    self.maxMultiplier = 3.00;
    self.isGameActive = false;

    /**
     * Payout Animation Properties
     */
    self.payoutPoints = [];
    self.currentPayoutIndex = 0;
    self.payoutAnimationInterval = null;

    /*
     * Start Engine
    */
    self.started = true;

    /**
     * Socket Events
     */
    if (self.started) {
        self.ws.on(C.STATUS_CRASH, function (data) {
            data = decode(data);
            self.gameStatus = data.status;
            self.history = data.crashes || [];
            self.time = data.time;
            data.md5 = (data.md5 !== undefined) ? data.md5 : null;
            data.force = data.force;
            data.amount = data.amount;
            self.emit('game_status', data);
        });

        self.ws.on(C.HISTORY_CRASH, function (data) {
            data = decode(data);
            self.emit(C.HISTORY_CRASH, data);
        });

        self.ws.on(C.PLAYERS_CRASH, function (data) {
            data = decode(data);
            self.emit('game_players', data);
        });

        self.ws.on(C.PLAY_CRASH, function (data) {
            data = decode(data);
            self.isPlaying = true;
            self.updateTableData({
                user: 'Hidden',
                betAmount: parseFloat(data.amount || 0).toFixed(2),
                multiplier: '0.00x',
                profit: 0.00
            });
            self.emit('play_crash', data);
        });

        self.ws.on(C.FINISH_CRASH, function (data) {
            data = decode(data);
            self.isPlaying = false;
            self.updateTableData({
                user: 'Hidden',
                betAmount: parseFloat(data.amount || 0).toFixed(2),
                multiplier: `${parseFloat(data.payout || 0).toFixed(2)}x`,
                profit: parseFloat(data.profit || 0).toFixed(2)
            });
            self.emit('finish_crash', data);
        });

        self.ws.on(C.BUSTED_CRASH, function (data) {
            data = decode(data);
            self.isPlaying = false;
            self.gameStatus = 'busted';
            self.isGameActive = false;
            if (data.crash) self.history.unshift(data.crash);
            self.time = data.time;
            data.md5 = (data.md5 !== undefined) ? data.md5 : null;
            data.force = data.force;
            data.amount = data.amount;
            self.emit('busted_crash', data);
            self.emit('busted_crash_history', data);
            self.stopAutoUpdateTable();
            self.stopPayoutAnimation();
        });

        self.ws.on(C.STARTED_CRASH, function (data) {
            data = decode(data);
            self.gameStatus = 'started';
            self.time = data.time;
            data.md5 = (data.md5 !== undefined) ? data.md5 : null;
            data.force = data.force;
            data.amount = data.amount;
            self.currentMultiplier = 1.00;
            self.isGameActive = true;
            self.emit('started_crash', data);
            self.startPayoutAnimation();
        });

        self.ws.on(C.WAITING_CRASH, function (data) {
            data = decode(data);
            self.gameStatus = 'waiting';
            self.time = data.time;
            data.md5 = (data.md5 !== undefined) ? data.md5 : null;
            data.force = data.force;
            data.amount = data.amount;
            self.emit('waiting_crash', data);
        });

        self.ws.on(C.ERROR_CRASH, function (data) {
            data = decode(data);
            self.emit('error_crash', data);
        });
    }
}

/**
 * Table Management Methods
 */
Engine.prototype.updateTableData = function(newRow) {
    let self = this;
    
    const currentTime = Date.now();
    if (currentTime - self.lastUpdateTime < 500) {
        return;
    }
    
    self.lastUpdateTime = currentTime;
    
    self.tableData.unshift(newRow);
    if (self.tableData.length > self.visibleRowsCount) {
        self.tableData = self.tableData.slice(0, self.visibleRowsCount);
    }
    
    self.emit('table_updated', self.getVisibleTableData());
};

Engine.prototype.getVisibleTableData = function() {
    let self = this;
    const visibleRows = Math.floor(Math.random() * 11) + 10; // Random number between 10 and 20
    return self.tableData.slice(0, visibleRows);
};

Engine.prototype.startAutoUpdateTable = function() {
    let self = this;
    if (self.updateInterval) {
        clearInterval(self.updateInterval);
    }

    self.updateInterval = setInterval(() => {
        if (self.gameStatus === 'started' && self.isGameActive) {
            self.currentMultiplier += 0.01;
            
            if (self.currentMultiplier >= self.maxMultiplier) {
                self.currentMultiplier = self.maxMultiplier;
                return; // Stop adding new rows
            }

            const randomAmount = (Math.random() * 95 + 5).toFixed(2);
            const currentMultiplier = self.currentMultiplier.toFixed(2);
            const profit = (randomAmount * (self.currentMultiplier - 1)).toFixed(2);
            
            self.updateTableData({
                user: 'Hidden',
                betAmount: randomAmount,
                multiplier: `${currentMultiplier}x`,
                profit: profit
            });
        }
    }, 100);
};

Engine.prototype.stopAutoUpdateTable = function() {
    let self = this;
    if (self.updateInterval) {
        clearInterval(self.updateInterval);
        self.updateInterval = null;
    }
};

/**
 * Payout Animation Methods
 */
Engine.prototype.generatePayoutPoints = function() {
    let self = this;
    self.payoutPoints = [];
    const numPoints = Math.floor(Math.random() * 6) + 10; // 10 to 15 points
    let currentPayout = 1.00;

    for (let i = 0; i < numPoints; i++) {
        currentPayout += Math.random() * 0.2; // Random increase up to 0.2
        if (currentPayout > 3.00) currentPayout = 3.00;
        self.payoutPoints.push(parseFloat(currentPayout.toFixed(2)));
    }

    // Ensure the last point is exactly 3.00
    self.payoutPoints[self.payoutPoints.length - 1] = 3.00;
};

Engine.prototype.startPayoutAnimation = function() {
    let self = this;
    self.generatePayoutPoints();
    self.currentPayoutIndex = 0;

    self.payoutAnimationInterval = setInterval(() => {
        if (self.currentPayoutIndex < self.payoutPoints.length) {
            self.currentMultiplier = self.payoutPoints[self.currentPayoutIndex];
            self.emit('payout_updated', self.currentMultiplier);
            self.currentPayoutIndex++;
        } else {
            self.stopPayoutAnimation();
            self.startAutoUpdateTable();
        }
    }, 1500); // Update every second
};

Engine.prototype.stopPayoutAnimation = function() {
    let self = this;
    if (self.payoutAnimationInterval) {
        clearInterval(self.payoutAnimationInterval);
        self.payoutAnimationInterval = null;
    }
};

/**
 * Event Management Methods
 */
Engine.prototype.off = function() {
    let self = this;
    self.stopAutoUpdateTable();
    self.stopPayoutAnimation();
    self.ws.off(C.STATUS_CRASH);
    self.ws.off(C.ERROR_CRASH);
    self.ws.off(C.WAITING_CRASH);
    self.ws.off(C.STARTED_CRASH);
    self.ws.off(C.BUSTED_CRASH);
    self.ws.off(C.FINISH_CRASH);
    self.ws.off(C.PLAY_CRASH);
    self.ws.off(C.PLAYERS_CRASH);
    self.ws.off(C.HISTORY_CRASH);
};

Engine.prototype.on = function(name, callback) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function(name, data = []) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.emit(name, data);
};

/**
 * Game Action Methods
 */
Engine.prototype.play = function() {
    let self = this;
    if (self.amount < 0.00000010) {
        self.amount = forceSatoshiFormat(0.00000010);
    }
    self.ws.emit(C.PLAY_CRASH, encode({
        amount: self.amount,
        payout: self.payout,
        coin: self.coin
    }));
};

Engine.prototype.finish = function(time) {
    let self = this;
    self.ws.emit(C.FINISH_CRASH, encode({
        token2: randomString(25) + parseFloat(time).toFixed(2),
    }));
};

Engine.prototype.getStatus = function() {
    let self = this;
    self.ws.emit(C.STATUS_CRASH);
};

Engine.prototype.getPlayers = function() {
    let self = this;
    self.ws.emit(C.PLAYERS_CRASH);
};

Engine.prototype.getHistory = function() {
    let self = this;
    self.ws.emit(C.HISTORY_CRASH);
};

export default Engine;