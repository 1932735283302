import React from "react";
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import styled from 'styled-components';
import socket from "../../../Socket";
import storage from "../../../Storage";
import C from "../../../Constant";
import {
  getUID,
  encode,
  SITE_URL,
  decode,
  wait,
  sendNotfication,
} from "../../../Helper";

const StyledButton = styled.button`
  background: linear-gradient(to right, #4CAF50, #45a049);
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background: linear-gradient(to right, #45a049, #4CAF50);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const RakeBackWrapper=styled.div`
position:absolute
top:0;
left:0;
`;

const TopImageCard = styled.div`
height:332px;
margin:30px 0;
border-radius:1.25rem;
position:relative;
width:100%
background-color: rgba(0, 0, 0, 0.3);
    background-size: auto 100%;
        background-position: center center;
        background-image:url("/assets/images/rakebackNano.jpg")
`;

export default class Rakeback extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      token: storage.getKey("token") ? storage.getKey("token") : null,
      uid: getUID,
      logged: false,
      amount: 0,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (this.state.token !== null) {
      this.setState({ logged: true });
    }

    this._isMounted = true;
    wait(200).then(() => {
      socket.emit(C.RAKEBACK_AMOUNT);
    });
    socket.on(C.RAKEBACK_AMOUNT, (data) => this.addList(decode(data)));
    socket.on(C.ADD_RAKEBACK, (data) => this.makeList(decode(data)));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addList(amount) {
    if (amount) {
      this.setState({ amount: amount.amount });
    }
  }

  makeList(data) {
    if (this._isMounted) {
      wait(1000).then(() => {
        socket.emit(C.CREDIT);
        let message = data.status.toString();
        sendNotfication(message, "info", "top-center");
      });
    }
  }

  addReward() {
    socket.emit(C.ADD_RAKEBACK);
  }

  render() {
    let { logged } = this.state;

    return (
      <>
        <Helmet>
          <title>Rakeback - Crypto Casino Games</title>
          <meta
            name="keywords"
            content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, PlayCrash.com Slot Games"
          />
          <meta
            name="description"
            content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !"
          />
          <meta
            name="og:title"
            content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !"
          />
          <meta
            name="og:description"
            content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !"
          />
          <link rel="canonical" href="/rakeback" />
        </Helmet>
        <div>

          <TopImageCard>
            <RakeBackWrapper>
            <div
          
          className={"p-4 "}
        >
          <div
          style={{fontSize:'46px', marginTop:'-10px'}}
            className={"text-center !font-[42px] text-upper text-warning font-weight-bold"}
          >
            Rakeback
          </div>

          <Row style={{marginTop:'45px'}}>
            <Col sm={12} xl={5} md={5} className={"m-auto"}>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                  <div style={{color:'white', fontSize:'14px', fontWeight:'bold'}} className="!font-bold mb-2 ">
                    Current Rakeback Reward
                  </div>
                  {logged ? (
                    <>
                      <input
                        type="text"
                        value={this.state.amount}
                        style={{width:'180px', background:'#f3c74d', borderRadius:'15px', color:'black', fontWeight:'600'}}
                        className={"form-control text-center"}
                      />
                      <StyledButton
                        className="mt-2 my-3 px-4 animated fadeIn text-center"
                        onClick={(e) => this.addReward()}
                      >
                      Claim
                      </StyledButton>
                    </>
                  ) : (
                    <input
                      type="text"
                      value={"Please login to see your Reward."}
                      className={"form-control"}
                    />
                  )}
                  </div>
              
            </Col>
          </Row>
        </div>
            </RakeBackWrapper>
          </TopImageCard>
         
        </div>
      </>
    );
  }
}