import React from "react";
import { Modal } from "react-bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import { wait, isMobile, isTablet, Event, __, hasClass } from "./Helper";

const chatSideBarWidth = 330;

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chat: false
        }
    }

    // componentDidMount() {
    //     if (isMobile() || isTablet()) {
    //         this.setState({ chat: false });
    //     }
    //     window.addEventListener('resize', this.handleResize);

    //     wait(500).then(() => {
    //         // this.handleResize();
    //     });

    //     Event.on('toggle_sidebar', (show) => {
    //         wait(100).then(() => {
    //             // this.handleResize();
    //         });
    //     })

    //     Event.on('toggle_chat', (show) => {
    //         this.setState({ chat: show })
    //         wait(100).then(() => {
    //             // this.handleResize();
    //         });
    //     })
    // }

    // componentWillUnmount() {
    //     // window.removeEventListener('resize', this.handleResize);
    // }

    // handleResize = () => {
    //     const leftSidenav = document.querySelector('.left-sidenav');
    //     const leftSidebar = document.querySelector('.left-sidebar');
    //     const pageContent = document.querySelector('#page-content');
    //     const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

    //     if (pageContent !== null) {
    //         if (!isMobile()) {
    //             if (this.state.chat) {
    //                 pageContent.style.marginLeft = '40px';
    //             }
    //             else {
    //                 pageContent.style.marginLeft = 'auto';
    //             }

    //             let pWidth = this.state.chat === true ? clientWidth - 600 : clientWidth;
    //             pageContent.style.width = pWidth + 'px';

    //             if (clientWidth < 1200) {
    //                 if (leftSidebar) {
    //                     pageContent.style.marginLeft = '0';

    //                     pWidth = this.state.chat === true ? clientWidth - 416 : clientWidth;
    //                     pageContent.style.width = pWidth + 'px';

    //                     // leftSidebar.classList.remove('big');
    //                     // leftSidebar.classList.add('min');
    //                     // leftSidenav.classList.remove('big');
    //                     // leftSidenav.classList.add('min');
    //                 }
    //             }
    //             else {
    //                 if (leftSidebar) {
    //                     // leftSidebar.classList.remove('min');
    //                     // leftSidebar.classList.add('big');
    //                     // leftSidenav.classList.remove('min');
    //                     // leftSidenav.classList.add('big');
    //                 }
    //             }
    //         }
    //         else {

    //         }

    //     }
    // };

    render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }
}

export default Layout;
