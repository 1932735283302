import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Button, Card, Dropdown} from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import {Event, isValidNumber, forceSatoshiFormat, sendNotfication, wait} from "../../../Helper";
import {setPlay} from "../../../actions/gamePlay";
import {setRisk} from "../../../actions/gameOption";
import {gameCoin} from "../../../actions/gameCoin";
import {setWallet} from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";

class Bet extends React.Component {
    _isMounted = false;
    _autoPlay = null;

    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            bet: 'manual',
            started: false,
            risk: '8',
            riskName: 'Low',
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            isLogged: storage.getKey("logged") !== null ? true : false,
            credit: storage.getKey('credit'),
            inputDisabled: false,
            buttonText: 'BET',
            added: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;
        this.props.gameCoin();
        engine.on(C.PLAY_PLINKO, () => this.play());
        engine.on(C.BUSTED_PLINKO, (data) => this.busted(data));
        engine.on(C.ERROR_PLINKO, (data) => this.error(data));
        engine.on("stop_playing", () => this.stopManual());
    }
    
    stopManual() {
        this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
    }

    componentWillMount() {
        this._isMounted = true;
        window.cancelAnimationFrame(this._autoPlay);
    }

    componentWillUnmount() {
        let { engine, started } = this.state;
        this._isMounted = false;
        engine.init = false;

        try {
            if(started)
                document.getElementById('bet').click()
        }
        catch (e) {}
    }


    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });

            if(this.state.started){
                this.setState({ started: false, buttonText: 'Start Auto Mode' });
                window.cancelAnimationFrame(this._autoPlay);
            }
        }
    }

    handleInputChange(event){
        let target = event.target;
        let value = target.value;

        if(target.name === 'amount'){
            this.setState({amount:value})
            if(!isValidNumber(value)) return;
        }
        
        if(target.name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({ [target.name]: value });
    }

    handleBet(e) {
        e.preventDefault();
        let {  amount, isLogged, bet, started } = this.state;

       // Check User
        if(!isLogged){
           return Event.emit('showAuthModal', true);
        }
        
        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }
    
        this.setState({ inputDisabled: true });

        if(bet === 'auto')
        {
            if(started)
            {
                this.setState({ started: false, buttonText: 'Start Auto Mode', inputDisabled: false });
                window.cancelAnimationFrame(this._autoPlay);
                return false;
            }
            else 
            {
                this.setState({ started: true, buttonText: 'Stop Auto Mode' });

                let self = this;

                function auto(){
                    if(self._isMounted){
                        let { engine, amount } = self.state;

                        if(!self.state.added){
                            self.setState({ added: true });

                            engine.init = true;
                            engine.coin = self.props.coin;
                            engine.amount = amount;
                            engine.autoStart();

                            wait(2000).then(() => {
                                self.setState({ added: false });
                            })

                        }
                        self._autoPlay = window.requestAnimationFrame(auto)
                    }
                }

                this._autoPlay = window.requestAnimationFrame(auto);
            }
        }
        else
        {
            this.placeBet();
        }
    }

    placeBet(){
        if(this._isMounted){
            let { engine, amount } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin   = this.props.coin;
                    engine.start();
                })
        }
    }

    busted(data){
        if(this._isMounted){
            let { engine, bet, started } = this.state;

            wait(100)
                .then( () => {
                    this.setState({ lock: false, playing: false, inputDisabled: false });

                    if(bet !== 'auto'){
                        engine.init = false;
                    }
                })
        }
    }

    play(){
        let { bet, engine, started } = this.state;
        if(this._isMounted)
        {
            wait(500).then(() => {
                this.setState({ inputDisabled: false });
            })

            //Set For Redux
            this.props.setPlay(true);
        }
    }

    handleChange = value => {
        this.setState({ risk: value });
        this.props.setRisk(value);
        this.setState({ riskName: value });
    }

    betMode = (type) => {
        let text;

        if(type === 'auto'){
            text = 'Start Auto Mode';
        }
        else {
            text = 'BET';
        }
        this.setState({ bet: type, buttonText: text });
    };
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
    }

    render() {
        let {inputDisabled, amount, bet, started, riskName} = this.state;

        return (
            <>
                <Row>
                    <Col lg={12} md={12} sm={12} className={'m-auto'}>
                        <Card className={'mb-0'}>
                            <Card.Body className={'px-2 pt-2 py-0'}>
                                <Row>
                                    <Col xl={6} md={8} sm={12}>
                                        <div className={"form-group mb-1 bet-input"}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                                    BET
                                                </span>
                                                </div>
                                                <input disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                                       placeholder="Enter Bet Amount" value={amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                                <div className="input-group-append">
                                                    <RangeCredit set={this.setAmount} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6} md={4} sm={12}>
                                        <div className={"form-group mb-1"}>
                                            <div className="input-group fw">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">Power</span>
                                                </div>
                                                <Dropdown className={"drop-select btn-block"}>
                                                    <Dropdown.Toggle split variant="" className={'py-0 mt-0'} >
                                                    <span className="text-muted text-center">
                                                        <i className={'mdi mdi-chevron-down mr-1'} />
                                                        {riskName}
                                                     </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu disabled={inputDisabled} className={"dopdown-menu-bottom " + inputDisabled }>
                                                        <Dropdown.Item onClick={e => this.handleChange('Low')} className={'animated fadeIn'}>
                                                            Low
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleChange('Medium')} className={'animated fadeIn'}>
                                                            Medium
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleChange('High')} className={'animated fadeIn'}>
                                                            High
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5} className={"m-auto"}>
                                        {bet === 'manual' &&
                                        <Button variant={'btn my-1 btn-block btn-bet btn-sm'}
                                                onClick={(e) => {this.handleBet(e)}}
                                                disabled={inputDisabled}
                                                type="submit">
                                            {this.state.buttonText}
                                        </Button>
                                        }
                                        {bet === 'auto' &&
                                            <Button id={'bet'} variant={'btn my-1 btn-block btn-bet btn-sm'}
                                                    onClick={(e) => {this.handleBet(e)}}
                                                    type="submit">
                                                {this.state.buttonText}
                                                {started &&
                                                    <><div className={'ml-2 spinner-border spinner-border-sm'} /></>
                                                }
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                                <div className="btn-group btn-group-toggle mb-1 ml-1" data-toggle="buttons">
                                   <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active': 'btn bg-cs2 btn-xs '}>
                                        <input disabled={started} onClick={ e => this.betMode('manual')} type="radio" /> Manual
                                    </label>
                                   <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active': 'btn bg-cs2 btn-xs '}>
                                        <input disabled={started} onClick={ e => this.betMode('auto')} type="radio" /> Auto
                                    </label>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

Bet.propTypes = {
    setPlay: PropTypes.bool,
    setRisk: PropTypes.number,
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { setPlay, setRisk, gameCoin, setWallet })(Bet);
