import React from 'react';
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import { isMobile, Event, wait, isValidNumber, forceSatoshiFormat, __, sendNotfication, playAudio } from "../../../Helper";
import C from "../../../Constant";

class Bet extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {                           
            engine: this.props.engine,
            bet: 'manual',
            buttonText: 'Roll',
            started: false,
            isLogged: storage.getKey("logged") !== null,
            amount: forceSatoshiFormat(storage.getKey('lam') || 0.00000010),
            disabled: false,
            payout: '101',
            type: 'low',
            betNumber: 100000,
            firstBetNumber: 100000,
            onWin: 0,
            onLost: 0,
            stopOnWin: 0,
            isWin: false,
            initialAmount: 0
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        const { engine } = this.state;
        
        this.props.gameCoin();
        engine.on(C.BUSTED_HIGHLOW, (data) => this.busted(data));
        engine.on(C.ERROR_HIGHLOW, (data) => this.error(data));
        engine.on("stop_high_low", () => this.stopAuto());
        engine.on("stop_playing", () => this.stopManual());

        this.setState({ 
            mt: isMobile() ? "mt-2" : "",
            initialAmount: this.state.amount,
            amount: 0
        });
    }

    componentWillUnmount() {
        const { engine, started } = this.state;
        engine.init = false;
        this._isMounted = false;

        if (started) {
            try {
                document.getElementById('bet').click();
            } catch (e) {
                console.error('Error stopping bet:', e);
            }
        }
    }


    error(data) {
        if (this._isMounted) {
            sendNotfication(data.message, 'info', 'top-center');

            if (data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ disabled: false });

            if (this.state.started) {
                this.setState({ started: false, buttonText: 'Start Auto Mode' });
            }
        }
    }

    busted = (data) => {
        if (!this._isMounted) return;

        const { engine, bet, started } = this.state;
        const isWin = parseFloat(data.profit) >= 0;

        this.setState({ isWin }, () => {
            if (bet === 'auto' && started) {
                engine.isWin = isWin;
                engine.roundProfit = __.toNumber(data.profit) + __.toNumber(engine.roundProfit);

                wait(1100).then(() => {
                    if (this._isMounted && this.state.started) {
                        this.placeAutoBet();
                    }
                });
            }
        });

        // Handle UI and sound effects
        wait(1000).then(() => {
            if (this._isMounted) {
                this.setState({ disabled: false });
            }
        });

        if (isWin) {
            wait(500).then(() => playAudio('win.mp3'));
        }
    }

    setBet = (e) => {
        e.preventDefault();
        if (!this._isMounted) return;

        const { isLogged, amount, bet, started } = this.state;

        if (!isLogged) {
            return Event.emit('showAuthModal', true);
        }

        this.setState({ disabled: true });

        // Handle amount validation
        const validAmount = parseFloat(amount) <= 0 
            ? 0.00000010 
            : Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));

        this.setState({ amount: forceSatoshiFormat(validAmount) }, () => {
            if (bet === 'auto') {
                if (started) {
                    this.setState({ 
                        started: false, 
                        buttonText: 'Start Auto Mode', 
                        disabled: false 
                    });
                } else {
                    this.setState({ 
                        started: true, 
                        buttonText: 'Stop Auto Mode',
                        initialAmount: validAmount
                    }, () => this.placeAutoBet());
                }
            } else {
                this.placeBet();
            }
        });
    }
//     placeAutoBet = () => {
//         if (!this._isMounted || !this.state.started) return;

//         const {
//             engine,
//             amount,
//             type,
//             betNumber,
//             firstBetNumber,
//             onWin,
//             onLost,
//             stopOnWin,
//             isWin,
//         } = this.state;
// const  initialAmount = amount;
//         wait(500).then(() => {
//             if (!this._isMounted || !this.state.started) return;

//             // Calculate new bet amount based on win/loss
//             let newAmount = parseFloat(amount);
//             if (engine.roundProfit !== undefined) {
//                 // Adjust amount based on win or loss
//                 if (isWin) {
//                     // If onWin is set, increase the initialAmount by the percentage
//                     if (onWin !== 0) {
//                         newAmount = initialAmount * (1 + parseFloat(onWin) / 100);
//                         this.setState({ amount: newAmount });
//                         console.log(`Win: Reset to initial amount and apply onWin percentage. New amount: ${newAmount}`);
//                     } else {
//                         // Otherwise, reset to initial bet amount
//                         this.setState({ amount: initialAmount });
//                         console.log(`Win: Reset to initial amount. New amount: ${initialAmount}`);
//                     }
    
//                     // If stopOnWin is true, end the auto-bet after a win
//                     if (stopOnWin) {
//                         console.log("Auto-betting stopped due to win.");
//                         engine.trigger.emit("stop_classic_dice");
//                         return;  // Stop further betting
//                     }
    
//                 } else {
//                     // On loss, increase the bet amount by the onLost percentage
//                     if (onLost !== 0) {
//                          newAmount = amount * (1 + parseFloat(onLost) / 100);
//                         this.setState({ amount: newAmount });
//                         console.log(`Loss: Increase bet by onLost percentage. New amount: ${newAmount}`);
//                     }
//                 }
//             }

//             // Ensure amount is within valid range
//             const maxCredit = parseFloat(storage.getKey('credit'));
//             newAmount = Math.max(0.00000010, Math.min(newAmount, maxCredit));

//             this.setState(prevState => ({
//                 amount: forceSatoshiFormat(newAmount),
//                 betNumber: prevState.betNumber > 0 ? prevState.betNumber - 1 : 0
//             }), () => {
//                 if (this.state.betNumber > 0 && this.state.started) {
//                     this.executeAutoBet(engine, newAmount, type);
//                 } else {
//                     this.stopAutoBetting(firstBetNumber);
//                 }
//             });
//         });
//     }
placeAutoBet() {
    if (this._isMounted) {
        let {
            engine,
            amount,            // Current bet amount
            payout,            // Current payout value
            type,              // Bet type (e.g., low/high)
            betNumber,         // Total number of remaining bets
            firstBetNumber,    // Initial number of bets
            onWin,             // Percentage increase on win
            onLost,            // Percentage increase on loss
            stopOnWin,         // Stop betting after a win
            isWin,             // Whether the last bet was a win
        } = this.state;
 const initialAmount = amount;
        // Delay for each bet
        wait(500).then(() => {
            console.log("Current bet number:", betNumber);
            console.log("IsWin:", isWin);

            // Adjust amount based on win or loss
            if (isWin) {
                // If onWin is set, increase the initialAmount by the percentage
                if (onWin !== 0) {
                    const newAmount = initialAmount * (1 + parseFloat(onWin) / 100);
                    this.setState({ amount: newAmount });
                    console.log(`Win: Reset to initial amount and apply onWin percentage. New amount: ${newAmount}`);
                } else {
                    // Otherwise, reset to initial bet amount
                    this.setState({ amount: initialAmount });
                    console.log(`Win: Reset to initial amount. New amount: ${initialAmount}`);
                }

                // If stopOnWin is true, end the auto-bet after a win
                if (stopOnWin) {
                    console.log("Auto-betting stopped due to win.");
                    engine.trigger.emit("stop_classic_dice");
                    return;  // Stop further betting
                }

            } else {
                // On loss, increase the bet amount by the onLost percentage
                if (onLost !== 0) {
                    const newAmount = amount * (1 + parseFloat(onLost) / 100);
                    this.setState({ amount: newAmount });
                    console.log(`Loss: Increase bet by onLost percentage. New amount: ${newAmount}`);
                }
            }

            // Decrease the betNumber after each bet
            this.setState(prevState => ({ betNumber: prevState.betNumber - 1 }));

            

            // Update engine with the new bet amount and other parameters
            const updatedAmount = this.state.amount;
            engine.init = true;
            engine.amount = parseFloat(updatedAmount);
            engine.payout = payout;
            engine.type = type;
            engine.coin = this.props.coin;
            engine.betNumber = firstBetNumber;
            engine.stopOnWin = parseInt(stopOnWin);
            engine.onWin = parseInt(onWin);
            engine.onLost = parseInt(onLost);

            // Continue auto-betting if there are remaining bets
            if (this.state.betNumber > 0) {
                engine.autoStart(); // Continue betting
            } else {
                console.log("Auto-betting has finished.");
                engine.trigger.emit("stop_classic_dice");
            }
        });
    }
}

    executeAutoBet = (engine, amount, type) => {
        engine.init = true;
        engine.amount = parseFloat(amount);
        engine.type = type;
        engine.coin = this.props.coin;
        engine.autoStart();
    }

    stopAutoBetting = (firstBetNumber) => {
        if (!this._isMounted) return;
        
        this.setState({
            started: false,
            buttonText: 'Start Auto Mode',
            disabled: false,
            betNumber: firstBetNumber
        });
        this.state.engine.init = false;
    }

    error = (data) => {
        if (!this._isMounted) return;

        sendNotfication(data.message, 'info', 'top-center');

        if (data.code === 'credit') {
            this.props.setWallet(true, data.uid);
        }

        this.setState({
            disabled: false,
            ...(this.state.started && {
                started: false,
                buttonText: 'Start Auto Mode'
            })
        });
    }

    placeBet = () => {
        if (!this._isMounted) return;

        const { amount, type, payout, engine } = this.state;

        wait(100).then(() => {
            engine.game = 'high_low';
            engine.init = true;
            engine.amount = amount;
            engine.payout = payout;
            engine.type = type;
            engine.coin = this.props.coin;
            engine.start();
        });
    }

    handleChangeType = value => {
        this.setState({ type: value });
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;

        if (target.name === 'amount' || target.name === 'stopOnWin') {
            this.setState({amount:value})
            if (!isValidNumber(value)) return;
        }

        if (target.name === 'amount') {
            storage.setKey('lam', value);
        }
        else{
        this.setState({ [target.name]: value });
        }
    };

    betMode = (type) => {
        let text;

        if (type === 'auto') {
            text = 'Start Auto Mode';
        }
        else {
            text = 'Roll';
        }
        this.setState({ bet: type, buttonText: text });
    };

    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am);
    }

    setNumber = (e, num) => {
        e.preventDefault();
        this.setState({ betNumber: num });
        this.setState({ firstBetNumber: num });
    };

    render() {
        let { disabled, amount, bet, type, started, mt } = this.state;
        return (
            <>
                <Form onSubmit={(e) => this.setBet(e)}>
                    <div className={" text-center btn-group-toggle my-3 " + mt} data-toggle="buttons">
                        <label className={'' + type === 'low' ? 'btn bg-cs2 btn-xs hslb  active' : 'btn bg-cs2 btn-xs hslb '}>
                            <input disabled={disabled} onClick={e => this.handleChangeType('low')} type="radio" /> <i className="mdi mdi-arrow-down-bold font-19 align-middle" /> 500
                        </label>
                        <label className={'' + type === 'equal' ? 'btn bg-cs2 btn-xs hsmb  active': 'btn bg-cs2 btn-xs hsmb '}>
                                <input disabled={disabled} onClick={ e => this.handleChangeType('equal')} type="radio" /> <i className="mdi mdi mdi-equal font-19 align-middle" /> 
                            </label>
                        <label className={'' + type === 'high' ? 'btn bg-cs2 btn-xs hsrb  active' : 'btn bg-cs2 btn-xs hsrb '}>
                            <input disabled={disabled} onClick={e => this.handleChangeType('high')} type="radio" /> <i className="mdi mdi-arrow-up-bold font-19 align-middle" /> 500
                        </label>
                    </div>
                    <Row className={'mt-1'}>
                        <Col xl={6} md={6} sm={12}>
                            <div className="form-group mb-1">
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                            BET
                                        </span>
                                    </div>
                                    <input
                                        disabled={disabled}
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder=".."
                                        value={amount}
                                        autoComplete={"off"}
                                        onKeyUp={this.handleInputChange}
                                        onChange={this.handleInputChange}
                                        className="form-control text-left"
                                        required={true}
                                    />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={5}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                            WIN AMOUNT
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                        value={this.state.amount}
                                        // value={(forceSatoshiFormat(3 ? (this.state.amount * 3 - (this.state.amount / 2)) / 4 : 0.00000004))}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} sm={12} className="m-auto">
                            {bet === 'manual' &&
                                <div className={"form-group rev mt-2 mb-0"}>
                                    <Button variant={'btn btn-block btn-bet btn-xl mb-2'}
                                        id={'bet'}
                                        disabled={disabled}
                                        type="submit">
                                        {this.state.buttonText}
                                    </Button>
                                </div>
                            }
                        </Col>
                    </Row>
                    {bet === 'auto' &&
                        <>
                            <Row>
                                <Col xl={6} md={6} sm={12}>
                                    <div className={"form-group mb-1 bet-input"}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    Bets Num
                                                </span>
                                            </div>
                                            <input
                                                type="number"
                                                disabled={disabled}
                                                className="form-control"
                                                required={true}
                                                value={this.state.betNumber}
                                                onChange={e => this.setNumber(e, e.target.value)} />
                                            <div className="input-group-append">
                                                <button onClick={e => this.setNumber(e, 10)} className={'btn btn-s-4 btn-xs'}>10</button>
                                                <button onClick={e => this.setNumber(e, 50)} className={'btn btn-s-4 btn-xs'}>50</button>
                                                <button onClick={e => this.setNumber(e, 100)} className={'btn btn-s-4 btn-xs'}>100</button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} md={6} sm={12}>
                                    <div className={"form-group mb-1 bet-input"}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    Increase Amount On Win
                                                </span>
                                            </div>
                                            <input
                                                disabled={disabled}
                                                type="number"
                                                className="form-control"
                                                name="onWin"
                                                min="0"
                                                max="100"
                                                autoComplete={"off"}
                                                value={this.state.onWin}
                                                onChange={e => this.setState({ onWin: e.target.value })} />
                                            <div className="input-group-append">
                                                <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} md={6} sm={12}>
                                    <div className={"form-group mb-1 bet-input"}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    Increase Amount On Lost
                                                </span>
                                            </div>
                                            <input
                                                disabled={disabled}
                                                type="number"
                                                className="form-control"
                                                name="onLost"
                                                min="0"
                                                max="100"
                                                autoComplete={"off"}
                                                value={this.state.onLost}
                                                onChange={e => this.setState({ onLost: e.target.value })} />
                                            <div className="input-group-append">
                                                <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} md={6} sm={12}>
                                    <div className={"form-group mb-1 bet-input"}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    Stop On Win
                                                </span>
                                            </div>
                                            <input
                                                disabled={disabled}
                                                type="text"
                                                placeholder=".."
                                                className="form-control"
                                                name="stopOnWin"
                                                value={this.state.stopOnWin}
                                                autoComplete={"off"}
                                                onChange={this.handleInputChange} />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-2 mr-1'} alt="" />
                                                    {this.props.coin}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5} className={'m-auto'}>
                                    <div className={"form-group mt-0 mb-0 rev"}>
                                        <Button variant={'btn btn-md btn-block my-2 btn-bet'}
                                            type="submit">
                                            {this.state.buttonText}
                                            {started &&
                                                <><div className={'ml-2 spinner-border spinner-border-sm'} /></>
                                            }
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </Form>
                <div className="btn-group btn-group-toggle mb-1 ml-1" data-toggle="buttons">
                    <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                        <input disabled={disabled} onClick={e => this.betMode('manual')} type="radio" /> Manual
                    </label>
                    <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                        <input disabled={disabled} onClick={e => this.betMode('auto')} type="radio" /> Auto
                    </label>
                </div>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
