import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Card} from "react-bootstrap";
import C from "../../Constant";
import {__, encode, decode, wait, Event, getGameID} from "../../Helper";
import socket from "../../Socket";
import storage from "../../Storage";
import {gameCoin} from "../../actions/gameCoin";

class Frame extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            started: false,
            message: null,
            height: 505,
            token: storage.getKey('token'),
            game: this.props.game,
            url: null,
            disabled: true,
            loader: false
        }
    }

    componentDidMount(){
        this._isMounted = true;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
        this.props.gameCoin();
        
        socket.on(C.PLAT_SLOTS, (data) => this.play(decode(data)));

        Event.on('coin_changed', () => this.restartGame());
        Event.on('game_changed', () => this.checkAndStart());

        if(this.state.token !== null)
            this.setState({ disabled: false });
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    checkAndStart(){
        this.setState({ started: false });
    }

    restartGame = () => {
        if(this._isMounted)
        {
            if(this.state.started)
            {
                this.setState({ started: false, url: null });
                wait(400).then(() => {
                    this.startReal();
                })
            }
        }
    }

    startDemo = () => {
        this.setState({ started: true });

        if(this.state.game === 'rolldice'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/rolldice' })
        }

        else
        if(this.state.game === 'pachingirl'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/pachingirl' })
        }

        else
        if(this.state.game === 'europeanroulette'){
            this.setState({ url: 'https://demo.evoplay.games/demo/table/html5/evoplay/europeanroulette' })
        }

        else
        if(this.state.game === 'mysteriesoftheeast'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/mysteriesoftheeast' })
        }

        else
        if(this.state.game === 'americanroulette'){
            this.setState({ url: 'https://demo.evoplay.games/demo/table/html5/evoplay/americanroulette' })
        }

        else
        if(this.state.game === 'redqueen'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/threecards' })
        }

        else
        if(this.state.game === 'texasholdempoker3d'){
            this.setState({ url: 'https://demo.evoplay.games/demo/table/html5/evoplay/texasholdempoker3d' })
        }

        else
        if(this.state.game === 'baccarat'){
            this.setState({ url: 'https://demo.evoplay.games/demo/table/html5/evoplay/baccarat' })
        }
        
        else
        if(this.state.game === 'frenchrouletteclassic'){
            this.setState({ url: 'https://demo.evoplay.games/demo/table/html5/evoplay/frenchrouletteclassic' })
        }
        
        else
        if(this.state.game === 'blackjack'){
            this.setState({ url: 'https://demo.evoplay.games/demo/table/html5/evoplay/blackjack' })
        }

        else
        if(this.state.game === 'moreorless'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/moreorless' })
        }

        else
        if(this.state.game === 'highstriker'){
            this.setState({ url: 'https://demo.evoplay.games/demo/socketgames/evoplay/highstriker' })
        }

        else
        if(this.state.game === 'thimbles'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/thimbles' })
        }

        else
        if(this.state.game === 'bombsquad'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/bombsquad' })
        }

        else
        if(this.state.game === 'magicwheel'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/magicwheel' })
        }
        
        else
        if(this.state.game === 'minefield'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/minefield' })
        }
        
        else
        if(this.state.game === 'rockpaperscissors'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/rockpaperscissors' })
        }
        
        else
        if(this.state.game === 'indianpoker'){
            this.setState({ url: 'https://demo.evoplay.games/demo/table/html5/evoplay/indianpoker' })
        }
        
        else
        if(this.state.game === 'unlimitedwishes'){
            this.setState({ url: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/unlimitedwishes' })
        }
        
        else
        if(this.state.game === 'etraces'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/etraces' })
        }
        
        else
        if(this.state.game === 'sindbad'){
            this.setState({ url: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/sindbad' })
        }
        
        else
        if(this.state.game === 'clashofpirates'){
            this.setState({ url: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/clashofpirates' })
        }
        
        else
        if(this.state.game === 'wheeloftime'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/wheeloftime' })
        }
        
        else
        if(this.state.game === 'triptothefuture'){
            this.setState({ url: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/triptothefuture' })
        }
        
        else
        if(this.state.game === 'crownanchor'){
            this.setState({ url: 'https://demo.evoplay.games/demo/instant/html5/evoplay/crownandanchor' })
        }
        else {
            this.setState({ url: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/' + this.state.game })
        }
    }

    startReal = () => {
        if(this.state.token !== null)
        {
            if(__.lowerCase(this.props.coin) === 'nexo'){
                return this.setState({ message: "NEXO is disabled in the slot games."})
            }
            
            if(__.lowerCase(this.props.coin) === 'nc'){
                return this.setState({ message: "NC is the test coin and can't work on the slot games."})
            }
            
            this.setState({ loader: true, disabled: true });

            this.setState({ message: null })

            socket.emit(C.PLAT_SLOTS, encode({ game: getGameID(this.state.game), coin: this.props.coin }))
        }
    }

    play = (link) => {
        this.setState({ loader: false, disabled: false, started: true, url : link })
    }

    render() {

        const background = '/assets/images/slots/' + this.state.game + '.jpg';

        return (
            <>
                {this.state.started ?
                    <iframe className={'fullScreen'} src={this.state.url}></iframe>
                    :
                    <div style={{ background: `url(${background}) center center / 100% no-repeat` }} className="sc-jEUCeg bSWQnZ">
                        <div className="mask">
                            <div className="tips">
                                <p className="ftw">
                                    Entering a third-party game. 
                                    The balance of the selected currency will be displayed in USD, and if you change currency while playing, the game will refresh and restart.
                                    <br/>
                                    {this.state.message}
                                </p>
                                <div className="btn-wrap">
                                    <button className="sc-kEjbxe sc-crrsfI cLOWRl jcNNmq btn btn-normal" disabled={this.state.disabled} onClick={this.startReal}>
                                        <div className="button-inner">
                                          {this.state.loader ? 
                                            <>
                                                <div class="spinner-border spinner-border-sm text-white mr-1" role="status" />
                                            </>
                                            :
                                            <>
                                                <i className="mdi mdi-play-circle-outline mr-1 align-middle" />
                                           </>
                                          }
                                          Real Play
                                        </div>
                                    </button>
                                    <button className="sc-kEjbxe sc-iqHYGH cLOWRl fRWXki btn btn-normal" onClick={this.startDemo}>
                                        <div className="button-inner">
                                              <i className="mdi mdi-play-circle-outline mr-1 align-middle" />
                                              Free Play
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

Frame.propTypes = {
    coin: PropTypes.string.isRequired,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin })(Frame);
