import Card from "./Card";

function Deck() {
    var ranks = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K"],
        suits = ["&#9824;", "&#9827;", "&#9829;", "&#9670;"],
        deck = [],
        i,
        x,
        card;

    this.getDeck = function () {
        return this.setDeck();
    };

    this.setDeck = function () {
        for (i = 0; i < ranks.length; i++) {
            for (x = 0; x < suits.length; x++) {
                card = new Card({ rank: ranks[i] });

                deck.push({
                    rank: ranks[i],
                    suit: suits[x],
                    value: card.getValue(),
                });
            }
        }

        return deck;
    };
}

export default Deck;
