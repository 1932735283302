import * as PIXI from "pixi.js";
import {Spine} from 'pixi-spine';
import MyEmitter from "events";
import Dice from "../Dice/index.js";
import Sprite from "../Sprite/index.js";
import Graphic from "../Graphic/index.js";
import Text from "../Text/index.js";
import Message from "../Text/Message.js";
import { wait, rand } from "../Helper/index.js";
import {Event, playAudio, forceSatoshiFormat, __} from "../../../../../Helper";

function Game(engine) {
    let self = this;
	
    self.engine = engine;
    self.app = null;
	self.ticker = null;
    self.container = null;
	self.objects = {};
	self.started = false;
	self.currentLand = 89;
	self.oldLand = 89
	self.dice = 0;
	self.lost = false;
	self.event = new MyEmitter();

	self.init = function () {
        self.app = new PIXI.Application({
            view: document.getElementById("game"),
            roundPixels: false,
            width: 610,
            height: 600,
			transparent: true,
			antialias: true,
			resolution: window.devicePixelRatio
        });

		self.app.loader
		    .add('symbol1', 'background.png')
		    .add('symbol2', 'background2.png')
		    .add('symbol3', 'trap.png')
		    .add('symbol4', 'flag.png')
		    .add('symbol5', 'snake.png')
		    .add('symbol6', 'snake2.png')
		    .add('symbol7', 'ladder.png')
		    .add('island', '/assets/spin/bg.json')
		    .add('player', '/assets/spin/boy.json')
		
		//Ticker
		self.ticker = new PIXI.Ticker();

		self.app.loader.onProgress.add(self.showProgress);
		self.app.loader.onComplete.add(self.load);
		self.app.loader.load();
    }

    self.showProgress = function (e) {
	    document.getElementById('loading').innerText = parseFloat(e.progress).toFixed(0)

	    if(e.progress > 99)
	    	document.getElementById('loading').remove();
    }

	self.load = function(loader, res){

		self.app.sortableChildren = true
		
        self.container = new PIXI.Container();
		self.container.sortableChildren = true;
        self.app.stage.addChild(self.container);
		
		self.width  = self.app.screen.width;
		self.height = self.app.screen.height;
		
		//Add Lands
		self.objects[ 'land_container'] = new PIXI.Container();
		
        let id = 0;
		let c = 2;
        for (var i = 0; i < 10; i++) {
            for (var j = 0; j < 9; j++) {
								
				
                let x = 55 + j * 60;
                let y = i * 60;
				
				let name = 'land' + id
				
				let bg;
				
				if(c === 2){
					bg = 'background2.png'
					c = 1;
				}
				else if(c === 1){
					bg = 'background.png'
					c = 2;
				}
				
				let w = 60;
				let h = 60
				
				self.objects[ name ] = new Sprite(bg, {x, y, width: w, height: h, anchor: true})
				
				self.objects[ 'text' + id ] = new Text(id, {size: 32, family: 'Arial', color: '#777'});
				self.objects[ name ].object.addChild(self.objects[ 'text' + id ].object)
				
				//Trap
				if(id === 22 || id == 45 || id === 80 || id === 11 || id === 50 || id === 84) {
					self.objects[ 'trap' + id ] = new Sprite('trap.png', {width: 70, height: 70, anchor: true })
					self.objects[ 'trap' + id ].object.alpha = .7
					self.objects[ 'text' + id ].object.visible = false;
					self.objects[ name ].object.addChild(self.objects[ 'trap' + id ].object)
				}
				
				//Finish
				if(id === 9) {
					self.objects[ 'flag' + id ] = new Sprite('flag.png', {width: 250, height: 280, x: -5, y: -40, anchor: true})
					const displacementSprite = PIXI.Sprite.from('./assets/images/snake/displacement_map_repeat.jpg');
					displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
					const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
					displacementFilter.padding = 10;
					self.objects[ 'text' + id ].object.visible = false;

					displacementSprite.position = self.objects[ 'flag' + id ].object.position;
					self.container.addChild(displacementSprite);
					self.objects[ 'flag' + id ].object.filters = [displacementFilter];
					self.objects[ 'flag' + id ].animate((item, delta) => {
						displacementSprite.x++;
						if (displacementSprite.x > displacementSprite.width) { displacementSprite.x = 0; }
					})
					self.objects[ name ].object.addChild(self.objects[ 'land' + id ].object, self.objects[ 'flag' + id ].object)
				}
				
				self.objects[ 'land_container'].addChild(self.objects[ name ].object);
				self.objects['land_container'].removeChild(self.objects['land' + j].object)
                id++;
            }
        }
		
		self.container.addChild(self.objects[ 'land_container']);
		
		//Add Snake's
		
		// Land 66
		var options = {
			x: 170,
			y: self.height - 115,
			width: 150,
			height: 150,
			anchor: true
		}
		self.objects[ 'snake1' ] = new Sprite('snake.png', options)
		self.objects[ 'snake1'].object.alpha = .9
		
		// Land 62
		var options = {
			x: self.width - 160,
			y: self.height - 220,
			width: 150,
			height: 150,
			rotation: 0.8,
			anchor: true
		}
		self.objects[ 'snake2' ] = new Sprite('snake2.png', options)
		self.objects[ 'snake2'].object.alpha = .9
		
		// Land 44
		var options = {
			x: self.width - 150,
			y: 215,
			width: 150,
			height: 150,
			rotation: 1.1,
			anchor: true
		}
		
		self.objects[ 'snake3' ] = new Sprite('snake2.png', options)
		self.objects[ 'snake3'].object.alpha = .9
		
		//land 26
		var options = {
			x: self.width,
			y: 50,
			width: 150,
			height: 150,
			rotation: 40.7,
			anchor: true
		}
		
		self.objects[ 'snake4' ] = new Sprite('snake.png', options)
		self.objects[ 'snake4'].object.alpha = .9
		
		// Land 28
		var options = {
			x: 25,
			y: self.height / 4.1,
			width: 150,
			height: 150,
			rotation: 1.5,
			anchor: true
		}
		
		self.objects[ 'snake5' ] = new Sprite('snake.png', options)
		self.objects[ 'snake5'].object.alpha = .9
		
		self.container.addChild(self.objects[ 'snake1'].object,
								self.objects[ 'snake2'].object,
								self.objects[ 'snake3'].object,
								self.objects[ 'snake4'].object,
								self.objects[ 'snake5'].object
		);
		
		//Add Ladder
		var options = {
			x: self.width / 2 + 35,
			y: self.height - 120,
			width: 25,
			height: 180,
			rotation: 14.95,
			anchor: true
		}
		self.objects[ 'ladder' ] = new Sprite('ladder.png', options)
		
		var options = {
			x: 115,
			y: self.height - 235 ,
			width: 25,
			height: 180,
			rotation: 0.8,
			anchor: true
		}
		self.objects[ 'ladder2' ] = new Sprite('ladder.png', options)
		
		
		var options = {
			x: self.width - 120,
			y: 130,
			width: 25,
			height: 165,
			rotation: 11.8,
			anchor: true
		}
		self.objects[ 'ladder3' ] = new Sprite('ladder.png', options)
		
		self.container.addChild(self.objects[ 'ladder'].object, self.objects[ 'ladder2'].object, self.objects[ 'ladder3'].object);
		
		//Add Gem's
		var options = {
			x: self.objects['land12'].object.x,
			y: self.objects['land12'].object.y,
			width: 50,
			height: 50,
			anchor: true
		}
		
		self.objects[ 'gem12' ] = new Sprite('gem.png', options)
		self.objects[ 'gem12' ].object.alpha = .8
		self.objects[ 'text12' ].object.visible = false;
		
		self.objects[ 'gem12' ].animate((item, delta) => {
			item.rotation += 0.01 * delta
		})

		var options = {
			x: self.objects['land16'].object.x,
			y: self.objects['land16'].object.y,
			width: 50,
			height: 50,
			anchor: true
		}
		
		self.objects[ 'gem16' ] = new Sprite('gem2.png', options)
		self.objects[ 'gem16' ].object.alpha = .8
		self.objects[ 'text16' ].object.visible = false;
		
		self.objects[ 'gem16' ].animate((item, delta) => {
			item.rotation += 0.01 * delta
		})
		
		var options = {
			x: self.objects['land31'].object.x,
			y: self.objects['land31'].object.y,
			width: 50,
			height: 50,
			anchor: true
		}
		
		self.objects[ 'gem31' ] = new Sprite('gem3.png', options)
		self.objects[ 'gem31' ].object.alpha = .8
		self.objects[ 'text31' ].object.visible = false;
		
		self.objects[ 'gem31' ].animate((item, delta) => {
			item.rotation += 0.01 * delta
		})
		
		var options = {
			x: self.objects['land64'].object.x,
			y: self.objects['land64'].object.y,
			width: 50,
			height: 50,
			anchor: true
		}
		
		self.objects[ 'gem64' ] = new Sprite('gem3.png', options)
		self.objects[ 'gem64' ].object.alpha = .8
		self.objects[ 'text64' ].object.visible = false;
		
		self.objects[ 'gem64' ].animate((item, delta) => {
			item.rotation += 0.01 * delta
		})
		
		self.container.addChild(self.objects[ 'gem12'].object,
								self.objects[ 'gem16'].object,
								self.objects[ 'gem31'].object,
								self.objects[ 'gem64'].object
		);
		
		//Spin Background
		self.addIsland(res)

		//Spin Player
		self.addPlayer(res)

		//Add Dice
		self.addDice();
	}

	self.addIsland = function(res){
		//Spin
		var island = new Spine(res.island.spineData);
		var scale = 0.28
		island.x = 0
		island.y = self.height / 4
		island.scale.x = island.scale.y = scale;
		island.sortChildren(2)
		island.zIndex =  1
		self.container.addChild(island);
		island.state.setAnimation(0, "animation", true);
	}

	self.addPlayer = function(res){
		//Add Player
		self.objects['player'] = new Spine(res.player.spineData);
		self.objects['player'].sortChildren(3)
		self.objects['player'].zIndex = 1
		self.objects['player'].scale.x = -0.1
		self.objects['player'].scale.y = 0.1
		self.objects['player'].y = 50
		self.objects['land89'].object.addChild(self.objects['player']);
		self.objects['player'].state.setAnimation(0, "idle B", true);
	}

	self.addDice = function(){
        self.dice = new Dice(self)
		self.dice.sprite.x = self.width / 2.3
		self.dice.sprite.y = self.height / 2.3
		self.dice.sprite.visible = false;
        self.container.addChild(self.dice.sprite)
	}
	
	self.showMessage = function(txt){
		const message = new Message(txt, {x: self.width / 2, y: self.height / 2});
		self.container.addChild(message.object);
		
		let done = false, count = 1
		message.animate(1500, (item, delta) => {
			if(!done){
				count -= 0.1
				item.alpha = count;
				if(item.alpha < 0){
					self.container.removeChild(message.object);
					done = true;
					message.stop();
				}
			}
		})
	}
	
	self.restart = function(){
		self.objects['player'].state.setAnimation(0, "idle B", true);
		self.objects['land89'].object.addChild(self.objects['player']);
		self.currentLand = 89;
		self.engine.currentLand = 89;
		self.oldLand = 89
		self.lost = false
	}
	
	self.play = function(target){
		self.dice.sprite.visible = true;
		
		// Roll Dice
		self.dice.Roll(target)
		
		self.event.on('start', () => {
			if(!self.started)
				self.playGame();
		})
	}

	self.playGame = function(){
		self.started = true;
		self.oldLand = self.currentLand
		self.currentLand -= self.dice.result
		self.engine.currentLand = self.dice.result;
		playAudio('dice2.mp3')
		
		wait(1500).then(() => {
			wait(1500).then(() => {
				self.dice.sprite.visible = false;
				self.movePlayer();
			})
		})
	}
	

	self.movePlayer = function(){

		self.objects['player'].state.setAnimation(0, "start rolling", false);

		wait(1000).then(() => {
			
			let move = self.oldLand - 1;
			
			let moved = 0
			
			var timer = setInterval(function () {
				
				if(self.currentLand <= 9){
					self.objects['player'].state.setAnimation(0, "victory", false);
					playAudio('win.mp3')
					self.showMessage('Game Was Ended!')
					
                    self.engine.init = true;
                    self.engine.cashout();

					clearInterval(timer);

					wait(2000).then(() => {
						self.restart();
					})
				}

				else {
					if(moved === self.dice.result)
					{
						self.objects['player'].state.setAnimation(0, "idle A", true);
						clearInterval(timer);
						self.landEvent()
						self.started = false;
						self.engine.land = self.currentLand;
						self.engine.currentLand = self.currentLand;
						self.engine.lost = self.lost;
						self.engine.clicked();
						playAudio('dice.mp3')
					}
					else 
					{
						//Remove player from the land
						self.objects['land' + move].object.removeChild(self.objects['player']);

						self.objects['player'].state.setAnimation(0, "ollie", false);
					
						//Add player to the land
						self.objects['land' + move].object.addChild(self.objects['player']);
						
						playAudio('dice.mp3')
					}
				}
				
				move--
				moved++
			}, 500);
			
			
			//Reset the position caused by ladder
			self.objects['player'].y = 50
			self.objects['player'].x = 0
			
			self.objects['score'] = new Text('1.01x', {color: 'yellow', size: 15, y: -10});
			self.objects['player'].addChild(self.objects['score'].object)
			
			let count = 5;
			self.objects[ 'score' ].animate((item, delta) => {
				count -= 0.1
				item.zIndex = 999999
				item.alpha = count;
				item.y -= 0.5 * delta;
				
				if(item.y < -50){
					self.objects['player'].removeChild(item);
					self.objects['score'].stop();
				}
			})
			
		})
	}
	
	self.landEvent = function(){
		
		// Gems
		if(self.currentLand === 64 || self.currentLand === 31 || self.currentLand === 12 || self.currentLand === 16){
			let moved = false;

			playAudio('win2.mp3')

			self.objects[ 'gem' + self.currentLand ].animate((item, delta) => {
				item.rotation = 0;
				
				if(!moved){
					item.scale.x += .1 * delta
					item.scale.y += .1 * delta
					
					if(item.scale.x > 1.5){
						self.objects[ 'score'] = new Text('1.50x', {size: 25})
						item.addChild(self.objects[ 'score'].object);
						wait(2200).then(() => {
							if(!__.isUndefined(self.objects[ 'gem' + self.currentLand ] && !__.isUndefined(self.objects[ 'score' ]))){
								self.objects[ 'gem' + self.currentLand ].object.removeChild(self.objects[ 'score'].object);
								self.container.removeChild(self.objects[ 'gem' + self.currentLand ].object);
							}
						})
						moved = true;
					}
				}
				else {
					item.scale.x -= 0.01 * delta
					item.scale.y -= 0.01 * delta
				}
			})
		}
		
		// Ladder
		if(self.currentLand === 87)
		{
			self.currentLand = 67
			self.objects['land67'].object.addChild(self.objects['player'])
			playAudio('win.mp3')
		}
		
		// Ladder
		if(self.currentLand === 63)
		{
			self.currentLand = 47
			self.objects['land47'].object.addChild(self.objects['player'])
			playAudio('win.mp3')
		}
		
		// Ladder
		if(self.currentLand === 35)
		{
			self.currentLand = 15
			self.objects['land15'].object.addChild(self.objects['player'])
			playAudio('win.mp3')
		}
		
		// Traps
		if(self.currentLand === 80 || self.currentLand == 22 || self.currentLand === 11 || self.currentLand === 45 || self.currentLand === 84){
			self.showMessage('Lost !');
			self.objects['player'].state.setAnimation(0, "fall front", false);
			playAudio('lost.mp3')
			wait(2000).then(() => {
				self.restart();
			})
		}
		
		//Snakes
		if(self.currentLand === 66 || self.currentLand === 62 || self.currentLand === 44 || self.currentLand === 28 || self.currentLand === 26 ){
			self.showMessage('Lost !');
			self.objects['player'].state.setAnimation(0, "hit obstacle", false);
			playAudio('lost.mp3')
			wait(2000).then(() => {
				self.restart();
			})
		}
	}
	
}

export default Game;
