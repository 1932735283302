import React from 'react';
import styled from 'styled-components';

const coinData = [
  { currency: 'BTC', accuracyLimit: '0.000000001' },
  { currency: 'NANO', accuracyLimit: '0.000000001' },
  { currency: 'NND', accuracyLimit: '0.000001' },
  { currency: 'ETH', accuracyLimit: '0.000000001' },
  { currency: 'USDT', accuracyLimit: '0.000001' },
  { currency: 'TRX', accuracyLimit: '0.000001' },
  { currency: 'DOGE', accuracyLimit: '0.00000001' },
  { currency: 'LTC', accuracyLimit: '0.00000001' },
  { currency: 'BNB', accuracyLimit: '0.000000001' },
  { currency: 'NNL', accuracyLimit: '0.000001' },
  { currency: 'BANANO', accuracyLimit: '0.000000001' },
  { currency: 'CUB', accuracyLimit: '0.000000001' },
];

const Container = styled.div`
  background-color: #31343C;
  padding: 24px;
  border-radius: 20px;
  min-height:100vh;
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Description = styled.p`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
//   background-color: #2A2C32;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #36393f;
    border-radius:10px;
  }
  &:nth-child(odd) {
    background-color: #2A2C32;
     border-radius:10px;
  }
`;

const TableRowNew = styled.tr`
 
`;


const TableHeader = styled.th`
  color: #b9bbbe;
  font-weight: 600;
  text-align: left;
    border-radius:10px;
  padding: 12px;
`;

const TableCell = styled.td`
  color: #ffffff;
  padding: 12px;
//   border-radius:10px;
`;

const CoinAccuracyLimit = () => {
  return (
    <Container>
      <Title>Coin accuracy limit</Title>
      <Description>
        Due to the limitation of data storage accuracy, changes in the amount less than a certain amount will not take effect. The
        minimum accuracy of most coins is consistent with the currency accuracy of the blockchain. For example, the minimum
        unit of SATS is 1. The minimum data storage accuracy of each coin is shown in the following table:
      </Description>
      <Table>
        <TableHead>
          <TableRowNew>
            <TableHeader>Currency</TableHeader>
            <TableHeader>Accuracy Limit</TableHeader>
          </TableRowNew>
        </TableHead>
        <tbody>
          {coinData.map((coin, index) => (
            <TableRow key={coin.currency}>
              <TableCell>{coin.currency}</TableCell>
              <TableCell>{coin.accuracyLimit}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default CoinAccuracyLimit;