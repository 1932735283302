/* eslint-disable */
import jQuery from 'jquery';
import Deck from "./Deck";
import Shuffle from "./Shuffle";
import Card from "./Card";
import { setActions, getWinner } from "./Game";
import GameObj from "./Object";
import { playAudio } from "../../../../Helper";
var $ = jQuery.noConflict();

function isMob() {
    let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    return clientWidth < 992
}

function Deal(dealer) {
    var deck = new Deck(),
        shuffle = new Shuffle(deck),
        shuffled = shuffle.getShuffle(),
        card;

    GameObj['dealer'] = dealer;

    this.getCard = function (sender) {
        this.setCard(sender);
        return card;
    };

    this.setCard = function (sender) {
        card = shuffled[0];
        shuffled.splice(card, 1);
        sender.setHand(card);
    };

    this.dealCard = function (num, i, obj) {
        if (i >= num) {
            return false;
        }

        playAudio('card3.mp3');

        var sender = obj[i],
            elements = obj[i].getElements(),
            score = elements.score,
            ele = elements.ele,
            dhand = GameObj['dealer'].getHand();

        GameObj['deal'].getCard(sender);

        if (i < 3) {
            renderCard(ele, sender, "up");
            $(score).html(sender.getScore());
        } else {
            renderCard(ele, sender, "down");
        }

        if (GameObj['player'].getHand().length < 3) {
            if (dhand.length > 0 && dhand[0].rank === "A") {
                setActions("insurance");
            }

            if (GameObj['player'].getScore() === 21) {
                if (!GameObj['blackjack']) {
                    GameObj['blackjack'] = true;
                    getWinner();
                } else {
                    GameObj['dealer'].flipCards();
                    $("#dscore span").html(GameObj['dealer'].getScore());
                }
            } else {
                if (dhand.length > 1) {
                    setActions("run");
                }
            }
        }

        function showCards() {
            setTimeout(function () {
                GameObj['deal'].dealCard(num, i + 1, obj);
            }, 500);
        }

        clearTimeout(showCards());
    };
}

export function renderCard(ele, sender, type, item) {
    var hand, i, card;

    if (!item) {
        hand = sender.getHand();
        i = hand.length - 1;
        card = new Card(hand[i]);
    } else {
        hand = GameObj['dealer'].getHand();
        card = new Card(hand[1]);
    }

    var rank = card.getRank(),
        suit = card.getSuit(),
        color = "red",
        posx = 40,
        posy = 265,
        speed = 200,
        cards = ele + " .card-" + i;

    if (isMob()) {
        posx = 402;
    }

    if (i > 0) {
        if (isMob()) {
            posx -= 30 * i;
        }
        else
            posx -= 4 * i;
    }

    let prefix = '%';

    if (isMob()) {
        prefix = 'px';
    }

    if (!item) {
        $(ele).append(
            '<div class="card-' +
            i +
            " " +
            type +
            '">' +
            '<span class="pos-0">' +
            '<span class="rank">&nbsp;</span>' +
            '<span class="suit">&nbsp;</span>' +
            "</span>" +
            '<span class="pos-1">' +
            '<span class="rank">&nbsp;</span>' +
            '<span class="suit">&nbsp;</span>' +
            "</span>" +
            "</div>"
        );

        if (ele === "#phand") {
            speed = 500;

            if (isMob())
                posy = 230;
            else
                posy = 340;

            $(ele + " div.card-" + i).attr("id", "pcard-" + i);

            if (hand.length < 2) {
                $("#a").html("You Have " + GameObj['player'].getScore());

                setTimeout(function () {
                    $("#pcard-0 .popover").css("display", "none").fadeIn();
                }, 500);
            }
        } else {
            $(ele + " div.card-" + i).attr("id", "dcard-" + i);
            if (hand.length < 2) {
                $("#b").html("Dealer Has " + GameObj['dealer'].getScore());
                setTimeout(function () {
                    $("#dcard-0 .popover").fadeIn();
                }, 100);
            }
        }

        $(ele + " .card-" + i).css("z-index", i);

        $(ele + " .card-" + i).animate(
            {
                top: posy,
                right: posx + prefix,
            },
            speed
        );

        $(ele).queue(function () {
            $(this).animate({ left: '-=25.5px' }, 100);
            $(this).dequeue();
        });
    } else {
        cards = item;
    }

    if (type === "up" || item) {
        if (suit !== "&#9829;" && suit !== "&#9670;") {
            color = "black";
        }

        $(cards).find('span[class*="pos"]').addClass(color);
        $(cards).find("span.rank").html(rank);
        $(cards).find("span.suit").html(suit);
    }
}

export default Deal;
