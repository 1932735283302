const coins = [
  {
    id: 18,
    name: "Polygon Matic",
    preffix: "MATIC",
    image: "POLYGON.png",
    min: "10",
    active: true,
    test: false,
    network: "MATIC",
  },
  {
    id: 19,
    name: "Shiba Inu",
    preffix: "SHIB",
    image: "SHIB.png",
    min: "1000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 2,
    name: "Indian Rupees",
    preffix: "INR",
    image: "INR.png",
    min: "1000",
    active: true,
    test: false,
    network: "UPI",
  },
  {
    id: 16,
    name: "Binance USD",
    preffix: "BUSD",
    image: "BUSD.png",
    min: "10.00000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 15,
    name: "USDC",
    preffix: "USDC",
    image: "USDC.png",
    min: "10.00000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 14,
    name: "TrueUSD",
    preffix: "TUSD",
    image: "TUSD.png",
    min: "10.00000000",
    active: true,
    test: false,
    network: "ERC-20",
  },
  //   {
  //     id: 13,
  //     name: "Maker",
  //     preffix: "MKR",
  //     image: "mkr.png",
  //     min: "0.00200000",
  //     active: true,
  //     test: false,
  //     network: "ERC-20",
  //   },
  {
    id: 12,
    name: "NEXO",
    preffix: "NEXO",
    image: "NEXO.png",
    min: "10.000000",
    active: true,
    test: false,
    network: "ERC-20",
  },
  //   {
  //     id: 11,
  //     name: "Pax USD",
  //     preffix: "USDP",
  //     image: "usdp.png",
  //     min: "1.00000000",
  //     active: true,
  //     test: false,
  //     network: "ERC-20",
  //   },
  {
    id: 10,
    name: "Binance",
    preffix: "BNB",
    image: "BNB.png",
    min: "0.03",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 9,
    name: "Ripple",
    preffix: "XRP",
    image: "XRP.png",
    min: "25.00000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 8,
    name: "Cardano",
    preffix: "ADA",
    image: "ADA.png",
    min: "25.00000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 7,
    name: "Dogecoin",
    preffix: "DOGE",
    image: "DOGE.png",
    min: "120.00000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 6,
    name: "TRON",
    preffix: "TRX",
    image: "TRON.png",
    min: "140.00000",
    active: true,
    test: false,
    network: "TRX",
  },
  {
    id: 3,
    name: "Tether",
    preffix: "USDT",
    image: "TETHER.png",
    min: "5.00000000",
    active: true,
    test: false,
    network: "TRC-20",
  },
  {
    id: 20,
    name: "Bitcoin Cash",
    preffix: "BCH",
    image: "BCH.png",
    min: "0.750000",
    active: true,
    test: false,
    network: "BCH",
  },
  {
    id: 5,
    name: "Litcoin",
    preffix: "LTC",
    image: "LTC.png",
    min: "0.2000",
    active: true,
    test: false,
    network: "LTC",
  },
  {
    id: 4,
    name: "Ethereum",
    preffix: "ETH",
    image: "ETH.png",
    min: "0.0050000",
    active: true,
    test: false,
    network: "ETH",
  },
  {
    id: 1,
    name: "Bitcoin",
    preffix: "BTC",
    image: "BTC.png",
    min: "0.00008000",
    active: true,
    test: false,
    network: "BTC",
  },
  // {
  //   id: 17,
  //   name: "Bidwinx",
  //   preffix: "BRX",
  //   image: "brx.png",
  //   min: "220",
  //   active: true,
  //   test: false,
  //   network: "BSC",
  // },
];

export default coins;
