import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GsCard from './BannerCard';
import GsCard1 from './BannerCard2';

const MainContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  background-color: rgb(25, 27, 30);
  color: white;
  padding: 12px;
  border-radius: 8px;
`;

// Mobile Slider Styles
const MobileContainer = styled(Container)`
  @media (min-width: 598px) {
    display: none;
  }
`;

const FixedHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeaderIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ColoredText = styled.span`
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: ${props => props.$color};
`;

const SliderWrapper = styled.div`
  overflow: hidden;
`;

const SliderTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-${props => Math.floor(props.$currentSlide / 2) * 105}%);
  width: ${props => (props.$totalSlides / 2) * 110}%;
`;

const SlideGroup = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  gap: 6px;
`;

const CardContainer = styled.div`
  flex: 1;
`;

// Desktop Grid Styles
const DesktopContainer = styled(Container)`
  display: none;
  @media (min-width: 598px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;



const GSContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const ScrollWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(${props => props.translateX}%);
  width: ${props => props.totalWidth}%;
`;

const CardGroup = styled.div`
  display: flex;
  gap: 16px;
  min-width: 100%;
  padding: 0 16px;
`;

const GSCard = styled.div`
  flex: 1;
  border-radius: 10px;
  padding: 16px;
  background-color: transparent;
`;

const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

const GSIconPlaceholder = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 8px;
  margin-right: 12px;
  object-fit: cover;
`;

const GSTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const OptionalText = styled.span`
  color: #888;
  font-size: 12px;
  font-family: Arial, sans-serif;
  margin-bottom: 2px;
`;

const GSProfitText = styled.span`
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: ${props => props.color || 'white'};
  margin-bottom: 2px;
`;

const GSDescriptionText = styled.span`
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #888;
`;

const HorizontalGsCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cardData = [
    {
      iconSrc: '/assets/images/nano/gs1.webp',
      optionalText: 'Hidden',
      profitText: 'Won $48664.27',
      profitColor: '#5DDB1C',
      descriptionText: 'In Plinko',
    },
    {
      optionalText: 'Hidden',
      iconSrc: '/assets/images/nano/gs2.webp',
      profitText: 'Profit: $432.47',
      descriptionText: 'In Mines',
    },
    {
      iconSrc: '/assets/images/nano/gs1.webp',
      optionalText: 'Hidden',
      profitText: 'Won $48664.27',
      profitColor: '#5DDB1C',
      descriptionText: 'In Plinko',
    },
    {
      optionalText: 'Hidden',
      iconSrc: '/assets/images/nano/gs2.webp',
      profitText: 'Profit: $432.47',
      descriptionText: 'In Mines',
    },
    {
      iconSrc: '/assets/images/nano/gs1.webp',
      optionalText: 'Hidden',
      profitText: 'Won $48664.27',
      profitColor: '#5DDB1C',
      descriptionText: 'In Plinko',
    },
    {
      optionalText: 'Hidden',
      iconSrc: '/assets/images/nano/gs2.webp',
      profitText: 'Profit: $432.47',
      descriptionText: 'In Mines',
    },
  ];

  // Group cards into pairs
  const groupedCards = [];
  for (let i = 0; i < cardData.length; i += 2) {
    groupedCards.push(cardData.slice(i, i + 2));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        (prevIndex + 1) % groupedCards.length
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [groupedCards.length]);

  const renderCard = (card) => (
   <div style={{display:"flex",gap:"2px"}}>
    <GSCard>
      <CardContent>
        <GSIconPlaceholder src={card.iconSrc} alt="" />
        <GSTextContainer>
          {card.optionalText && (
            <OptionalText>{card.optionalText}</OptionalText>
          )}
          <GSProfitText color={card.profitColor}>
            {card.profitText}
          </GSProfitText>
          <GSDescriptionText>{card.descriptionText}</GSDescriptionText>
        </GSTextContainer>
      </CardContent>
    </GSCard>
    <GSCard>
      <CardContent>
        <GSIconPlaceholder src={card.iconSrc} alt="" />
        <GSTextContainer>
          {card.optionalText && (
            <OptionalText>{card.optionalText}</OptionalText>
          )}
          <GSProfitText color={card.profitColor}>
            {card.profitText}
          </GSProfitText>
          <GSDescriptionText>{card.descriptionText}</GSDescriptionText>
        </GSTextContainer>
      </CardContent>
    </GSCard>
   </div>
  );

  return (
    <GSContainer>
      <ScrollWrapper 
        translateX={-currentIndex * 100}
        totalWidth={(groupedCards.length + 1) * 160}
      >
        {[...groupedCards, groupedCards[0]].map((group, groupIndex) => (
          <CardGroup key={groupIndex}>
            {group.map((card, index) => (
              <React.Fragment key={index}>
                {renderCard(card)}
              </React.Fragment>
            ))}
          </CardGroup>
        ))}
      </ScrollWrapper>
    </GSContainer>
  );
};

const ResponsiveBannerGames = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const items = [
    {
      component: 'GsCard',
      group: 1
    },
    {
      component: 'GsCard1',
      group: 1
    },
    {
      component: 'GsCard',
      group: 2
    },
    {
      component: 'GsCard1',
      group: 2
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 2) % items.length);
    }, 4000);

    return () => clearInterval(timer);
  }, []);

  // Group items into pairs
  const groupedSlides = items.reduce((acc, item, i) => {
    if (i % 2 === 0) {
      acc.push(items.slice(i, i + 2));
    }
    return acc;
  }, []);

  return (
    <MainContainer>
      {/* Mobile Slider */}
      <MobileContainer>
        <FixedHeaderContainer>
          <HeaderItem>
            <HeaderIcon src="/assets/images/nano/bigprofit.webp" alt="" />
            <HeaderText>
              <ColoredText $color="#DA1341">Much</ColoredText>
              <ColoredText $color="white">Wow Wins</ColoredText>
            </HeaderText>
          </HeaderItem>
          <HeaderItem>
            <HeaderIcon src="/assets/images/nano/topprofit.webp" alt="" />
            <HeaderText>
              <ColoredText $color="#FFA800">Top</ColoredText>
              <ColoredText $color="white">Winning Games</ColoredText>
            </HeaderText>
          </HeaderItem>
        </FixedHeaderContainer>

        <SliderWrapper>
          <SliderTrack 
            $currentSlide={currentSlide} 
            $totalSlides={items.length}
          >
            {groupedSlides.map((group, index) => (
              <SlideGroup key={index}>
                {group.map((item, i) => (
                  <CardContainer key={i}>
                   <HorizontalGsCard/>
                  </CardContainer>
                ))}
              </SlideGroup>
            ))}
          </SliderTrack>
        </SliderWrapper>
      </MobileContainer>

      {/* Desktop Grid */}
      <DesktopContainer>
        {items.map((item, index) => (
          <div key={index}>
            {item.component === 'GsCard' ? <GsCard /> : <GsCard1 />}
          </div>
        ))}
      </DesktopContainer>
    </MainContainer>
  );
};

export default ResponsiveBannerGames;