import React from 'react';
import styled from 'styled-components';

const data = [
    { level: 'VIP 01', xpAmount: 1, xpProgress: '0 XP\n0%', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 02', xpAmount: 100, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 03', xpAmount: 200, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 04', xpAmount: 1000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 05', xpAmount: 2000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 06', xpAmount: 3000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
    { level: 'VIP 07', xpAmount: 4000, xpProgress: 'Not Started', star: 1, bonus: '/assets/images/box.png', iconPath: '/assets/images/starVip.png' },
];

const Table = styled.table`
  width: 100%;
  color: #ffffff;
  border-collapse: collapse;
`;

const TableContainer = styled.div`
  width: 100%;
  background-color: #1E2024;
  color: #ffffff;
  padding: 12px;
  border-radius: 12px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  font-weight: normal;
  color: #F5F6F7;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #282B30;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  color: #F5F6F7;
`;

const LevelIcon = styled.img`
  width: 14px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
`;

const ProgressBar = styled.div`
  width: 50px;
  height: 4px;
  background-color: #2e2e2e;
  border-radius: 2px;
`;

const StarContainer = styled.div`
  display: flex;
`;

const Star = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.active ? '#ffffff' : '#4e4e4e'};
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  margin-right: 2px;
`;

const BonusIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const BronzeTable = () => {
    return (
        <div>
            <div style={{margin:'24px 0'}}>VIP Level System</div>
            <TableContainer>
                <div style={{padding:'20px'}}>
                    <div style={{ color: '#F5F6F7' }}>Bronze Card</div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Level</TableHeader>
                            <TableHeader>XP Amount</TableHeader>
                            <TableHeader>XP Progress Bar</TableHeader>
                            <TableHeader>Star</TableHeader>
                            <TableHeader>Bonus</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <LevelIcon src={row.iconPath} alt={row.level} />
                                    {row.level}
                                </TableCell>
                                <TableCell>{row.xpAmount}</TableCell>
                                <TableCell>
                                    {row.xpProgress}
                                    <ProgressBar />
                                </TableCell>
                                <TableCell>
                                    <StarContainer>
                                        {['/assets/images/starVip.png', '/assets/images/starVip.png', '/assets/images/starVip.png', '/assets/images/starVip.png', '/assets/images/starVip.png'].map((_, i) => (
                                            <LevelIcon key={i} src={row.iconPath} alt={row.level} />
                                        ))}
                                    </StarContainer>
                                </TableCell>
                                <TableCell>
                                    <BonusIcon src={row.bonus} alt={row.bonus} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default BronzeTable;