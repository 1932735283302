import * as PIXI from "pixi.js";
import { wait } from "../Helper/index.js";

class Sprite {
	constructor(image, opt = {}){
		this.image = image
		this.opt = opt
		this.object = null
		this.ticker = null
		
		this.show();
	}
	
	show(){
		const sprite = PIXI.Sprite.from('/assets/images/snake/' + this.image);
		
		if(this.opt.x && this.opt.y){
			sprite.x = this.opt.x
			sprite.y = this.opt.y
		}
		
		if(this.opt.width && this.opt.height){
			sprite.width = this.opt.width
			sprite.height =this.opt.height
		}
		
		if(this.opt.rotation){
			sprite.rotation = this.opt.rotation
		}
		
		if(this.opt.button){
			sprite.interactive = true;
			sprite.buttonMode = true;
		}
		
		if(this.opt.active){
			sprite.interactive = true;
		}
		
		sprite.anchor.set(0.5)
		
		let index = this.opt.index ? this.opt.index : 1;
			
		sprite.sortChildren(index)
		sprite.zIndex = index
		sprite.type = 'sprite'
		sprite.name = this.image;
		
		this.object = sprite
	}

	animate(delay, callback){
		this.ticker = new PIXI.Ticker();
		
		let self = this;
		
		if(typeof delay === 'function'){
			callback = delay;
			delay = 0;
		}
		
		var c = function(delta){
			return callback(self.object, delta);
		}
		
		wait(delay).then(() => {
			this.ticker.add(c)
			this.ticker.start();
		})
	}
	
	stop(){
		if(this.ticker !== null)
			this.ticker.stop();
	}
}

export default Sprite;
