import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import socket from "../../../Socket";
import storage from "../../../Storage";
import { decode, encode, getElement, sendNotfication, isEmail, Event, DEVELOPMENT, wait, randomString } from "../../../Helper";
import C from "../../../Constant";
import axios from 'axios';
import * as countriesData from 'countries-list';
import Select from 'react-select';
const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #1e2124;
    color: white;
    border-radius: 18px;
    overflow: hidden;
  }
`;

const ModalHeader = styled.div`
  background-color: #43B30B;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #2f7d08;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const ModalBody = styled(Modal.Body)`
  padding: 20px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#2b2f33',
    borderColor: '#3a3f44',
    borderRadius: '12px',
    color: 'white', // Set the color of the selected value to white
    padding: '14px',
    minHeight: '26px',
    '&:hover': {
      borderColor: '#43B30B',
    },
    '&:focus': {
      outline: 'none',
      borderColor: '#43B30B',
      boxShadow: '0 0 0 2px rgba(67, 179, 11, 0.2)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9a9a9a', // Set the placeholder text color to a light gray
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#2b2f33',
    borderRadius: '12px',
    borderColor: '#3a3f44',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#43B30B' : '#2b2f33',
    color: 'white', // Set the text color of the options to white
    borderRadius: '8px',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#43B30B',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white', // Set the color of the selected value to white
  }),
};
const StyledInput = styled.input`
  background-color: #2b2f33;
  border: 1px solid #3a3f44;
  border-radius: 12px;
  color: white;
  padding: 14px;
  &::placeholder {
    color: #6c757d;
  }
  &:focus {
    outline: none;
    border-color: #43B30B;
    box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
  }
    /* Hide number input spinners */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Labeltext = styled.div`
  color: #696F79;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;
const Button = styled.button`
  padding: 20px 30px;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  /* Medium screens */
  @media (max-width: 768px) {
    padding: 15px 20px;
    font-size: 14px;
  }

  /* Small screens */
  @media (max-width: 480px) {
    padding: 8px 12px;
    font-size: 12px;
    border-radius: 20px;
  }
`;

const SignInButton = styled(Button)`
  background-color: #8b5cf6;
  color: white;
  border: none;
  width: 100%;
  max-width: 200px;

  /* Medium screens */
  @media (max-width: 768px) {
    max-width: 130px;
  }

  /* Small screens */
  @media (max-width: 480px) {
    max-width: 100px;
    padding: 6px 10px;
    font-size: 10px;
  }

  &:hover {
    background-color: #7c3aed;
  }
`;

const SignUpButton = styled(Button)`
  background-color: #30353C;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 120px;

  /* Medium screens */
  @media (max-width: 768px) {
    max-width: 100px;
  }

  /* Small screens */
  @media (max-width: 480px) {
    max-width: 80px;
    padding: 6px 10px;
    font-size: 10px;
  }

  &:hover {
    background-color: #374151;
  }
`;


const StyledSignUpButton = styled.div`
  background-color: #45B70C;
  color: white;
  border: none;
  border-radius: 500px;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;

  /* Tablet screens */
  @media (max-width: 768px) {
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 400px;
  }

  /* Mobile screens */
  @media (max-width: 480px) {
    padding: 6px 16px;
    font-size: 12px;
    border-radius: 300px;
  }
`;


class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey('logged'),
      show: this.props.show || false,
      username: '',
      email: '',
      password: '',
      phoneNumber: '',
      referral: this.props.referral || '',
      ruleChecked: false,
      submitted: false,
      disabled: false,
      disableVerifyButton: false,
      effect: 'zoomIn',
      sendingOTP: false,
      verifyingOTP: false,
      otp: '',
      encodedOTP: '',
      selectedCountry: null,
      countryOptions: Object.values(countriesData.countries).map(country => ({
        value: `+${country.phone}`,
        label: `${country.name} (+${country.phone})`
      }))
    };
  }

  componentDidMount() {
    socket.on(C.REGISTER_USER, data => this.setRegister(decode(data)));
    Event.on('register_form', this.handleShow);
  }

  setRegister = (data) => {
    if (data.error) {
      this.setState({ disabled: false, disableVerifyButton: false, submitted: false, verifyingOTP: false });
      return sendNotfication(data.error, 'error', 'bottom-left');
    }

    if (data.status) {
      console.log("Data received:", data);
      this.setState({ submitted: false, verifyingOTP: false });
      wait(1000).then(() => {
        socket.emit(C.LOGIN_USER, encode({
          username: data.name,
          password: data.password,
          recaptcha: 'google'
        }));
      });
    }
  };

  handleShow = () => {
    this.setState({ show: true, effect: 'zoomIn' });
  };

  handleClose = () => {
    this.setState({ show: false, effect: 'zoomOut', disabled: false, disableVerifyButton: false, submitted: false, ruleChecked: false, sendingOTP: false, verifyingOTP: false, otp: '', encodedOTP: '' });
  };

  verifyReferralCode = async () => {
    try {
      const response = await axios.get(`https://api.ibitplay.com/verify-referral-code/${this.state.referral}`);
      console.log("Referral response:", response);
      return response.data.valid;
    } catch (error) {
      console.error("Referral code verification error:", error);
      return false;
    }
  };

  sendOTP = async () => {
    const { email } = this.state;
    if (!isEmail(email)) {
      sendNotfication('Please enter a valid email address', 'error', 'bottom-left');
      return;
    }

    this.setState({ sendingOTP: true, disabled: true });
    try {
      const response = await axios.post('https://api.ibitplay.com/send-register-otp', { to: email });
      if (response.data.success) {
        this.setState({ encodedOTP: response.data.encodedOtp, sendingOTP: false, verifyingOTP: true, disabled: false, disableVerifyButton: false });
        sendNotfication('OTP sent to your email', 'success', 'bottom-left');
      } else {
        this.setState({ sendingOTP: false, disabled: false });
        sendNotfication('Failed to send OTP', 'error', 'bottom-left');
      }
    } catch (error) {
      this.setState({ sendingOTP: false, disabled: false });
      console.error("Error sending OTP:", error);
      sendNotfication('Failed to send OTP', 'error', 'bottom-left');
    }
  };

  verifyOTP = async () => {
    const { otp, encodedOTP } = this.state;
    if (!otp) {
      sendNotfication('Please enter the OTP', 'error', 'bottom-left');
      return;
    }
    this.setState({ verifyingOTP: true, disableVerifyButton: true });
    try {
      const response = await axios.post('https://api.ibitplay.com/verify-otp', { otp, encodedOtp: encodedOTP });
      if (response.data.success) {
        const { username, password, email, referral, phoneNumber ,selectedCountry} = this.state;
       await socket.emit(C.REGISTER_USER, encode({
          username,
          password,
          email,
          method: true,
          refree: referral || '',
          phone: `${selectedCountry.value} ${phoneNumber}` || '',
          aff: storage.getKey('aff') || null
        }));
        this.setState({ verifyingOTP: false, disableVerifyButton: false });
      } else {
        this.setState({disableVerifyButton: false });
        sendNotfication('Invalid OTP', 'error', 'bottom-left');
      }
    } catch (error) {
      this.setState({ verifyingOTP: false, disableVerifyButton: false });
      console.error("Error verifying OTP:", error);
      sendNotfication('Failed to verify OTP', 'error', 'bottom-left');
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ submitted: true, disabled: true });

    const { username, email, password, ruleChecked, referral, phoneNumber } = this.state;

    if (referral && referral.trim()) {
      const verified = await this.verifyReferralCode();
      if (!verified) {
        this.setState({ disabled: false });
        sendNotfication('Please enter a valid referral code', 'error', 'bottom-left');
        return;
      }
    }

    if (!(username && password && email && ruleChecked && phoneNumber)) {
      this.setState({ disabled: false });
      return;
    }

    if (username.length < 5) {
      this.setState({ disabled: false });
      sendNotfication('Username must be at least 5 characters', 'error', 'bottom-left');
      return;
    }

    if (phoneNumber == "" || phoneNumber == null) {
      this.setState({ disabled: false });
      sendNotfication('Please enter phone number', 'error', 'bottom-left');
      return;
    }

    if (!isEmail(email)) {
      this.setState({ disabled: false });
      sendNotfication('Please enter a valid email address', 'error', 'bottom-left');
      return;
    }

    await this.sendOTP();
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkRule = () => {
    this.setState(prevState => ({ ruleChecked: !prevState.ruleChecked }));
  };

  login = () => {
    this.handleClose();
    Event.emit('login_form');
  };
  handleCountryChange = (option) => {
    this.setState({ selectedCountry: option });
  };
  render() {
    const { show, username, email, password, ruleChecked, disabled, disableVerifyButton, referral, phoneNumber, sendingOTP, verifyingOTP, otp } = this.state;

    return (
      <>
        <StyledSignUpButton onClick={this.handleShow}>
          Sign up
        </StyledSignUpButton>
        <StyledModal
          size="md"
          centered
          show={show}
          onHide={this.handleClose}
          aria-labelledby="register-modal"
          className={`animated ${this.state.effect}`}
        >
          <ModalHeader>
            <CloseButton onClick={this.handleClose}>×</CloseButton>
          </ModalHeader>
          <ModalBody>
            <div className="text-center mb-4">
              <h3>CREATE YOUR ACCOUNT</h3>
            </div>
            <StyledForm onSubmit={this.handleSubmit}>
              {!verifyingOTP && (
                <>
                  <Labeltext>Username</Labeltext>
                  <StyledInput
                    type="text"
                    name="username"
                    value={username}
                    onChange={this.handleInputChange}
                    placeholder="Username"
                  />
                  <Labeltext>Email Address</Labeltext>
                  <StyledInput
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                    placeholder="Email Address"
                  />
                  <Labeltext>Password</Labeltext>
                  <StyledInput
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.handleInputChange}
                    placeholder="Password"
                  />
                   <Labeltext>Country</Labeltext>
                  <Select
    name="selectedCountry"
    options={this.state.countryOptions}
    value={this.state.selectedCountry}
    onChange={this.handleCountryChange}
    styles={customStyles}
    className="mr-2 w-32"
    classNamePrefix="select"
    placeholder="Select Country"
  />
                  <Labeltext>Phone Number</Labeltext>
  
  <StyledInput
    type="text"
    name="phoneNumber"
    value={this.state.phoneNumber}
    onChange={this.handleInputChange}
    placeholder="Phone Number"
  />

                  <Labeltext>Referral Code (Optional)</Labeltext>
                  <StyledInput
                    name="referral"
                    value={referral}
                    onChange={this.handleInputChange}
                    placeholder="Referral Code"
                  />
                  <div>
                    <input
                      type="checkbox"
                      id="ruleCheck"
                      checked={ruleChecked}
                      onChange={this.checkRule}
                    />
                    <label htmlFor="ruleCheck" style={{ marginLeft: '10px', fontSize: '14px' }}>
                      I agree with the user agreement
                    </label>
                  </div>
                  <ButtonContainer>
                    <SignUpButton id="signupBtn" type="submit" disabled={disabled || sendingOTP}>
                      {(disabled || sendingOTP) && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
                      {sendingOTP ? 'Sending OTP' : 'Sign Up'}
                    </SignUpButton>
                    <SignInButton type="button" onClick={this.login}>Sign In</SignInButton>
                  </ButtonContainer>
                </>
              )}

              {verifyingOTP && (
                <>
                  <Labeltext>Enter OTP</Labeltext>
                  <StyledInput
                    type="text"
                    name="otp"
                    value={otp}
                    onChange={this.handleInputChange}
                    placeholder="Enter OTP"
                  />
                  <ButtonContainer>
                    <SignUpButton id="verifyBtn" type="button" disabled={disableVerifyButton} onClick={this.verifyOTP}>
                      {disableVerifyButton && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
                      Verify OTP
                    </SignUpButton>
                    <SignInButton type="button" onClick={this.login}>Sign In</SignInButton>
                  </ButtonContainer>
                </>
              )}
            </StyledForm>
          </ModalBody>
        </StyledModal>
      </>
    );
  }
}

export default Register;
