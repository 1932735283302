import React from 'react';
import storage from "../../../Storage";
import C from "../../../Constant";
import { wait, forceSatoshiFormat, Event } from "../../../Helper";
import Deck from "./components/Deck";
import Hand from "./components/Hand";
import Canvas from "./components/Canvas";

class Game extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,

            loading: true,
            progress: 0,

            deck: null,

            // flag for when the player has lost.
            gameOver: false,

            // when set to true, the hand has been evaluated and a fresh hand will be dealt.
            // this is when the player and adjust their wager.
            handOver: true,

            // the deck and the hand. 
            hand: [],

            // number of hands played.
            handsPlayed: 0,

            //player wager
            wager: 0,

            started: false,

            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010)

        };
    }

    componentDidMount() {
        this._isMounted = true;
        const { engine } = this.state;

        Event.on('loading_videopoker', (p) => this.progress(p))

        engine.on(C.PLAY_VIDEOPOKER, data => this.play(data));
        engine.on(C.DEAL_VIDEOPOKER, data => this.busted());

        var deck = new Deck();


        let canvas = new Canvas(engine)
        this.setState({ canvas: canvas })
        canvas.init();
        canvas.start(); // fix load canvas after return to the game

        this.setState({ deck: deck });

    }

    progress = (p) => {
        this.setState({ progress: p })
        if (parseFloat(p) >= 99) {
            this.setState({ loading: false })
            if (document.getElementById('game') !== null) {
                document.getElementById('game').style.display = 'block'
                document.getElementById('game').classList.add('fadeIn');
                document.getElementById('game').classList.add('m-auto');
            }
        }
    }

    componentWillUnmount() {
        this.destroy();
        this._isMounted = false;
    }

    destroy = () => {
        this.setState({ engine: null, handsPlayed: 0, hand: [], deck: null, handOver: true, gameOver: false });
    }

    init = () => {
        if (this._isMounted) {
            // reset number of hands played
            this.handsPlayed = 0;

            // shuffle the deck
            this.state.deck.shuffle(8);

            this.setState({ handOver: true, gameOver: false, started: true })
        }
    }

    play = (data) => {
        if (this._isMounted) {
            let amount = null;

            if (data !== null)
                amount = data.amount;

            if (amount !== null && data !== null)
                this.setWager(parseFloat(amount));

            if (!this.state.gameOver) {
                var money = this.getMoney();
                var wager = this.getWager();

                if (!this.state.handOver) {
                    // cards held, re-deal.
                    var order = 1; // for the delays

                    for (var i = 0; i < this.state.hand.length; i++) {
                        let isHold = this.state.hand[i].isHeld;

                        if (!isHold) {
                            this.state.hand[i] = this.state.deck.nextCard();

                            //for deal
                            this.state.engine.emit('deal_card', { card: this.state.hand[i], pos: i, hold: false })
                            order++;
                        }
                    }

                    this.setState({ started: false })

                    // wait a bit, then evaluate the hand
                    wait(order * 250).then(() => {

                        // get the value of the hand and award any winnings.
                        var finishedHand = new Hand(this.state.hand);

                        var score = finishedHand.getScore();
                        var winnings = score[0] * wager;
                        var originalMoney = money;
                        var newMoney = money + winnings;
                        this.setMoney(newMoney);

                        this.state.canvas.deal(this.state.hand);
                        this.state.engine.emit('deal', { win: winnings, score: score[1] });

                        // if the wager amount is greater than the new amount of money, match them.
                        if (this.getWager() > newMoney) {
                            this.setWager(newMoney);
                        }

                        // if crossing thing $1000 threshold, set the wager amount to $100.
                        // after having $1000 bets will now be incremented by $100 instead of $10.
                        if ((originalMoney < 1000) && newMoney >= 1000) {
                            this.setWager(100);
                        }

                        if (this.getMoney() == 0) {
                            // game over!
                            this.setState({ gameOver: true })
                        }
                        else {
                            // play another hand
                            this.setState({ deck: new Deck(), handOver: true });
                            this.state.deck.shuffle();
                        }
                    })
                }
                else if (money - wager >= 0) {
                    // Start And Reset Hand
                    this.setState({ handOver: false })
                    this.startDealNewHand();

                    var money = this.getMoney();
                    money -= this.getWager();
                    this.setMoney(money);
                    this.setState({ started: true })
                    this.state.canvas.play(this.state.hand);
                }
            } else {
                this.init();
                this.setState({ started: true })
            }
        }
    }

    busted() {
        if (this._isMounted) {
            return this.play(null);
        }
    }

    getMoney = () => {
        return parseFloat(this.state.engine.amount)
    }

    getWager = () => {
        return parseFloat(this.state.wager)
    }

    setWager = (amt) => {
        this.setState({ wager: parseFloat(amt) })
    }

    setMoney = (money) => {
        let am = this.state.amount += parseFloat(money);
        this.setState({ amount: am })
    }

    //First deal cards
    startDealNewHand = () => {
        // increment the counter
        this.state.handsPlayed++;

        var card;
        this.setState({ hand: [] });

        for (var i = 0; i < 5; i++) {
            card = this.state.deck.nextCard();
            this.setState(state => ({ hand: [card, ...state.hand] }));

            this.state.engine.emit('card', { card: card, pos: i, hold: false })
        }
    }

    render() {
        return (
            <div className="poker_container">
                <div className="text-center" id="">
                    {
                        this.state.loading &&
                        <>
                            <div className="game-loading">
                                Loading {this.state.progress}%
                            </div>
                        </>
                    }
                    <canvas id="game" className="animated" style={{ display: 'none' }} />
                </div>
            </div>
        );
    }
}

export default Game;
