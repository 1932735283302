import * as PIXI from "pixi.js";

class Card {
	constructor(c, x, y){
		this.c = c
		this.x = x
		this.y = y
		this.card = null
	}
	
	add(){
		let card = PIXI.Sprite.from('./assets/images/video-poker/cards/' + this.c.rank + '.png');
		card.x = this.x;
		card.y = this.y;
		card.rank = this.c.rank;
		card.suit = this.c.suit;
		card.anchor.set(0.5);
		card.interactive = true;
		card.buttonMode = true;
		card.sortChildren(2);
		card.zIndex = 1;
		this.card = card;
	}
}

export default Card;
