import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { wait } from "../../../Helper";
import Effect from "./components/Effect";

let loaded = false;

PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

class Game {

    constructor(engine) {
        this.app = new PIXI.Application({
            width: 400,
            height: 367,
            resolution: 1,
            backgroundAlpha: true,
            backgroundColor: 0X0e1423,
            view: document.getElementById('game')
        });
        this.engine = engine;
        this.level = 1;
        this.walls = [];
        this.reels = [];
        this.hand = null;
        this.clicked = false;
        this.ended = false;
        this.endWalls = 28;
        this.started = false;
    }

    init() {
        if (!loaded) {
            PIXI.Loader.shared
                .add('check', 'assets/images/tower/spritesheet/check.json')
                .add('uncheck', 'assets/images/tower/spritesheet/uncheck.json')
                .add('search', 'assets/images/tower/spritesheet/search.json');
        }

        PIXI.Loader.shared.load(e => this.load());
    }

    load() {
        loaded = true;
        this.container = new PIXI.Container();
        this.app.stage.sortableChildren = true
        this.container.sortableChildren = true
        this.app.stage.addChild(this.container);

        this.configTower();
        this.configHand();
        this.configWalls();
    }

    winner() {
        this.started = true;
        this.lost = false;
        this.onClick(this.target)
    }

    busted() {
        this.lost = true;
        if (!this.clicked)
            this.onClick(this.target)
    }

    end() {
        this.clicked = false;
        this.level = 1;

        this.container.removeChild(this.hand);

        for (var i = 0; i < this.walls.length; i++) {
            let wall = this.walls[i];
            this.container.removeChild(wall)
        }
        this.walls = [];

        this.configHand();
        this.configWalls();
    }

    nextLevel() {
        this.level++;

        if (this.level > 7) {
            this.ended = true;
            gsap.to(this.tower, { duration: 2, pixi: { brightness: 1, combineCMF: true } });
            this.container.removeChild(this.hand);
            let style = new PIXI.TextStyle({ fontFamily: 'Arial', fontSize: 30, fill: [0xFFFFFF] });
            let t = new PIXI.Text('You Won', style);
            t.x = -50;
            t.y = 20;
            this.container.addChild(t)
            gsap.to(t, { duration: 1, pixi: { x: this.app.screen.width / 3 } });
        }

        for (var i = 0; i < this.walls.length; i++) {
            let wall = this.walls[i];
            gsap.to(wall, { duration: .3, pixi: { y: Math.ceil(wall.y) - 70 } }); // backward
            gsap.to(wall, { duration: .5, pixi: { y: Math.ceil(wall.y) + 50 } });
        }
    }

    configTower() {
        const sprite = new PIXI.Sprite.from('assets/images/tower/tower2.png');
        sprite.anchor.set(.5, 0)
        sprite.alpha = 0;
        sprite.zIndex = 0;
        sprite.x = this.app.screen.width / 2;
        this.container.addChild(sprite);
        this.tower = sprite;
        gsap.to(sprite, { duration: .5, pixi: { brightness: 0, combineCMF: true } });
        gsap.to(sprite, { alpha: 1, duration: 7 })
    }

    configHand() {
        this.hand = new PIXI.Sprite.from('assets/images/tower/hand.png');
        this.hand.anchor.set(.5, 0)
        this.hand.zIndex = 1;
        this.hand.x = 20;
        this.hand.y = this.app.screen.height - 35
        this.container.addChild(this.hand);
        Effect.Flash(this.hand, 5)
    }

    configWalls() {
        let c = 1;
        for (var i = 0; i < 8; i++) {
            for (var j = 0; j < 4; j++) {
                if (i !== 0) {
                    const sprite = new PIXI.Sprite.from('assets/images/tower/wall.png')
                    sprite.y = this.app.screen.height - 50 * i * 1
                    sprite.x = j < 2 ? -100 : 400
                    sprite.width = 80
                    sprite.height = 40
                    sprite.interactive = true;
                    sprite.buttonMode = true;
                    sprite.id = c;
                    Effect.FadeIn(sprite);
                    Effect.MoveIn(sprite, (this.app.screen.width - 70) / 4 * j + 45);
                    this.container.addChild(sprite);
                    this.walls.push(sprite)

                    const mask = PIXI.Sprite.from('assets/images/tower/focus.png');
                    mask.scale.y = .7;
                    mask.y = 10;
                    Effect.Filter(mask, 'AdvancedBloomFilter');

                    sprite.on('mouseover', (e) => {
                        sprite.addChild(mask);
                        Effect.Filter(sprite, 'AdvancedBloomFilter');
                    })

                    sprite.on('mouseout', (e) => {
                        sprite.removeChild(mask);
                    })

                    sprite.on('pointertap', () => {

                        let obj = sprite;

                        if (this.level === 1) {
                            if (obj.id === 1 || obj.id === 2 || obj.id === 3 || obj.id === 4) {
                                sprite.removeChild(mask);
                                this.engine.land = sprite.id;
                                this.target = sprite;
                                this.engine.clicked();
                            }
                        }
                        if (this.level === 2) {
                            if (obj.id === 5 || obj.id === 6 || obj.id === 7 || obj.id === 8) {
                                sprite.removeChild(mask);
                                this.engine.land = sprite.id;
                                this.target = sprite;
                                this.engine.clicked();
                            }
                        }
                        if (this.level === 3) {
                            if (obj.id === 9 || obj.id === 10 || obj.id === 11 || obj.id === 12) {
                                sprite.removeChild(mask);
                                this.engine.land = sprite.id;
                                this.target = sprite;
                                this.engine.clicked();
                            }
                        }
                        if (this.level === 4) {
                            if (obj.id === 13 || obj.id === 14 || obj.id === 15 || obj.id === 16) {
                                sprite.removeChild(mask);
                                this.engine.land = sprite.id;
                                this.target = sprite;
                                this.engine.clicked();
                            }
                        }
                        if (this.level === 5) {
                            if (obj.id === 17 || obj.id === 18 || obj.id === 19 || obj.id === 20) {
                                sprite.removeChild(mask);
                                this.engine.land = sprite.id;
                                this.target = sprite;
                                this.engine.clicked();
                            }
                        }
                        if (this.level === 6) {
                            if (obj.id === 21 || obj.id === 22 || obj.id === 23 || obj.id === 24) {
                                sprite.removeChild(mask);
                                this.engine.land = sprite.id;
                                this.target = sprite;
                                this.engine.clicked();
                            }
                        }
                        if (this.level === 7) {
                            if (obj.id === 25 || obj.id === 26 || obj.id === 27 || obj.id === 28) {
                                sprite.removeChild(mask);
                                this.engine.land = sprite.id;
                                this.target = sprite;
                                this.engine.clicked();
                            }
                        }

                    })
                    c++
                }
            }
        }
    }

    addScan(obj) {
        const checkTextures = []
        for (var i = 0; i < 51; i++) {
            const texture = PIXI.Texture.from(`${i + 1}s.png`);
            checkTextures.push(texture);
        }
        const sprite = new PIXI.AnimatedSprite(checkTextures);
        sprite.alpha = 0;
        sprite.anchor.set(0.5);
        sprite.x = 85
        sprite.width = 170
        sprite.height = 40
        sprite.y = 25;
        sprite.loop = false;
        sprite.animationSpeed = .5;
        gsap.to(sprite, { alpha: 1 })
        sprite.gotoAndPlay(0);
        obj.addChild(sprite);

        wait(2000).then(() => {
            obj.removeChild(sprite)
            if (this.lost) {
                this.unCheck(obj);
                wait(2000).then(() => {
                    this.end()
                })
            } else {
                this.addCheck(obj);
                wait(1000).then(() => {
                    this.nextLevel()
                })
            }
        })
    }

    addCheck(obj) {
        const checkTextures = []
        for (var i = 0; i < 11; i++) {
            const texture = PIXI.Texture.from(`${i + 1}.png`);
            checkTextures.push(texture);
        }
        const sprite = new PIXI.AnimatedSprite(checkTextures);
        sprite.alpha = 0;
        sprite.anchor.set(0.5);
        sprite.x = 95;
        sprite.y = 27;
        sprite.width = 70
        sprite.height = 38
        sprite.loop = false;
        sprite.animationSpeed = .4;
        gsap.to(sprite, { alpha: 1 })
        sprite.gotoAndPlay(0);
        obj.addChild(sprite);
        wait(1200).then(() => {
            obj.removeChild(sprite);
            gsap.to(obj, { alpha: 0, duration: .5 })

            wait(500).then(() => {
                this.container.removeChild(obj);
                this.clicked = false;
            })
        })
    }

    unCheck(obj) {
        const checkTextures = []
        for (var i = 0; i < 20; i++) {
            const texture = PIXI.Texture.from(`${i + 1}u.png`);
            checkTextures.push(texture);
        }
        const sprite = new PIXI.AnimatedSprite(checkTextures);
        sprite.alpha = 0;
        sprite.anchor.set(0.5);
        sprite.x = 95
        sprite.y = 27;
        sprite.width = 70
        sprite.height = 38
        sprite.loop = false;
        sprite.animationSpeed = .2;
        gsap.to(sprite, { alpha: 1 })
        sprite.gotoAndPlay(0);
        obj.addChild(sprite);
        wait(1200).then(() => {
            wait(500).then(() => {
                this.clicked = false;
            })
        })
    }

    onClick(obj) {
        this.clicked = true;

        if (this.level === 1) {
            if (obj.id === 1 || obj.id === 2 || obj.id === 3 || obj.id === 4) {
                this.addScan(obj);
            }
        }
        if (this.level === 2) {
            if (obj.id === 5 || obj.id === 6 || obj.id === 7 || obj.id === 8) {
                this.addScan(obj);
            }
        }
        if (this.level === 3) {
            if (obj.id === 9 || obj.id === 10 || obj.id === 11 || obj.id === 12) {
                this.addScan(obj);
            }
        }
        if (this.level === 4) {
            if (obj.id === 13 || obj.id === 14 || obj.id === 15 || obj.id === 16) {
                this.addScan(obj);
            }
        }
        if (this.level === 5) {
            if (obj.id === 17 || obj.id === 18 || obj.id === 19 || obj.id === 20) {
                this.addScan(obj);
            }
        }
        if (this.level === 6) {
            if (obj.id === 21 || obj.id === 22 || obj.id === 23 || obj.id === 24) {
                this.addScan(obj);
            }
        }
        if (this.level === 7) {
            if (obj.id === 25 || obj.id === 26 || obj.id === 27 || obj.id === 28) {
                this.addScan(obj);
            }
        }
    }

    destroy() {
        this.app = null;
    }
}

Game.prototype.result = function (data) {
}

export default Game;