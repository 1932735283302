import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #36393f;
  color: #b9bbbe;
  padding: 24px;
  border-radius: 20px;

`;

const Title = styled.div`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 16px;
`;

const Paragraph = styled.div`
  margin-bottom: 16px;
  line-height: 1.5;
`;

const SubTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const List = styled.div`
  // margin-left: 20px;
  margin-bottom: 16px;
`;

const ListItem = styled.div`
  margin-bottom: 12px;
  line-height: 1.5;
`;

const FeeComponent = () => {
  return (
    <Container>
      <Title>Why do you need a Withdraw fee?</Title>
      <Paragraph>
        When we make a transaction, the information is broadcast to the network, and the miners collect and package the
        production blocks, and only after the block is produced, the transaction is recognized. Although the miners will get a fixed
        monetary reward after digging into the block, according to the rules, the reward will gradually be halved and become less
        and less, and eventually the miners may not be profitable. Therefore, transaction fees are needed to maintain the
        enthusiasm of miners for mining.
      </Paragraph>

      <SubTitle>The role of the Withdraw fee</SubTitle>
      <List>
        <ListItem>1.Encourage miners to mine</ListItem>
        <ListItem>
          2.Prevent excessive small transactions from hitting the network. Due to the P2P network, the ability of transaction
          processing is limited. If everyone frequently conducts small transactions, the network will be congested, causing delays or
          even stagnation. So set a threshold, the amount of natural transactions will be reduced when there is a small transaction.
        </ListItem>
      </List>

      <SubTitle>How much is the Withdraw fee?</SubTitle>
      <Paragraph>
        Since the transaction is a two-way charge, it means that the sale of a digital currency requires at least a 0.1% Withdraw fee
        to the platform.
      </Paragraph>
    </Container>
  );
};

export default FeeComponent;