import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import { decode, encode, wait, sendNotfication } from "../../../../Helper";
import C from "../../../../Constant";

class Security extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabled: false,
      password: "",
      _password: "",
      twoFa: null,
      disabled2: false,
      disabled3: false,
      activated: false,
      checkbox: false,
      checkbox2: false,
    };
    this.saveUserSetting = this.saveUserSetting.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      socket.on(C.EDIT_PASSWORD, (data) => this.updateProfile(decode(data)));
      socket.on(C.TWO_FA, (data) => this.setupTwoFa(decode(data)));
      socket.on(C.TWO_FA_CONFIRM, (data) => this.confirmTwoFa(decode(data)));
      socket.on(C.TWO_FA_DISABLE, (data) => this.disableTwoFactorStatus(decode(data)));
      socket.emit(C.TWO_FA);
    }
  }

  componentWillMount() {
    wait(500).then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setupTwoFa(qr) {
    if (this._isMounted) {
      if (qr === "activated") {
        this.setState({ activated: true });
      } else this.setState({ twoFa: qr });
    }
  }

  confirmTwoFa(data) {
    if (this._isMounted) {
      this.setState({ disabled2: false });

      if (data.status !== true) {
        return sendNotfication(data.status.toString());
      }

      sendNotfication("2FA Successfully activated.");
    }
  }

  saveUserSetting(e) {
    if (this._isMounted) {
      wait(750).then(() => {
        socket.emit(
          C.EDIT_PASSWORD,
          encode({
            oldpassword: this.state.passwordold,
            password: this.state.password,
          })
        );
      });
    }
  }

  confirm = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      if (!this.state.verify_code) return;
      if (!this.state.loginpass) return;
      this.setState({ disabled2: true });
      socket.emit(
        C.TWO_FA_CONFIRM,
        encode({
          code: this.state.verify_code,
          password: this.state.loginpass,
        })
      );
    }
  };

  updateProfile(data) {
    if (this._isMounted) {
      this.setState({ disabled: false });
      if (data.status) {
        return sendNotfication(
          "Your Account Password was Updated ",
          "success",
          "top-center"
        );
      } else {
        return sendNotfication(data.error, "error", "top-center");
      }
    }
  }

  disableTwoFactorStatus = (data) => {
    if (this._isMounted) {
      this.setState({ disabled3: false });

      if (data.status !== true) {
        return sendNotfication(data.status.toString());
      }
      sendNotfication("2FA Successfully disabled.");
    }
  };

  disableTwoFa = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      if (!this.state.verify_code) return;
      if (!this.state.loginpass) return;
      this.setState({ disabled3: true });
      socket.emit(
        C.TWO_FA_DISABLE,
        encode({
          code: this.state.verify_code,
          password: this.state.loginpass,
        })
      );
    }
  };

  handleCheckBox = (e) => {
    this.setState({ checkbox: true, loadingPrivacy: true });
    wait(1200).then(() => {
      this.setState({ loadingPrivacy: false, checkbox: false });
      return sendNotfication(
        "This feature can't be active in your account !",
        "info",
        "top-center"
      );
    });
  };

  handleCheckBox2 = (e) => {
    this.setState({ checkbox2: true, loading2: true });
    wait(1200).then(() => {
      this.setState({ checkbox2: false, loading2: false });
      return sendNotfication(
        "This feature can't be active in your account !",
        "info",
        "top-center"
      );
    });
  };

  render() {
    const inputStyle = {
      backgroundColor: "#1a1a1a",
      border: "1px solid #333",
      borderRadius: "8px",
      color: "#fff",
      padding: "10px 12px",
      fontSize: "14px",
      width: "100%",
      marginBottom: "-10px"
    };

    const buttonStyle = {
      backgroundColor: "#5DFF00",
      border: "none",
      borderRadius: "24px",
      color: "#000",
      padding: "12px 24px",
      fontSize: "14px",
      fontWeight: "bold",
      cursor: "pointer",
      width: "50%",
      marginTop: "8px"
    };

    const disabledButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#5DFF0080",
      cursor: "not-allowed"
    };

    return (
      <>
        {this.state.loading ? (
       <div style={{width:"100%", height:"30vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
           {/* <div className="text-center" style={{ minHeight: 150 }}>
            <div className="spinner-grow my-2 user-loader" role="status" />
          </div> */}
       </div>
        ) : (
          < div style={{marginTop:"-20px"}}>
            <Row>
              <Col sm='12' md="6" lg="6" className="">
                <h4>Two-factor Authentication</h4>
                {!this.state.activated ? (
                  <Form onSubmit={this.confirm}>
                    <Row>
                      <Col sm="12" md="12">
                        <Row>
                          <Col sm="12" md="12" className="text- m-auto">
                            <p className="font-14 text-white">
                              Download and install Google Authenticator. Enable
                              Two-factor Authentication to protect your account from
                              unauthorized access. Scan the QR code with your Google
                              Authenticator App.
                            </p>
                            <div className="text-center">
                              <img
                                className="img-fluid rounded border p-1"
                                alt="2FA"
                                src={this.state.twoFa}
                                style={{ width: 150 }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col sm="12">
                            <label>Verification Code</label>
                            <input
                              type="text"
                              style={inputStyle}
                              value={this.state.verify_code}
                              onChange={(e) =>
                                this.setState({ verify_code: e.target.value })
                              }
                            />
                          </Col>
                          <Col sm="12" className="mt-2">
                            <label>Login Password</label>
                            <input
                              type="text"
                              style={inputStyle}
                              value={this.state.loginpass}
                              onChange={(e) =>
                                this.setState({ loginpass: e.target.value })
                              }
                            />
                          </Col>
                          <Col sm="12" md="12" className="text-center">
                            <button
                              type="submit"
                              style={this.state.disabled2 ? disabledButtonStyle : buttonStyle}
                              disabled={this.state.disabled2}
                            >
                              Enable
                              {this.state.disabled2 && (
                                <div className="ml-2 spinner-border spinner-border-sm" />
                              )}
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  <>
                    <Form onSubmit={this.disableTwoFa}>
                      <Row>
                        <Col sm="12">
                          <p className="font-14 text-white">
                            To disable Two-Factor authentication, enter your
                            one-time Password (OTP)
                          </p>
                          <label>Login Password</label>
                          <input
                            type="text"
                            style={inputStyle}
                            value={this.state.loginpass}
                            onChange={(e) =>
                              this.setState({ loginpass: e.target.value })
                            }
                          />
                          <label>Verification Code</label>
                          <input
                            type="text"
                            style={inputStyle}
                            value={this.state.verify_code}
                            onChange={(e) =>
                              this.setState({ verify_code: e.target.value })
                            }
                          />
                          <button
                            type="submit"
                            style={this.state.disabled3 ? disabledButtonStyle : buttonStyle}
                            disabled={this.state.disabled3}
                          >
                            Disable
                            {this.state.disabled3 && (
                              <div className="ml-2 spinner-border spinner-border-sm" />
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Col>

              <Col sm='12' md="6" lg="6">
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  if (!this.state.password) {
                    document.getElementById("password").classList.add("border-danger");
                    return;
                  }
                  if (!this.state._password) {
                    document.getElementById("_password").classList.add("border-danger");
                    return;
                  }
                  if (this.state.password !== this.state._password) {
                    sendNotfication("Passwords is different!", "error", "top-center");
                  }
                  if (this.state.password === this.state._password) {
                    this.setState({ disabled: true });
                    this.saveUserSetting(e);
                  }
                }}>
                  <h4>Change Password</h4>
                  <Row>
                    <Col sm={12}>
                      <label className="mt-1" htmlFor="passwordold">
                        Old Password
                      </label>
                      <input
                        type="password"
                        id="passwordold"
                        autoComplete="off"
                        style={inputStyle}
                        value={this.state.passwordold}
                        required={true}
                        onChange={(e) =>
                          this.setState({ passwordold: e.target.value })
                        }
                      />
                    </Col>
                    <Col sm={12}>
                      <label className="mt-1" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        autoComplete="off"
                        style={inputStyle}
                        value={this.state.password}
                        required={true}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </Col>
                    <Col sm={12}>
                      <label className="mt-1" htmlFor="_password">
                        Confirmation Password
                      </label>
                      <input
                        type="password"
                        id="_password"
                        autoComplete="off"
                        style={inputStyle}
                        value={this.state._password}
                        required={true}
                        onChange={(e) =>
                          this.setState({ _password: e.target.value })
                        }
                      />
                    </Col>
                    <Col sm={12} className="text-center mt-2">
                      <button
                        type="submit"
                        style={this.state.disabled ? disabledButtonStyle : buttonStyle}
                        disabled={this.state.disabled}
                      >
                        Save Changes
                        {this.state.disabled && (
                          <div className="ml-2 spinner-border spinner-border-sm" />
                        )}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

export default Security;
