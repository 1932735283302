import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import { defaultAvatar, encode, Game, Event } from "../../../../Helper";

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coin: this.props.coin ? this.props.coin : (storage.getKey('coin') !== null) ? storage.getKey('coin') : 'BTC'
        };
    }

    getDetail = (e) => {
        if (!this.props.byLink)
            e.preventDefault();

        if (this.props.clicked !== undefined)
            this.props.clicked(); // can close game modal

        const getFromUrl = () => {
            let id = this.props.params.pathname;
            return id.replace('/user/', '');
        }

        let id = this.props.id ? this.props.id : getFromUrl()

        Event.emit('single_game_modal_close');
        Event.emit('force_modal_user');
        Event.emit('close_message_modal');
        socket.emit(C.USER_INFO, encode({ id: id, coin: this.state.coin, rate: null, game: 'all', first: true }));
    };

    render() {
        let { username, id, isWinner, cssClass, queue, menu, avatar, chat, level, byLink } = this.props;

        if (byLink) {
            return '' + this.getDetail()
        }

        if (isWinner === true)
            cssClass = 'text-success';

        let link = <>
            <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}>
                {username}
            </Link>
        </>;

        if (menu) {
            link = <>
                <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={'text-muted ng'}>
                    <p className="p-0 m-0">
                    <img
                                    src="https://img2.nanogames.io/avatar/589929/s?t=1727193471914"
                                    alt="profile-user"
                                    style={{ height: '50px', width: '50px', borderRadius: '50%', objectFit: 'contain' }}
                                />
                    </p>
                </Link>
            </>;
        }

        else if (queue) {
            let fixAvatar = (avatar !== undefined && avatar !== false && avatar !== "" && avatar !== null) ? avatar : defaultAvatar;
            link = <>
                <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}>
                    <img src={fixAvatar} alt="user" className="rounded-circle thumb-xs mr-1 hidden-sm" />
                    {username}
                </Link>
            </>;
        }
        else {
            if (avatar) { 
                link = <>
                    <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}>
                    <img
                                    src="https://img2.nanogames.io/avatar/589929/s?t=1727193471914"
                                    alt="profile-user"
                                    style={{ height: '50px', width: '50px', borderRadius: '50%', objectFit: 'contain' }}
                                />
                        <ul className={'ml-2 mt-0 d-flex'}>
                            <span>{username}</span>
                        </ul>
                    </Link>
                </>;
            }

            if (chat) {
                if (avatar === null) {
                    link = <>
                        <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}>
                            <ul className={'ml-1 mt-0 d-flex'}>
                                <span>{username}</span>
                            </ul>
                        </Link>
                    </>;
                }
            }
        }

        return link;
    }
}

Modal.propTypes = {
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, {})(Modal);
