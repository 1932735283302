

import {styled} from 'styled-components'
import {Link } from 'react-router-dom'

const Container = styled.div``


;


const TopImageCard = styled.div`
height:332px;
border-radius:1.25rem;
background-color: rgba(0, 0, 0, 0.3);
    background-size: auto 100%;
        background-position: center center;
        background-image:url("https://static.nanogames.io/assets/banner.a6e03510.png")
`;


const ContainerPlay = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  justify-content:center;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background: linear-gradient(145deg, #2A2D34 0%, #1E2024 100%);
  border-radius: 10px;
//   padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ImagePlaceholder = styled.div`
  width: 80px;
  height: 80px;
  background-color: #3A3D44;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
  color: #9A9DA3;
`;

const StyledImage = styled.img`
  height: 145px; /* Desktop view */
  margin-bottom: 15px;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 180px; /* Mobile view */
  }
`;

const Title = styled.h3`
  color: #FFFFFF;
  font-size: 18px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: #9A9DA3;
  font-size: 14px;
`;

const images = {
    PICK2: '/assets/images/lotto/pic2.png',
    PICK3: '/assets/images/lotto/pic3.png',
    PICK4: '/assets/images/lotto/pic4.png',
    PICK5: '/assets/images/lotto/pic5.png',
  };

 export  const PickOption = ({ title }) => (
  <Link to="/pick2">
    <StyledImage src={images[title]} alt={title} />
  </Link>
  
  );


export default function Lottery(){
    return(
      <Container>

       <TopImageCard/>

       <ContainerPlay>
      <PickOption title="PICK2" />
      <PickOption title="PICK3" />
      <PickOption title="PICK4" />
      <PickOption title="PICK5" />

    </ContainerPlay>

      </Container>
    )
}
