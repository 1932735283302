/* eslint-disable */
import React from 'react';
import {wait, chkd, isMobile, getRandomInt, Event, playAudio} from "../../../Helper";
import C from "../../../Constant";
import storage from "../../../Storage";
import Canvas from "./components/Canvas";

class Game extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            engine: this.props.engine,
            canvas: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;
        this.setUpCanvas();
        engine.on(C.PLAY_SNAKEANDLADDERS, (data) => this.play(data));
        engine.on(C.NEXT_SNAKEANDLADDERS, (data) => this.play(data));
        engine.on(C.CASHOUT_SNAKEANDLADDERS, (data) => this.endGame(data));
        engine.on(C.BUSTED_SNAKEANDLADDERS, (data) => this.endGame(data));
 
        if(isMobile()){
            let canvas = document.getElementById('game');
            canvas.style.width = '100%'
            canvas.style.height = '100%'
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setUpCanvas(){
        if(this._isMounted){
            let canvas = new Canvas(this.state.engine)
            canvas.init();
            this.setState({ canvas: canvas })
        }
    }

    play = (data) => {
        if(this._isMounted){
            let { target } = data;
            this.state.canvas.play(target);
        }
    };

    endGame(data){
        if(this._isMounted){
            this.state.canvas.restart();
        }
    }

    render() {
        return(
            <div className="snake-bg animated fadeIn">
                <div className="text-center">
                    <div className="text-center ycenter" id="loading" />
                    <canvas id="game" />
                </div>
            </div>
        );
    }
}

export default Game;
